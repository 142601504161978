import { useState } from "react";
import {
  deepCopy,
  isEmptyList,
  isNullOrUndefined,
  makeKeyFriendlyString,
} from "../../utils";
import { useTranslation } from "react-i18next";
import TimeRangeInput from "../TimeRangeInput";
import { selectInputFix } from "../../styleUtils";
import SelectCrewMember from "../Dispatches/SelectCrewMember";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { getUserInfo } from "../../userUtils";
import moment from "moment-timezone";
import CrewInfo from "../CrewInfo";

function CrewShiftsSlot(props) {
  const { t } = useTranslation();

  const {
    isEditable,
    users,
    onAddCrewShift,
    selectedDate,
    airportTimezone,
    turnarounds,
    positions,
    onSaveCrewShift,
    operationTypes,
  } = props;
  const [crewShiftToEdit, setCrewShiftToEdit] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const mCrewShiftDate = moment(selectedDate)
    .tz(airportTimezone)
    .startOf("day");

  return (
    <>
      {
        /** Read mode **/
        isNullOrUndefined(crewShiftToEdit) && (
          <div
            className={`crew-shifts-module-row slot${
              !isEditable ? " read-only" : ""
            }`}
          >
            <div className="slot-placeholder">
              <div>
                <div>
                  {!isActive && (
                    <button
                      className="outlined"
                      onClick={() => {
                        onAddCrewShift("_new");
                        setIsActive(true);
                      }}
                      disabled={!isEditable}
                    >
                      <Plus />
                      {t("add_crew_shift")}
                    </button>
                  )}
                  {isActive && (
                    <SelectCrewMember
                      onSelect={(user) => {
                        if (!isNullOrUndefined(user)) {
                          const userInfo = getUserInfo(
                            user,
                            turnarounds,
                            positions
                          );
                          setCrewShiftToEdit({
                            userUuid: userInfo.uuid,
                            userInfo: userInfo,
                            startTime: mCrewShiftDate.toDate().toISOString(),
                            endTime: mCrewShiftDate.toDate().toISOString(),
                            operationTypeUuid: null,
                          });
                        }
                      }}
                      onCancel={() => {
                        onAddCrewShift(null);
                        setIsActive(false);
                      }}
                      users={users}
                      positions={positions}
                      turnarounds={turnarounds}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        /** Edit mode **/
        !isNullOrUndefined(crewShiftToEdit) && (
          <div className={`crew-shifts-module-row slot`}>
            <div>
              <CrewInfo
                user={crewShiftToEdit.userInfo.user}
                details={t(crewShiftToEdit.userInfo.dispatchStatus)}
                mapStatus={crewShiftToEdit.userInfo.mapStatus}
              />
            </div>
            <div>
              <div>
                <TimeRangeInput
                  onTimeRangeChange={(timerangeValues) => {
                    const updatedCrewShift = deepCopy(crewShiftToEdit);
                    updatedCrewShift.startTime = timerangeValues.startTime;
                    updatedCrewShift.endTime = timerangeValues.endTime;
                    setCrewShiftToEdit(updatedCrewShift);
                  }}
                  timeRangeValues={{
                    startTime: crewShiftToEdit.startTime,
                    endTime: crewShiftToEdit.endTime,
                  }}
                  timezone={airportTimezone}
                />
              </div>
            </div>
            <div>
              <div>
                {!isEmptyList(operationTypes) && (
                  <select
                    className="certifications-input"
                    style={selectInputFix}
                    value={crewShiftToEdit.operationTypeUuid}
                    onChange={(e) => {
                      crewShiftToEdit.operationTypeUuid = e.target.value;
                    }}
                  >
                    <option value="">{t("any_certified_operation")}</option>
                    {operationTypes &&
                      operationTypes.map((operationType) => (
                        <option
                          value={operationType.uuid}
                          key={makeKeyFriendlyString(operationType.uuid)}
                        >
                          {operationType.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="actions">
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    setCrewShiftToEdit(null);
                    onAddCrewShift(null);
                    setIsActive(false);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  className="primary"
                  onClick={() => {
                    onSaveCrewShift(crewShiftToEdit);
                    setCrewShiftToEdit(null);
                    setIsActive(false);
                  }}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
export default CrewShiftsSlot;
