import { useEffect, useRef } from "react";
import { useMainDispatchContext } from "../../MainContext";
import {
  getDistanceInMeters,
  handleHover,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";
import TrackerStatus from "../TrackerStatus";
import StatusBadge from "../StatusBadge";

function VehicleInfoModule(props) {
  const { t } = useTranslation();
  const { vehicleInfo, isSelected, isLocateMode, pinMarker } = props;
  const info = vehicleInfo.mapInfo;
  const dispatch = useMainDispatchContext();
  const details =
    !isNullOrUndefined(info.turnaroundInfo) &&
    !isNullOrUndefined(info.gseRecord) ? (
      <>
        {info.turnaroundInfo.combinedFlightName}&nbsp;&bull;&nbsp;
        {info.gseRecord?.operationInfo?.name}
      </>
    ) : (
      <>{`${t("last_online")} ${
        !isNullOrUndefined(info?.position?.lastOnline)
          ? info?.position?.lastOnline
          : "-"
      }`}</>
    );
  const hasPosition =
    !isNullOrUndefined(info?.position?.longitude) &&
    !isNullOrUndefined(info?.position?.latitude);

  const distanceFromPin =
    !isNullOrUndefined(pinMarker) && !isNullOrUndefined(info?.position)
      ? getDistanceInMeters(
          [pinMarker.longitude, pinMarker.latitude],
          [info?.position.longitude, info?.position.latitude]
        )
      : 0;
  const selectedRef = useRef(null);
  useEffect(() => {
    if (isSelected && !isNullOrUndefined(selectedRef.current)) {
      // Attempt to scroll the element into view if not already
      const { top, left, bottom, right } =
        selectedRef.current.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      const isFullyVisible =
        top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;

      if (!isFullyVisible) {
        document.querySelector(".overlay-panel-content").scrollTo({
          left: 0,
          top: selectedRef.current.offsetTop - 16,
          behavior: "smooth",
        });
      }
    }
  }, [isSelected]);

  const battery = info?.position?.battery;
  const hasPower = info?.position?.hasPower;
  const satStrengthPercent = info?.position?.satStrengthPercent;
  const cellStrengthPercent = info?.position?.cellStrengthPercent;

  return (
    <div
      ref={isSelected ? selectedRef : null}
      className={`overlay-module${isSelected ? " selected" : ""}${
        !hasPosition ? " disabled" : ""
      }`}
      data-trackercode={info?.position?.trackerCode}
      data-uuid={info?.uuid}
      onClick={() => {
        if (!hasPosition) return;
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.VEHICLE,
            item: {
              uuid: info.uuid,
            },
            isLocateMode: isLocateMode,
          },
        });
      }}
      onMouseOver={() => {
        if (!hasPosition) return;
        handleHover(true, vehicleInfo);
      }}
      onMouseOut={(e) => {
        if (!hasPosition) return;
        handleHover(false, vehicleInfo);
      }}
    >
      <div className="overlay-module-box">
        <div className="vehicle-info">
          <div className="vehicle-status">
            <div className={`status-indicator status-${info.status}`}></div>
          </div>
          <div className="vehicle-details">
            <div className="vehicle-name">
              <div>{info.name}</div>
              <div></div>
            </div>
            <div>
              <StatusBadge
                isGeneric={true}
                isAllCap={true}
                message={info.type}
              />
            </div>
            <div className="vehicle-status-details">{details}</div>
            {!isNullOrUndefined(pinMarker) && (
              <div className="vehicle-status-details">
                {t("pin_distance", { value: distanceFromPin.toFixed(2) })}
              </div>
            )}
          </div>
          <div>
            <TrackerStatus
              battery={battery}
              hasPower={hasPower}
              satStrengthPercent={satStrengthPercent}
              cellStrengthPercent={cellStrengthPercent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInfoModule;
