import { getTurnaroundInfoForTurnaroundSummary } from "./turnaroundUtils";
import { getUserInfo } from "./userUtils";
import { getByFieldValue, isNullOrUndefined } from "./utils";
import moment from "moment-timezone";

export const CHAT_CATEGORY_TYPES = {
  TURNAROUND: 1,
  GROUP: 2,
  DIRECT: 3,
};

export const CHAT_ATTACHMENT_SIZE = {
  PORTRAIT: 200,
  LANDSCAPE: 300,
};

export function isSeenByUser(channel, message, user, airportTimezone) {
  const lastReadMember =
    !isNullOrUndefined(user) && !isNullOrUndefined(channel.members)
      ? getByFieldValue(channel.members, "userUuid", user.uuid)
      : null;
  const lastReadTime = !isNullOrUndefined(lastReadMember)
    ? moment(lastReadMember.lastRead).tz(airportTimezone)
    : null;
  return !isNullOrUndefined(lastReadTime)
    ? lastReadTime.isAfter(message.sentAt)
    : false;
}

export function getUnreadMessagesForUser(
  channel,
  currentUser,
  airportTimezone
) {
  const unreadMessages = [];
  for (let i = 0; i < channel.messages?.length; i++) {
    const message = channel.messages[i];
    if (
      !isSeenByUser(channel, message, currentUser, airportTimezone) &&
      message.sentBy !== currentUser.uuid
    ) {
      unreadMessages.push(message);
    }
  }
  return unreadMessages;
}

export function getChannelInfoFromSummary(
  channel,
  users,
  turnaroundsSummary,
  positions
) {
  const channelInfo = {
    ...channel,
    _sourceChannel: channel,
  };

  if (!isNullOrUndefined(channel.otherUserUuid)) {
    channelInfo.isDirect = true;
    channelInfo.channelType = CHAT_CATEGORY_TYPES.DIRECT;

    // Name of channel will be name of user
    const chatUser = getByFieldValue(users, "uuid", channel.otherUserUuid);
    channelInfo.chatUserInfo = !isNullOrUndefined(chatUser)
      ? getUserInfo(chatUser, turnaroundsSummary, positions)
      : null;
    channelInfo.name = !isNullOrUndefined(channelInfo.chatUserInfo)
      ? channelInfo.chatUserInfo.fullName
      : "";
  } else if (!isNullOrUndefined(channel.turnaroundUuid)) {
    channelInfo.isTurnaround = true;
    channelInfo.channelType = CHAT_CATEGORY_TYPES.TURNAROUND;

    // Name of channel will be name of turnaround
    const turnaround = getByFieldValue(
      turnaroundsSummary,
      "uuid",
      channel.turnaroundUuid
    );

    channelInfo.turnaroundInfo = !isNullOrUndefined(turnaround)
      ? getTurnaroundInfoForTurnaroundSummary(turnaround)
      : null;

    channelInfo.name = !isNullOrUndefined(channelInfo.turnaroundInfo)
      ? channelInfo.turnaroundInfo.combinedFlightName
      : "";
  } else {
    channelInfo.isGroup = true;
    channelInfo.channelType = CHAT_CATEGORY_TYPES.GROUP;
  }

  // TODO: Add validation for group channels
  channelInfo.isValidChannel =
    channelInfo.isGroup ||
    (channelInfo.isDirect && !isNullOrUndefined(channelInfo.chatUserInfo)) ||
    (channelInfo.isTurnaround &&
      !isNullOrUndefined(channelInfo.turnaroundInfo));

  return channelInfo;
}

export function getChannelInfoFromTurnaroundInfo(turnaroundInfo) {
  return {
    channelType: CHAT_CATEGORY_TYPES.TURNAROUND,
    isTurnaround: true,
    name: turnaroundInfo.combinedFlightName,
    turnaroundUuid: turnaroundInfo.uuid,
  };
}

export function scaleCommsImage(origWidth, origHeight) {
  // Scale image to a specific width
  let ratio = 1;
  if (origWidth > origHeight) {
    ratio = CHAT_ATTACHMENT_SIZE.LANDSCAPE / origWidth;
  } else {
    ratio = CHAT_ATTACHMENT_SIZE.PORTRAIT / origWidth;
  }
  return [origWidth * ratio, origHeight * ratio];
}

// Gets the direct message channel for the user if it exists
export function getChannelForUserUuid(channelInfoList, userUuid) {
  if (isNullOrUndefined(channelInfoList)) return null;
  let directMessageChannels = null;
  for (let i = 0; i < channelInfoList.length; i++) {
    const channelInfo = channelInfoList[i];
    if (channelInfo.category.channelGroupType === CHAT_CATEGORY_TYPES.DIRECT) {
      directMessageChannels = channelInfo.channelInfoList;
    }
  }
  if (isNullOrUndefined(directMessageChannels)) return null;
  for (let i = 0; i < directMessageChannels.length; i++) {
    const directMessageChannel = directMessageChannels[i];
    if (directMessageChannel?.chatUserInfo?.uuid === userUuid) {
      return directMessageChannel;
    }
  }
  return null;
}
