import {
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "./utils";
import moment from "moment-timezone";

// mDay should be start of the day
export function hasCrewShiftsForDate(user, crewShifts, mDay) {
  if (
    isNullOrUndefined(user) ||
    isEmptyList(crewShifts) ||
    isNullOrUndefined(mDay)
  ) {
    return false;
  }
  const timezone = getTimezoneFromUser(user);
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime)
        .tz(timezone)
        .startOf("day");
      if (mDay.toDate().toISOString() === mStartTime.toDate().toISOString()) {
        // user has a shift on this day
        return true;
      }
    }
  }
  return false;
}

export function getCrewShiftsForDate(user, crewShifts, mDay) {
  if (
    isNullOrUndefined(user) ||
    isEmptyList(crewShifts) ||
    isNullOrUndefined(mDay)
  ) {
    return false;
  }
  const timezone = getTimezoneFromUser(user);
  const crewShiftsForDate = [];
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime)
        .tz(timezone)
        .startOf("day");
      if (mDay.toDate().toISOString() === mStartTime.toDate().toISOString()) {
        // user has a shift on this day
        crewShiftsForDate.push(crewShiftToCheck);
      }
    }
  }
  sortByField(crewShiftsForDate, "startTime");
  return crewShiftsForDate;
}

// Returns current shift and next shift for today
export function getRelativeCrewShifts(user, crewShifts) {
  if (isEmptyList(crewShifts)) {
    return null;
  }
  const timezone = getTimezoneFromUser(user);
  const relativeShifts = {};
  const mNow = moment().tz(timezone);
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime).tz(timezone);
      const mEndTime = moment(crewShiftToCheck.endTime).tz(timezone);
      if (mStartTime.isBefore(mNow) && mEndTime.isAfter(mNow)) {
        // Current shift
        relativeShifts.currentShift = crewShiftToCheck;
      } else if (mStartTime.isAfter(mNow)) {
        if (isNullOrUndefined(relativeShifts.nextShift)) {
          relativeShifts.nextShift = crewShiftToCheck;
        } else if (
          mStartTime.isBefore(
            moment(relativeShifts.nextShift.startTime).tz(timezone)
          )
        ) {
          // Earlier next shift
          relativeShifts.nextShift = crewShiftToCheck;
        }
      }
    }
  }
  return relativeShifts;
}
