import {
  formatDate,
  formatTime,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { useTranslation } from "react-i18next";
import { DEFAULT_TIMEZONE } from "../../constants";
import moment from "moment-timezone";
import { getDayAbbreviation } from "../../dateUtils";
import { getCrewShiftsForDate } from "../../crewShiftUtils";

export default function CrewShiftsOutlookDetail(props) {
  const { crewShiftModalUser, crewShifts } = props;
  const { t } = useTranslation();
  if (isNullOrUndefined(crewShiftModalUser)) {
    return null;
  }

  const airport = crewShiftModalUser?.airport;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mToday = moment().tz(airportTimezone);
  const mDay = moment().tz(airportTimezone).startOf("week");
  const crewShiftsToRender = [];

  for (let i = 0; i < 7; i++) {
    const crewShiftsForDate = getCrewShiftsForDate(
      crewShiftModalUser,
      crewShifts,
      mDay
    );
    crewShiftsToRender.push({
      assignments: crewShiftsForDate,
      day: moment(mDay),
    });
    mDay.add(1, "day");
  }

  return (
    <div>
      <div className="crew-shifts-outlook-detail">
        <div className="crew-shift-rows">
          {!isEmptyList(crewShiftsToRender) &&
            crewShiftsToRender.map((crewShift, idx) => (
              <div
                key={idx}
                className={`crew-shift-row ${
                  moment(crewShift.day).day() === mToday.day() ? " today" : ""
                }`}
              >
                <div>
                  <div className="crew-shift-date">
                    <div>{getDayAbbreviation(crewShift.day.toDate())}</div>
                    <span>{formatDate(crewShift.day, airportTimezone)}</span>
                  </div>
                  <div className="crew-shift-infos">
                    {!isEmptyList(crewShift.assignments) &&
                      crewShift.assignments.map((item, subidx) => (
                        <div
                          key={`${idx}_${subidx}`}
                          className="crew-shift-info"
                        >
                          <div className="crew-shift-time-range">
                            {!isNullOrUndefined(item.startTime) && (
                              <>
                                <div>
                                  {formatTime(item.startTime, airportTimezone)}
                                </div>
                                <div>&nbsp;-&nbsp;</div>
                                <div>
                                  {formatTime(item.endTime, airportTimezone)}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="crew-shift-operation">
                            {!isNullOrUndefined(item.operationType?.name) && (
                              <>{item.operationType?.name}</>
                            )}
                          </div>
                        </div>
                      ))}
                    {isEmptyList(crewShift.assignments) && (
                      <div className="crew-shift-info">
                        <div className="crew-shift-time-range"></div>
                        <div className="crew-shift-operation de-emphasize">
                          {t("no_assignments")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
