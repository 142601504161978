import { useTranslation } from "react-i18next";
import { formatTime, isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
import StatusBadge from "../StatusBadge";
import { EMPTY_TIME } from "../../constants";
function TurnaroundCrew(props) {
  const {
    crewInfo,
    airportTimezone,
    selected,
    isSelectable,
    onClick,
    showCertifications,
    showActions,
    showCrewStatus,
    showAssignCrew,
    onAssignCrew,
    showUnassignCrew,
    onUnassignCrew,
    isSavingUnassignment,
    isUnassigned,
  } = props;
  const mainContext = useMainContext();
  const { positions, turnaroundsSummary } = mainContext;
  if (isNullOrUndefined(crewInfo?.user)) return null;
  const userInfo = getUserInfo(crewInfo.user, turnaroundsSummary, positions);

  return (
    <div
      className={`turnaround-crew${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      key={userInfo?.user.uuid}
      data-uuid={userInfo?.user.uuid}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
    >
      {userInfo && userInfo.user && (
        <CrewInfo
          user={userInfo.user}
          showActions={showActions}
          showAssignCrew={showAssignCrew}
          onAssignCrew={onAssignCrew}
          showUnassignCrew={showUnassignCrew}
          onUnassignCrew={onUnassignCrew}
          isSavingUnassignment={isSavingUnassignment}
          details={
            <CrewDetails
              crewInfo={crewInfo}
              airportTimezone={airportTimezone}
              showCertifications={showCertifications}
              showCrewStatus={showCrewStatus}
              isUnassigned={isUnassigned}
            />
          }
          mapStatus={userInfo.mapStatus}
        />
      )}
    </div>
  );
}

// Content to insert into the "details" for the CrewInfo component
function CrewDetails(props) {
  const { crewInfo, airportTimezone, showCertifications, isUnassigned } = props;
  const { t } = useTranslation();
  if (isNullOrUndefined(crewInfo)) return null;
  const hasArrived = !isNullOrUndefined(crewInfo?.userRecord?.startTime);
  const hasDeparted = !isNullOrUndefined(crewInfo?.userRecord?.endTime);
  const arrivedAt = hasArrived
    ? formatTime(crewInfo?.userRecord?.startTime, airportTimezone)
    : null;
  const departedAt = hasDeparted
    ? formatTime(crewInfo?.userRecord?.endTime, airportTimezone)
    : null;

  return (
    <>
      {!isUnassigned && showCertifications && (
        <div>
          <div className="certification-name">
            <div>{crewInfo.certificationName}</div>
          </div>
        </div>
      )}
      {isUnassigned && (
        <div>
          <StatusBadge
            message={t("unassigned").toUpperCase()}
            isCritical={true}
          />
        </div>
      )}
      <span>
        {t("arrived_cap_colon")}
        &nbsp;
        <span className="time-completed">
          {hasArrived ? arrivedAt : EMPTY_TIME}
        </span>
      </span>
      <span>
        {t("departed_cap_colon")}&nbsp;
        <span className="time-completed">
          {hasDeparted ? departedAt : EMPTY_TIME}
        </span>
      </span>
    </>
  );
}

export default TurnaroundCrew;
