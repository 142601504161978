import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { formatMediumDate, isNullOrUndefined } from "../../utils";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { useTranslation } from "react-i18next";
const DATE_FORMAT = "MM/DD/YYYY";

function RangePicker(props) {
  const {
    today,
    airportTimezone,
    startDate,
    endDate,
    minDate,
    maxDate,
    maxRange,
    onChange,
  } = props;
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const startDateDisplay = !isNullOrUndefined(selectedStartDate)
    ? formatMediumDate(selectedStartDate, airportTimezone)
    : "";
  const endDateDisplay = !isNullOrUndefined(selectedEndDate)
    ? formatMediumDate(selectedEndDate, airportTimezone)
    : "";

  const hardMin = moment(minDate);
  const hasStart = !isNullOrUndefined(selectedStartDate);
  const hasEnd = !isNullOrUndefined(selectedEndDate);

  let startDateMin = hardMin;
  let startDateMax = maxDate;

  // When in process of selecting a range (start selected but not end)
  // Constrain to the max range as specified
  if (hasStart && !hasEnd) {
    const minDateWithinRange = moment(selectedStartDate).add(
      (maxRange - 1) * -1,
      "day"
    );
    if (minDateWithinRange.isAfter(hardMin)) {
      startDateMin = minDateWithinRange;
    }
    const maxDateWithinRange = moment(selectedStartDate).add(
      (maxRange - 1) * 1,
      "day"
    );
    if (maxDateWithinRange.isBefore(maxDate)) {
      startDateMax = maxDateWithinRange;
    }
  }

  const sevenDaysAgo = moment(today).add(-7, "days");
  const fourteenDaysAgo = moment(today).add(-14, "days");
  const thirtyDaysAgo = moment(today).add(-30, "days");

  const handleClose = useCallback(() => {
    const hasStart = !isNullOrUndefined(selectedStartDate);
    const hasEnd = !isNullOrUndefined(selectedEndDate);
    if (!hasStart || !hasEnd) {
      // Revert back to orig
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      onChange(startDate, endDate);
    }
    setIsActive(false);
  }, [selectedStartDate, selectedEndDate, onChange, startDate, endDate]);

  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef, handleClose]);

  function toggleClose() {
    if (isActive) {
      handleClose();
    } else {
      setIsActive(true);
    }
  }

  function handleSelectDate(newValue) {
    const hasStart = !isNullOrUndefined(selectedStartDate);
    const hasEnd = !isNullOrUndefined(selectedEndDate);
    if (hasStart && hasEnd) {
      // Both fields are populated, treat as making new range selection
      setSelectedStartDate(newValue);
      setSelectedEndDate(null);
    } else if (hasStart) {
      if (newValue.isBefore(selectedStartDate)) {
        setSelectedStartDate(newValue);
        setSelectedEndDate(selectedStartDate);
        onChange(newValue, selectedStartDate);
      } else {
        setSelectedStartDate(selectedStartDate);
        setSelectedEndDate(newValue);
        onChange(selectedStartDate, newValue);
      }
      setIsActive(false);
    }
  }
  function handleSelectPresetDate(newStart, newEnd) {
    setSelectedStartDate(newStart);
    setSelectedEndDate(newEnd);
    onChange(newStart, newEnd);
    setIsActive(false);
  }
  return (
    <div className="daterange-picker" ref={componentRef}>
      <button
        className={`capitalized ${
          isActive ? "date-picker-active" : "date-picker"
        }`}
        onClick={() => {
          toggleClose();
        }}
      >
        <Calendar />
        {`${startDateDisplay} - ${endDateDisplay}`}
      </button>
      {isActive && (
        <div className="daterange-picker-selectors drop-shadow">
          <div className="daterange-picker-container">
            <div className="presets">
              <div>
                <div
                  className="preset-choice"
                  onClick={() => {
                    handleSelectPresetDate(sevenDaysAgo, today);
                  }}
                >
                  <span>{t("last_days_web", { value: 7 })}</span>
                </div>
                <div
                  className="preset-choice"
                  onClick={() => {
                    handleSelectPresetDate(fourteenDaysAgo, today);
                  }}
                >
                  <span>{t("last_days_web", { value: 14 })}</span>
                </div>
                <div
                  className="preset-choice"
                  onClick={() => {
                    handleSelectPresetDate(thirtyDaysAgo, today);
                  }}
                >
                  <span>{t("last_days_web", { value: 30 })}</span>
                </div>
              </div>
            </div>
            <div>
              <StaticDatePicker
                value={selectedStartDate}
                minDate={startDateMin}
                maxDate={startDateMax}
                slotProps={{
                  toolbar: {
                    hidden: true,
                  },
                  actionBar: {
                    actions: [],
                  },
                  day: {
                    selectedStartDate: selectedStartDate,
                    selectedEndDate: selectedEndDate,
                  },
                }}
                slots={{
                  day: SelectedRangeDay,
                }}
                onChange={(newValue) => {
                  handleSelectDate(newValue);
                  //   onChange(selectedStartDate, newValue);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const SelectedRangeDay = (props) => {
  const { selectedStartDate, selectedEndDate, day, ...other } = props;
  const mDay = moment(day);
  const sx = {};
  const hasStart = !isNullOrUndefined(selectedStartDate);
  const hasEnd = !isNullOrUndefined(selectedEndDate);

  const isWithinRange =
    hasStart &&
    hasEnd &&
    mDay.isAfter(selectedStartDate) &&
    mDay.isBefore(selectedEndDate);

  const pickerDay = mDay.format(DATE_FORMAT);
  const startDate = selectedStartDate.format(DATE_FORMAT);
  const endDate = selectedEndDate?.format(DATE_FORMAT);
  const isSelected =
    (hasStart && pickerDay === startDate) || (hasEnd && pickerDay === endDate);

  if (!isSelected) {
    sx.backgroundColor = isWithinRange
      ? "rgba(23, 121, 218, .15)"
      : "transparent";
  }
  return <PickersDay {...other} day={day} sx={sx} selected={isSelected} />;
};

export default RangePicker;
