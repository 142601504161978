import { TurnaroundOperationStatus } from "../../turnaroundUtils";
import { isNullOrUndefined } from "../../utils";
import { ReactComponent as AlertRed } from "../../assets/alert-small-red.svg";

function TurnaroundProgressBar(props) {
  const { turnaroundInfo, showOperationStatus } = props;

  let statusClass = "not-started";
  if (turnaroundInfo?.percentComplete === 100) {
    statusClass = "completed";
  } else if (turnaroundInfo?.percentComplete > 0) {
    statusClass = "in-service";
  }

  const status = turnaroundInfo?.turnaroundLevelOperationStatus;
  const statusMissingCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.MISSING]
    : 0;
  const statusDelayedCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.IN_PROGRESS_LATE]
    : 0;

  // NOTE: Missing or Delayed both are considered Critical
  // These counts are decremented if the user has ack'd them
  const totalCriticalCount = statusMissingCount + statusDelayedCount;
  const showCriticalState = showOperationStatus && totalCriticalCount > 0;

  return (
    <div className={`turnaround-progress ${statusClass}`}>
      <div className="progress-info">
        <div>
          <div className="status-badge">{turnaroundInfo.statusDisplayName}</div>
        </div>
        <div>{turnaroundInfo.percentComplete}%</div>
        <div className="operation-status">
          {showCriticalState && (
            <div className="operation-status-badge status-critical">
              <div>
                <div>
                  <AlertRed />
                </div>
                <div>{totalCriticalCount}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="progress-bar">
        <div
          className="progress-bar-filled"
          style={{
            width: `${turnaroundInfo.percentComplete}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default TurnaroundProgressBar;
