import "./styles.css";
import { useTranslation } from "react-i18next";
import CrewShiftsModule from "./CrewShiftsModule";
import ButtonDatePicker from "../ButtonDatePicker";
import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment-timezone";
import { MIN_DATE } from "../../constants";
import {
  formatLongDate,
  getByFieldValue,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getCertifications,
  getOperationTypes,
  getTurnaroundsSummary,
  getUsers,
} from "../../api";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import TitleBadge from "../TitleBadge";
import { getUserInfo } from "../../userUtils";
import {
  getFileUpload,
  importPreviewCrewShifts,
  searchCrewShifts,
  uploadCrewShiftsFile,
} from "../../crewShiftsApi";
import {
  useCrewShiftsContext,
  useCrewShiftsDispatchContext,
} from "../../CrewShiftsContext";
import CrewShiftPreviewModal from "./CrewShiftPreviewModal";

function CrewShifts() {
  const { t } = useTranslation();

  const dispatch = useMainDispatchContext();
  const crewShiftsDispatch = useCrewShiftsDispatchContext();
  const mainContext = useMainContext();
  const crewShiftsContext = useCrewShiftsContext();
  const {
    users,
    certifications,
    currentUser,
    operationTypes,
    turnaroundsSummary,
    positions,
  } = mainContext;
  const { globalError, fileUpload, crewShifts, crewShiftsImportPreview } =
    crewShiftsContext;

  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");

  const [selectedDate, setSelectedDate] = useState(mToday);
  const [isUploading, setIsUploading] = useState(false);
  const [importFileUploadUuid, setImportFileUploadUuid] = useState(null);

  useEffect(() => {
    sortByField(operationTypes, "name");
  }, [operationTypes]);

  const [crewShiftsToRender, crewShiftsByUserUuid] = useMemo(() => {
    const crewShiftsMap = {};
    const crewShiftsList = [];
    if (!isEmptyList(crewShifts) && !isEmptyList(users)) {
      for (let i = 0; i < crewShifts.length; i++) {
        const crewShift = crewShifts[i];
        const crewShiftUser = getByFieldValue(
          users,
          "uuid",
          crewShift.userUuid
        );
        if (!isNullOrUndefined(crewShiftUser)) {
          const certificationInfo =
            !isNullOrUndefined(crewShift.certification) &&
            !isEmptyList(certifications)
              ? getByFieldValue(certifications, "uuid", crewShift.certification)
              : null;

          const crewShiftInfo = {
            ...crewShift,
            operationTypeUuid: crewShift.operationType?.uuid,
            userInfo: getUserInfo(crewShiftUser, turnaroundsSummary, positions),
            certificationInfo: certificationInfo,
          };
          crewShiftsList.push(crewShiftInfo);

          if (crewShiftUser.uuid in crewShiftsMap) {
            crewShiftsMap[crewShiftUser.uuid].push(crewShiftInfo);
          } else {
            crewShiftsMap[crewShiftUser.uuid] = [crewShiftInfo];
          }
        }
      }
    }
    sortByField(crewShiftsList, "userInfo.fullName", ["startTime"]);
    return [crewShiftsList, crewShiftsMap];
  }, [crewShifts, certifications, users, turnaroundsSummary, positions]);

  useEffect(() => {
    // Fetch other data needed for this page
    getUsers(dispatch);
    getTurnaroundsSummary(dispatch);
    getCertifications(dispatch);
    getOperationTypes(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Fetch crewShifts for the specified date
    const startTime = selectedDate.toDate().toISOString();
    const endTime = moment(selectedDate).add(1, "day").toDate().toISOString();
    searchCrewShifts(crewShiftsDispatch, startTime, endTime);
  }, [crewShiftsDispatch, selectedDate]);

  // File uploading
  useEffect(() => {
    if (!isNullOrUndefined(fileUpload?.uploadUrl)) {
      const file = fileInputRef.current?.files[0];

      // Reset function
      async function resetAll() {
        setIsUploading(false);
        crewShiftsDispatch({
          type: "setFileUpload",
          value: null,
        });
        fileInputRef.current.value = "";
      }
      if (isNullOrUndefined(file)) {
        // reset
        resetAll();
      } else {
        const reader = new FileReader();
        const fileUploadUuid = fileUpload.uuid;
        const uploadUrl = fileUpload.uploadUrl;
        const fileType = file.type;

        // Function to call once the file bytes are read
        async function fileOnloadHandler() {
          var arrayBuffer = reader.result;
          var bytes = new Uint8Array(arrayBuffer);

          const fileResult = await uploadCrewShiftsFile(
            crewShiftsDispatch,
            uploadUrl,
            bytes,
            fileType
          );
          if (fileResult) {
            // Show the preview to the user before actual import
            const importPreviewResult = await importPreviewCrewShifts(
              crewShiftsDispatch,
              fileUploadUuid
            );
            setImportFileUploadUuid(fileUploadUuid);
            if (!importPreviewResult) {
              resetAll();
            }
          }
          resetAll();
        }
        reader.onload = fileOnloadHandler;

        // Start reading the file bytes
        reader.readAsArrayBuffer(file);
      }
    }
  }, [t, dispatch, crewShiftsDispatch, fileUpload, globalError]);

  useEffect(() => {
    if (!isBlank(globalError)) {
      dispatch({
        type: "setGlobalError",
        globalError: globalError,
      });
    }
  }, [crewShiftsDispatch, dispatch, globalError]);

  const crewShiftCount = !isNullOrUndefined(crewShiftsByUserUuid)
    ? Object.keys(crewShiftsByUserUuid).length
    : 0;

  const fileInputRef = useRef(null);
  function handleUploadClick() {
    fileInputRef.current.click();
  }

  function handleUploadFile() {
    setIsUploading(true);
    getFileUpload(crewShiftsDispatch);
    crewShiftsDispatch({
      type: "setGlobalError",
      globalError: null,
    });
  }

  function refreshCrewShifts() {
    // Fetch crewShifts for the specified date
    const startTime = selectedDate.toDate().toISOString();
    const endTime = moment(selectedDate).add(1, "day").toDate().toISOString();
    searchCrewShifts(crewShiftsDispatch, startTime, endTime);
  }

  return (
    <div className="crew-shifts">
      <div className="crew-shifts-header">
        <div>
          <div>
            <h3 className="badged">
              {t("crew_shifts")}
              <TitleBadge value={crewShiftCount} />
            </h3>
            <div>
              <span className="capitalized">
                {formatLongDate(selectedDate)}
                {selectedDate.isSame(mToday) && (
                  <>&nbsp;&middot;&nbsp;{t("today")}</>
                )}
              </span>
            </div>
          </div>
          <div className="actions">
            <div>
              <button
                className="secondary"
                onClick={handleUploadClick}
                disabled={isUploading}
              >
                <Upload />
                {isUploading ? t("uploading") : t("upload_csv")}
              </button>
              <input
                className="not-visible"
                type="file"
                ref={fileInputRef}
                accept=".csv"
                onChange={handleUploadFile}
              />
            </div>
            <div>
              <ButtonDatePicker
                label={
                  selectedDate == null
                    ? null
                    : selectedDate.format("MM/DD/YYYY")
                }
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                }}
                minDate={moment(MIN_DATE)}
                maxDate={moment(mToday).add(2, "month")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="crew-shifts-container">
        <div className="crew-shifts-content">
          <CrewShiftsModule
            crewShifts={crewShifts}
            crewShiftsToRender={crewShiftsToRender}
            operationTypes={operationTypes}
            selectedDate={selectedDate}
            onCrewShiftUpdate={() => {
              refreshCrewShifts();
            }}
          />
        </div>
      </div>
      {!isNullOrUndefined(crewShiftsImportPreview) && (
        <CrewShiftPreviewModal
          timezone={airportTimezone}
          crewShiftsImportPreview={crewShiftsImportPreview}
          users={users}
          fileUploadUuid={importFileUploadUuid}
          onCrewShiftImport={() => {
            crewShiftsDispatch({
              type: "setCrewShiftsImportPreview",
              value: null,
            });
            dispatch({
              type: "setAlertMessage",
              alertMessage: t("import_complete"),
            });
            refreshCrewShifts();
          }}
        />
      )}
    </div>
  );
}

export default CrewShifts;
