import {
  getApiByResourceName,
  handleBadResponse,
  handleException,
} from "./api";
import i18next from "./i18n";

export async function getChatSummary(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "chat-summary",
    "ChatSummary",
    "get"
  );
}

export async function getChatChannelDetails(dispatch, channelId) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels/${channelId}`,
    "ChatChannel",
    "get"
  );
}

export async function patchChatChanneLastRead(
  dispatch,
  channelId,
  lastReadTime
) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels/${channelId}/last-read`,
    "ChatChannelLastRead",
    "PATCH",
    {
      lastReadTime: lastReadTime,
    }
  );
}

// Creates a new channel
export async function postChatChannel(dispatch, channel) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels`,
    "CreateChatChannel",
    "post",
    channel
  );
}

export async function postChatChannelMessage(dispatch, channelId, content) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels/${channelId}/message`,
    "ChatChannelMessage",
    "post",
    content
  );
}

// Updates the channel members (this is the only thind)
export async function patchChatChannelMembers(
  dispatch,
  channelId,
  membersToAdd
) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels/${channelId}/members`,
    "ChatChannelMembers",
    "PATCH",
    {
      membersToAdd: membersToAdd,
    }
  );
}

export async function getChatAttachmentUpload(dispatch, channelId) {
  return await getApiByResourceName(
    dispatch,
    `chat/channels/${channelId}/attachment-upload`,
    "ChatAttachmentUpload",
    "get"
  );
}

export async function uploadChatAttachment(
  dispatch,
  uploadUrl,
  fileData,
  fileType
) {
  try {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
        "Cache-Control": "no-cache",
      },
      body: fileData,
    });
    await response.blob();
    if (!response.ok) {
      // Check errors?
      handleBadResponse(response, null, dispatch);
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("login_user_error_msg"),
    });
  }
}
