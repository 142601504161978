import { useRef, useState } from "react";
import { isBlank, isNullOrUndefined } from "../../utils";
import { scaleCommsImage } from "../../commsUtils";
import LoadingIndicator from "../LoadingIndicator";
import { useChatDispatchContext } from "../../ChatContext";
import moment from "moment-timezone";

function ChatBubble(props) {
  const { mediaSignatures, message, isLoading, onLoaded, index, timezone } =
    props;
  const chatDispatch = useChatDispatchContext();
  const imageRef = useRef(null);
  const bubbleRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const mNow = moment().tz(timezone);
  const mSentAt = moment(message?.sentAt).tz(timezone);
  const ageInMinutes = moment.duration(mNow.diff(mSentAt)).as("minutes");

  const commsChannelAttachment = message?.content?.attachment;
  const fullSizeMediaSignature =
    mediaSignatures.length > 0 ? mediaSignatures[0].signature : null;
  const thumbnailMediaSignature =
    mediaSignatures.length > 1 ? mediaSignatures[1].signature : null;

  const attachmentUrl =
    ageInMinutes > 2
      ? `${commsChannelAttachment?.thumbnailUrl}${thumbnailMediaSignature}`
      : `${commsChannelAttachment?.fullSizeUrl}${fullSizeMediaSignature}`;
  let imageWidth = 200;
  let imageHeight = 200;

  // Attachments *should* have dimensions set on them, but just in case
  const hasAttachmentDimensions =
    !isNullOrUndefined(commsChannelAttachment?.width) &&
    !isNullOrUndefined(commsChannelAttachment?.height);

  if (hasAttachmentDimensions) {
    const [scaledWidth, scaledHeight] = scaleCommsImage(
      commsChannelAttachment.width,
      commsChannelAttachment.height
    );
    imageWidth = scaledWidth;
    imageHeight = scaledHeight;
  }

  const bubbleStyle =
    !isNullOrUndefined(commsChannelAttachment) ||
    !isNullOrUndefined(commsChannelAttachment)
      ? {
          backgroundImage: !isBlank(attachmentUrl)
            ? `url(${attachmentUrl})`
            : "",
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
        }
      : {};

  function handleImageLoad() {
    if (!hasAttachmentDimensions) {
      // No image dimensions, try getting them by loading it into the browser
      imageRef.current.style.display = "";
      const size = imageRef.current.getBoundingClientRect();
      imageRef.current.style.display = "none";
      const [scaledWidth, scaledHeight] = scaleCommsImage(
        size.width,
        size.height
      );
      bubbleRef.current.style.width = `${scaledWidth}px`;
      bubbleRef.current.style.height = `${scaledHeight}px`;
      setDimensions([scaledWidth, scaledHeight]);
    } else {
      setDimensions([imageWidth, imageHeight]);
    }
    onLoaded();
  }

  const hasValidAttachment = !isNullOrUndefined(commsChannelAttachment);
  function handleClick() {
    if (hasValidAttachment) {
      chatDispatch({
        type: "setSelectedAttachment",
        value: {
          ...commsChannelAttachment,
          dimensions: dimensions,
        },
      });
    }
  }

  const testId = !isNullOrUndefined(index) ? `chat-bubble-${index}` : null;
  return (
    <div
      className={`chat-message-bubble${
        hasValidAttachment ? " attachment" : ""
      }${message?.isTemp ? " animate" : " chat-bubble"}`}
      style={bubbleStyle}
      ref={bubbleRef}
      onClick={handleClick}
      data-testid={testId}
    >
      <div>
        {message?.content.text}
        {!isNullOrUndefined(commsChannelAttachment) && (
          <div className="hideout">
            <img
              ref={imageRef}
              src={attachmentUrl}
              onLoad={handleImageLoad}
              alt=""
              style={{ display: "none" }}
            />
          </div>
        )}
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
}
export default ChatBubble;
