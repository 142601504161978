import { useMemo, useRef } from "react";
import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import moment from "moment-timezone";
import {
  formatDate,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { CHART_COLOR_ALL, CHART_SERIES_COLORS } from "../../constants";
import { useTranslation } from "react-i18next";

function TurnaroundOperationsTrend(props) {
  const { t } = useTranslation();
  const { airportTimezone, chartData, selectedOperations, operationTypes } =
    props;
  registerTheme("Moonware", MoonwareTheme.theme);
  const chartRef = useRef();

  const [trendDateBuckets, trendSeriesData] = useMemo(() => {
    let dateBuckets = [];
    let seriesData = [];

    if (!isNullOrUndefined(chartData?.aggregatesByOperationType)) {
      const operationUuids = Object.keys(chartData.aggregatesByOperationType);
      for (let i = 0; i < operationUuids.length; i++) {
        const operationUuid = operationUuids[i];
        const operationType = getByFieldValue(
          operationTypes,
          "uuid",
          operationUuid
        );
        const operationData =
          chartData.aggregatesByOperationType[operationUuid];
        if (isEmptyList(dateBuckets)) {
          // Collect the date values
          dateBuckets = operationData.buckets.map(
            (bucket) => bucket.weekStartTime
          );
        }
        const operationBucketData = operationData.buckets.map(
          (bucket) => bucket.onTimePercent
        );
        // Include data for selected operations (none means include all)
        if (
          isEmptyList(selectedOperations) ||
          selectedOperations.includes(operationUuid)
        ) {
          seriesData.push(
            generateSeriesConfig(
              operationType.name,
              operationBucketData,
              seriesData.length
            )
          );
        }
      }
    }
    return [dateBuckets, seriesData];
  }, [chartData, selectedOperations, operationTypes]);

  const option = {
    axisPointer: {
      shadowStyle: {
        color: "rgba(23, 121, 218, 0.25)",
      },
      label: {
        backgroundColor: "rgba(23, 121, 218, 0.5)",
      },
    },
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "axis",
      confine: true,
      position: function (point, params, dom, rect, size) {
        // Custom position function to keep the legend/data within view
        const viewWidth = size?.viewSize[0] - 16;
        const seriesCount = trendDateBuckets?.length;
        const seriesWidth =
          viewWidth > 0 && seriesCount > 0 ? viewWidth / seriesCount : 0;

        const tooltipWidth = dom.getBoundingClientRect().width;
        const tooltipHeight = dom.getBoundingClientRect().height;

        if (seriesCount === 1) {
          const left = point[0] - tooltipWidth - 32;
          if (left > viewWidth / 2) {
            return [left, point[1] - tooltipHeight / 2];
          } else {
            return [point[0] + 32, point[1] - tooltipHeight / 2];
          }
        }
        const left = point[0] - seriesWidth / 2 - tooltipWidth;
        if (left > 0) {
          return [left, point[1] - tooltipHeight / 2];
        } else {
          return [point[0] + seriesWidth / 2, point[1] - tooltipHeight / 2];
        }
      },
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "rgba(23, 121, 218, .75)",
        },
        label: {
          formatter: (params) => {
            return typeof params.value === "string"
              ? `${formatDate(
                  moment(params.value).tz(airportTimezone).toDate(),
                  airportTimezone
                )}\n`
              : `${Math.round(params.value)}%`;
          },
        },
      },
      textStyle: {
        color: "#ffffff",
        fontSize: trendSeriesData?.length > 6 ? 8 : 12,
      },
    },

    legend: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        data: trendDateBuckets,
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          formatter: (value) => {
            return `${formatDate(
              moment(value).tz(airportTimezone).toDate(),
              airportTimezone
            )}`;
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: (value) => {
            return `${value}%`;
          },
        },
        splitLine: {
          lineStyle: {
            color: "#4A4C56",
            type: [1, 5],
            width: 2,
            cap: "round",
          },
        },
      },
    ],
    series: trendSeriesData,
    animationDuration: 250,
  };

  if (!isNullOrUndefined(chartRef.current)) {
    chartRef.current.getEchartsInstance();
  }

  return (
    <div className="chart-container">
      <div className="chart-title-bar">
        <h4>{t("operation_on_time_performance")}</h4>
      </div>
      {trendDateBuckets.length === 0 && (
        <div className="empty-state">{t("no_results_for_selected_range")}</div>
      )}
      {trendDateBuckets.length > 0 && (
        <div className="chart-component">
          <ReactECharts
            ref={chartRef}
            option={option}
            style={{ height: "380px", width: "100%" }}
            theme="Moonware"
            notMerge={true}
          />
        </div>
      )}
    </div>
  );
}

function generateSeriesConfig(name, data, idx) {
  const itemStyle = {
    borderRadius: 5,
    opacity: "1.0",
  };
  if (isNullOrUndefined(idx)) {
    itemStyle.color = CHART_COLOR_ALL;
  } else if (idx < CHART_SERIES_COLORS.length) {
    itemStyle.color = CHART_SERIES_COLORS[idx];
  }
  return {
    name: name,
    type: "bar",
    smooth: 0.25,
    tooltip: {
      valueFormatter: function (value) {
        return value === "-" ? value : `${value}%`;
      },
    },
    data: data,
    itemStyle: itemStyle,
    emphasis: {
      itemStyle: {
        opacity: "1.0",
      },
    },
    lineStyle: {
      width: 3,
    },
    symbolSize: 8,
    showSymbol: false,
  };
}
export default TurnaroundOperationsTrend;
