import "./styles.css";
import { ReactComponent as Battery } from "../../assets/tracker/battery.svg";
import { ReactComponent as Sat } from "../../assets/tracker/sat.svg";
import { ReactComponent as Cell } from "../../assets/tracker/cell.svg";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils";

function TrackerStatus(props) {
  const { t } = useTranslation();

  const { hasPower, battery, satStrengthPercent, cellStrengthPercent } = props;

  const batteryFill = {
    background: `linear-gradient(to right, #B0B7C3 ${battery}%,  transparent ${battery}%)`,
  };

  const batteryValue = !isNullOrUndefined(battery) ? `${battery}%` : t("na");
  const satValue = !isNullOrUndefined(satStrengthPercent)
    ? `${satStrengthPercent}%`
    : t("na");
  const cellValue = !isNullOrUndefined(cellStrengthPercent)
    ? `${cellStrengthPercent}%`
    : t("na");
  return (
    <div className="tracker-status">
      <div>
        <div className="cell">
          <div>
            <div className="cell-icon">
              <div className="cell-icon-battery">
                <div
                  className="cell-icon-battery-fill"
                  style={batteryFill}
                ></div>
                <Battery />
              </div>
            </div>
            <div className="cell-value">
              {hasPower ? t("power_abbr").toUpperCase() : batteryValue}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="cell">
          <div>
            <div className="cell-icon">
              <div className="cell-icon-sat">
                <Sat />
              </div>
            </div>
            <div className="cell-value">{satValue}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="cell">
          <div>
            <div className="cell-icon">
              <div className="cell-icon-cell">
                <Cell />
              </div>
            </div>
            <div className="cell-value">{cellValue}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TrackerStatus;
