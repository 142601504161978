import { useState } from "react";
import {
  deepCopy,
  formatTime,
  isEmptyList,
  isNullOrUndefined,
  makeKeyFriendlyString,
} from "../../utils";
import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import TimeRangeInput from "../TimeRangeInput";
import { selectInputFix } from "../../styleUtils";
import CrewInfo from "../CrewInfo";

function CrewShiftsRow(props) {
  const { t } = useTranslation();
  const {
    crewShift,
    airportTimezone,
    isEditable,
    onSelectCrewShift,
    onSaveCrewShift,
    onRemoveCrewShift,
    operationTypes,
  } = props;
  const [crewShiftToEdit, setCrewShiftToEdit] = useState(null);
  const dispatch = useMainDispatchContext();

  if (isNullOrUndefined(crewShift)) return null;

  function handleRemove(crewShift) {
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: <CrewShiftRemoveConfirmation crewShift={crewShift} />,
        actionLabel: t("remove"),
        actionOK: () => {
          onRemoveCrewShift(deepCopy(crewShift));
          setCrewShiftToEdit(null);
        },

        actionCancel: () => {
          dispatch({
            type: "setConfirmation",
            confirmation: null,
          });
        },
      },
    });
  }

  return (
    <>
      {
        /** Read mode **/
        isNullOrUndefined(crewShiftToEdit) && (
          <div
            className={`crew-shifts-module-row${
              !isEditable ? " read-only" : ""
            }`}
          >
            <div>
              <CrewInfo
                user={crewShift.userInfo.user}
                details={t(crewShift.userInfo.dispatchStatus)}
                mapStatus={crewShift.userInfo.mapStatus}
              />
            </div>
            <div>{`${formatTime(
              crewShift.startTime,
              airportTimezone
            )} - ${formatTime(crewShift.endTime, airportTimezone)}`}</div>
            <div>
              {!isNullOrUndefined(crewShift.operationName) &&
                crewShift.operationName}
            </div>
            <div className="actions">
              <div>
                <button
                  className="danger-outline"
                  onClick={() => {
                    handleRemove(crewShift);
                  }}
                  disabled={!isEditable}
                >
                  {t("remove")}
                </button>
              </div>
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    setCrewShiftToEdit(deepCopy(crewShift));
                    onSelectCrewShift(crewShift.uuid);
                  }}
                  disabled={!isEditable}
                >
                  {t("edit")}
                </button>
              </div>
            </div>
          </div>
        )
      }
      {
        /** Edit mode **/
        !isNullOrUndefined(crewShiftToEdit) && (
          <div className="crew-shifts-module-row selected">
            <div>
              <CrewInfo
                user={crewShift.userInfo.user}
                details={t(crewShift.userInfo.dispatchStatus)}
                mapStatus={crewShift.userInfo.mapStatus}
              />
            </div>
            <div>
              <div>
                <TimeRangeInput
                  onTimeRangeChange={(timerangeValues) => {
                    const updatedCrewShift = deepCopy(crewShiftToEdit);
                    updatedCrewShift.startTime = timerangeValues.startTime;
                    updatedCrewShift.endTime = timerangeValues.endTime;
                    setCrewShiftToEdit(updatedCrewShift);
                  }}
                  timeRangeValues={{
                    startTime: crewShiftToEdit.startTime,
                    endTime: crewShiftToEdit.endTime,
                  }}
                  timezone={airportTimezone}
                />
              </div>
            </div>
            <div>
              <div>
                {!isEmptyList(operationTypes) && (
                  <select
                    className="operations-input"
                    style={selectInputFix}
                    value={
                      !isNullOrUndefined(crewShiftToEdit.operationTypeUuid)
                        ? crewShiftToEdit.operationTypeUuid
                        : ""
                    }
                    onChange={(e) => {
                      crewShiftToEdit.operationTypeUuid = e.target.value;
                    }}
                  >
                    <option value="">{t("any_certified_operation")}</option>
                    {operationTypes &&
                      operationTypes.map((operationType) => (
                        <option
                          value={operationType.uuid}
                          key={makeKeyFriendlyString(operationType.uuid)}
                        >
                          {operationType.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="actions">
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    setCrewShiftToEdit(null);
                    onSelectCrewShift(null);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  className="primary"
                  onClick={() => {
                    onSaveCrewShift(crewShiftToEdit);
                    setCrewShiftToEdit(null);
                  }}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

function CrewShiftRemoveConfirmation(props) {
  const { crewShift } = props;
  return (
    <div>
      <div className="confirmation-detail">
        <div>Remove crew shift</div>
      </div>
      <div>
        <span>Confirm remove crew shift for {crewShift.userInfo.fullName}</span>
      </div>
    </div>
  );
}
export default CrewShiftsRow;
