import { useTranslation } from "react-i18next";
import { isBlank, isEmptyList, isNullOrUndefined } from "../../utils";

function DispatchOverrideDetails(props) {
  const { t } = useTranslation();
  const { operationOverride } = props;
  const detailPairs = [];

  if (!isBlank(operationOverride.overrideDuration)) {
    detailPairs.push([
      t("duration"),
      formatValue(operationOverride.overrideDuration, "m"),
    ]);
  }
  if (!isBlank(operationOverride.overrideStartOffsetMinutes)) {
    detailPairs.push([
      t("start_time_offset"),
      formatValue(operationOverride.overrideStartOffsetMinutes, "m"),
    ]);
  }
  if (!isNullOrUndefined(operationOverride.overrideEnabled)) {
    detailPairs.push([
      t("enabled"),
      operationOverride.overrideEnabled
        ? t("true_web").toUpperCase()
        : t("false_web").toUpperCase(),
    ]);
  }
  if (!isEmptyList(operationOverride?.requirementOverrides)) {
    for (let i = 0; i < operationOverride?.requirementOverrides.length; i++) {
      const requirementOverride = operationOverride?.requirementOverrides[i];
      detailPairs.push([
        requirementOverride.certification.name,
        formatValue(requirementOverride.overrideQuantity),
      ]);
    }
  }

  return (
    <div>
      <div className="turnaround-label-operation-info">
        <div>{operationOverride.operationName}</div>
      </div>
      <div className="turnaround-label-operation-details">
        <div className="turnaround-label-operation-details-section">
          {detailPairs &&
            detailPairs.map((values, idx) => (
              <div className="label-value-row" key={`detail_row_${idx}`}>
                <div>{values[0]}</div>
                <div>{values[1]}</div>
              </div>
            ))}
        </div>
        <div className="turnaround-label-operation-details-section"></div>
      </div>
    </div>
  );
}

function formatValue(value, suffix) {
  const prefix = value > 0 ? "+" : "";
  return `${prefix}${value}${!isBlank(suffix) ? suffix : ""}`;
}
export default DispatchOverrideDetails;
