import moment from "moment-timezone";
// Need to import each supported language
import i18next from "./i18n";
import es from "moment/locale/es";
import fr from "moment/locale/fr";

import { DEFAULT_TIMEZONE, ID_FIELDS, SCROLL_STYLES } from "./constants";

const EMAIL_REGEX = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);
const PASSWORD_REGEX = new RegExp(
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
);
export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
export function isEmptyList(value) {
  return !isNullOrUndefined(value) && value?.length > 0 ? false : true;
}

export function isBlank(value) {
  if (isNullOrUndefined(value)) {
    return true;
  }
  return typeof value === "string" ? value.trim().length === 0 : false;
}

/** Cheap version of cloning objects, does not guarantee property name order **/
export function deepCopy(origin) {
  return JSON.parse(JSON.stringify(origin));
}

export function isValidEmail(value) {
  if (isNullOrUndefined(value) || value.length < 3) return false;
  return EMAIL_REGEX.test(value);
}

// For use when a user creates or resets the password
export function isValidPassword(value) {
  if (isNullOrUndefined(value)) return false;
  return PASSWORD_REGEX.test(value);
}

export function roundToNearestHour(dateObj) {
  const m = moment(dateObj);
  const rounded =
    m.minute() >= 30 ? m.add(1, "hour").startOf("hour") : m.startOf("hour");
  return rounded.toDate();
}

export function formatDate(value, tz) {
  return formatDateWithPattern(value, tz, "DD MMM").toUpperCase();
}

export function formatMediumDate(value, tz) {
  return formatDateWithPattern(value, tz, "MMM DD yyyy");
}

export function formatLongDate(value, tz) {
  return formatDateWithPattern(value, tz, "MMMM DD, yyyy");
}

function formatDateWithPattern(value, tz, pattern) {
  const mTime = getLocalMoment(new Date(value));
  if (!isNullOrUndefined(tz)) {
    mTime.tz(tz);
  }
  return mTime.format(pattern).replaceAll(".", "");
}

export function formatTimeRange(startTime, endTime, timezone) {
  if (!isNullOrUndefined(endTime)) {
    return `${formatTime(startTime, timezone)} - ${formatTime(
      endTime,
      timezone
    )}`;
  } else {
    return formatTime(startTime, timezone);
  }
}

// Returns an instance of moment that is localized with the selected language
// Should use this for formatting date strings
export function getLocalMoment(dateObj) {
  if ("fr" === i18next.language) {
    return moment(dateObj).locale("fr", fr);
  } else if ("es" === i18next.language) {
    return moment(dateObj).locale("es", es);
  }
  return moment(dateObj).locale("en");
}

export function formatTime(value, tz, showPlusDays, refDate, showTimezone) {
  if (isNullOrUndefined(value)) return null;
  const mValue = moment(new Date(value));
  if (!isNullOrUndefined(tz)) {
    mValue.tz(tz);
  }
  const pattern = showTimezone ? "HH:mm z" : "HH:mm";
  let timeVal = mValue.format(pattern).toUpperCase();
  if (showPlusDays) {
    const plusDays = getPlusDays(mValue, tz, refDate);
    if (plusDays > 0) {
      timeVal = `${timeVal} +${plusDays}`;
    } else if (plusDays < 0) {
      timeVal = `${timeVal} ${plusDays}`;
    }
  }
  return timeVal;
}

export function formatDatetime(value, tz) {
  return formatDateWithPattern(value, tz, "DD MMM HH:mm");
}

export function formatLongDatetime(value, tz) {
  return formatDateWithPattern(value, tz, "DD MMM YYYY HH:mm");
}

export function formatDuration(ms) {
  return moment.utc(parseInt(ms)).format("HH:mm");
}

export function formatHoursMins(totalMins) {
  const hrs = Math.floor(totalMins / 60);
  const mins = Math.floor(totalMins % 60);
  return totalMins > 59 ? `${hrs}h${mins > 0 ? ` ${mins}m` : ""}` : `${mins}m`;
}

function getPlusDays(mValue, tz, refDate) {
  // display number of days that the date is ahead of today
  const mRefDate = !isNullOrUndefined(refDate)
    ? moment(refDate)
    : moment(new Date());
  if (!isNullOrUndefined(tz)) {
    mRefDate.tz(tz);
  }
  mRefDate.startOf("day");
  const mTimeStartOfDay = moment(mValue).startOf("day");
  const daysDifference = mTimeStartOfDay.diff(mRefDate, "days");
  if (daysDifference !== 0) {
    return daysDifference;
  }
  return 0;
}

export function getMinMaxTimeValue(items, tz) {
  if (isNullOrUndefined(items)) return null;
  const itemsArr = filterEmptyValues(items, tz).sort((a, b) => {
    const val1 = a.toDate();
    const val2 = b.toDate();
    if (val1 === val2) return 0;
    return val1 < val2 ? -1 : 1;
  });
  return [itemsArr[0], itemsArr[itemsArr.length - 1]];
}

function filterEmptyValues(items, tz) {
  if (isNullOrUndefined(items)) return null;
  const values = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!isNullOrUndefined(item)) {
      values.push(moment(item).tz(tz));
    }
  }
  return values;
}

export function sortByDateField(list, fieldName) {
  list.sort((a, b) => {
    const source1 = a[fieldName];
    const source2 = b[fieldName];
    if (isNullOrUndefined(source1) && !isNullOrUndefined(source2)) {
      return 1;
    } else if (!isNullOrUndefined(source1) && isNullOrUndefined(source2)) {
      return -1;
    }
    const val1 = new Date(source1);
    const val2 = new Date(source2);

    if (val1 === val2) return 0;
    return val1 > val2 ? 1 : -1;
  });
}

// Sorts the list of objects by the given fieldName
// fieldName may be a nested field like "user.name"
// altFields (optional) list of tieBreaker sorting fields [ "lastName" ]
export function sortByField(list, fieldName, altFields) {
  list.sort((a, b) => {
    let result = compareValues(a, b, fieldName);
    if (result === 0 && altFields?.length > 0) {
      let i = 0;
      while (result === 0 && i < altFields.length) {
        result = compareValues(a, b, altFields[i]);
        i++;
      }
    }
    return result;
  });
}

export function dedupeByField(list, fieldName) {
  const map = {};
  const result = [];
  if (!isNullOrUndefined(list)) {
    for (let i = 0; i < list.length; i++) {
      const listItem = list[i];
      const key = listItem[fieldName];
      if (isNullOrUndefined(map[key])) {
        map[key] = listItem;
        result.push(listItem);
      }
    }
  }
  return result;
}

function compareValues(a, b, fieldName) {
  const fieldNameParts = fieldName?.split(".");
  const val1 =
    fieldNameParts?.length === 2
      ? a[fieldNameParts[0]]?.[fieldNameParts[1]]
      : a[fieldName];

  const val2 =
    fieldNameParts?.length === 2
      ? b[fieldNameParts[0]]?.[fieldNameParts[1]]
      : b[fieldName];

  if (val1 === val2) {
    return 0;
  }
  return val1 > val2 ? 1 : -1;
}

export function getRelativeTime(mTime, mTimeTo) {
  if (isNullOrUndefined(mTime)) return null;
  if (isNullOrUndefined(mTimeTo)) return null;
  return !isNullOrUndefined(mTime)
    ? parseInt(moment.duration(mTime.diff(mTimeTo)).as("minutes"))
    : -1;
}
export function getRelativeTimeString(mTime, mTimeTo) {
  const timeToMins = getRelativeTime(mTime, mTimeTo);
  let timeToText = "now";
  if (timeToMins > 0) {
    timeToText = `in ${formatHoursMins(timeToMins)}`;
  } else if (timeToMins < 0) {
    timeToText = `${formatHoursMins(Math.abs(timeToMins))} ago`;
  }
  return timeToText;
}

export function filterList(list, filterFn) {
  const filteredList = [];
  if (!isNullOrUndefined(list)) {
    for (let i = 0; i < list.length; i++) {
      const listItem = list[i];
      if (filterFn(listItem)) {
        filteredList.push(listItem);
      }
    }
  }
  return filteredList;
}

// Use for partial string matches like with search query fields
export function filterBySearchQuery(searchQuery, list, fieldName, maxResults) {
  if (isNullOrUndefined(list)) return null;
  const results = [];
  for (let i = 0; i < list.length; i++) {
    const flightInfo = list[i];
    const fieldValue = flightInfo[fieldName];
    if (
      isSearchQueryMatch(searchQuery, fieldValue) &&
      (isNullOrUndefined(maxResults) || results.length < maxResults)
    ) {
      results.push(flightInfo);
    }
  }
  return results;
}

export function isSearchQueryMatch(searchTerm, fieldValue) {
  const adjustedSearchQuery = searchTerm?.trim().toLowerCase();
  return (
    isBlank(adjustedSearchQuery) ||
    fieldValue?.toLowerCase().indexOf(adjustedSearchQuery) > -1
  );
}

export function getMapFromList(list, keyField) {
  const map = {};

  return !isNullOrUndefined(list)
    ? list.reduce((acc, val) => {
        acc[val[keyField]] = val;
        return acc;
      }, map)
    : map;
}

/** This compare simple arrays, not guaranteed for objects due to property name order **/
export function isArraySame(arr1, arr2) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

// Return items in arr1 that is not in arr2
export function getArrayDifference(arr1 = [], arr2 = []) {
  return arr1.filter((i) => !arr2.includes(i));
}

export function getById(list, id) {
  return getByFieldValue(list, ID_FIELDS.ID, id);
}

export function getByUuid(list, uuid) {
  return getByFieldValue(list, ID_FIELDS.UUID, uuid);
}

/** Returns the value in the list where the fieldName equals the fieldValue */
// fieldName may be a nested field like "user.name"
export function getByFieldValue(list, fieldName, fieldValue) {
  if (
    isNullOrUndefined(list) ||
    isNullOrUndefined(fieldName) ||
    isNullOrUndefined(fieldValue)
  ) {
    return null;
  }
  const idx = list.findIndex((item) => {
    const fieldNameParts = fieldName?.split(".");
    const val =
      fieldNameParts?.length === 2
        ? item[fieldNameParts[0]]?.[fieldNameParts[1]]
        : item[fieldName];

    return val === fieldValue;
  });
  if (idx > -1) {
    return list[idx];
  }
  return null;
}

export function getMapItemByFieldValue(map, fieldName, fieldValue) {
  if (
    isNullOrUndefined(map) ||
    isNullOrUndefined(fieldName) ||
    isNullOrUndefined(fieldValue)
  ) {
    return null;
  }
  const keys = Object.keys(map);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = map[key];
    if (value[fieldName] === fieldValue) {
      return value;
    }
  }
  return null;
}

export function listWithMaxLength(list, maxLength) {
  if (list?.length > maxLength) {
    const listWithMax = deepCopy(list);
    listWithMax.splice(maxLength);
    return listWithMax;
  }
  return list;
}

export function makeKeyFriendlyString(value) {
  return value.trim().replaceAll(" ", "-").toLowerCase();
}

export function makeTestFriendlyString(value) {
  return value.trim().replaceAll(" ", "-").toLowerCase();
}

export function scrollElementIntoView(containerEl, targetEl, scrollStyle) {
  // Attempt to scroll the element into view if not already
  if (isNullOrUndefined(containerEl) || isNullOrUndefined(targetEl)) return;
  const { top, left, bottom, right } = targetEl.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  const isFullyVisible =
    top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;

  if (!isFullyVisible) {
    containerEl.scrollTo({
      left: 0,
      top: targetEl.offsetTop - 16,
      behavior: !isNullOrUndefined(scrollStyle)
        ? scrollStyle
        : SCROLL_STYLES.SMOOTH,
    });
  }
}

// Returns a list containing the non empty values for the given fieldName
export function getListByField(list, fieldName) {
  const listByField = [];
  if (!isEmptyList(list)) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const itemValue = item[fieldName];
      if (!isBlank(itemValue)) {
        listByField.push(itemValue);
      }
    }
  }
  return listByField;
}

export function getTimezoneFromUser(user) {
  const airport = !isNullOrUndefined(user) ? user.airport : null;
  return !isNullOrUndefined(airport) ? airport.timezone : DEFAULT_TIMEZONE;
}

export function getNearestTimeInterval(timeVal, intervalVal) {
  const nearestValue = moment(timeVal).startOf("minute");
  const minutesValue = nearestValue.minutes();
  if (intervalVal < 0) {
    const remainder = minutesValue % Math.abs(intervalVal);
    nearestValue.add(-1 * remainder, "minute");
  } else {
    const remainder = intervalVal - (minutesValue % intervalVal);
    nearestValue.add(remainder, "minute");
  }
  return nearestValue;
}
