import { useTranslation } from "react-i18next";
import "./styles.css";
function ButtonSwitch(props) {
  const { t } = useTranslation();

  const {
    selectedOption,
    onChange,
    labelValuePairs,
    wideMode,
    lightMode,
    disabled,
  } = props;

  return (
    <div
      className={`button-switch${disabled ? " disabled" : ""}${
        wideMode ? " wide" : ""
      }${lightMode ? " light" : ""}`}
    >
      <div className="button-switch-box">
        {labelValuePairs &&
          labelValuePairs.map((labelValuePair, idx) => (
            <div
              key={idx}
              className={`button-switch-option ${
                selectedOption === labelValuePair.value ? "selected" : ""
              }`}
              onClick={() => {
                if (disabled) return;
                onChange(labelValuePair.value);
              }}
            >
              <span>{t(labelValuePair.value)}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
export default ButtonSwitch;
