import moment from "moment-timezone";
import { formatTime, isBlank, isNullOrUndefined } from "../../utils";

function ScheduledTimeValue(props) {
  const { actualTime, scheduledTime, timezone, selectedDate } = props;

  const actualTimeDisplay = formatTime(
    actualTime,
    timezone,
    true,
    selectedDate
  );

  // If scheduled times are diff, display as striketrough
  const scheduledTimeDisplay =
    !isNullOrUndefined(scheduledTime) && scheduledTime !== actualTime
      ? formatTime(scheduledTime, timezone, true, selectedDate)
      : null;

  const showScheduledTime =
    !isBlank(scheduledTimeDisplay) &&
    actualTimeDisplay !== scheduledTimeDisplay;

  let displayClassName = "";
  if (showScheduledTime) {
    // If showScheduledTime, change the appearance based on before/after
    if (moment(actualTime).isAfter(scheduledTime)) {
      displayClassName = "danger";
    } else if (moment(actualTime).isBefore(scheduledTime)) {
      displayClassName = "affirmative";
    }
  }
  return (
    <span className={displayClassName}>
      {showScheduledTime && (
        <span className="striked">{scheduledTimeDisplay}</span>
      )}
      {actualTimeDisplay}
    </span>
  );
}

export default ScheduledTimeValue;
