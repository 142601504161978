import { formatTime, getByFieldValue, isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
import ChatBubble from "./ChatBubble";

function ChatMessage(props) {
  const { message, mediaSignatures, onImageLoaded, index, timezone } = props;

  const mainContext = useMainContext();
  const { currentUser, turnaroundsForDispatch, users, positions } = mainContext;

  const airportTimezone = currentUser?.airport?.timezone;
  const isIncomingMessage = currentUser.uuid !== message.sentBy;
  const isOutgoingMessage = currentUser.uuid === message.sentBy;
  const isSystemMessage = isNullOrUndefined(message.sentBy);

  const chatUser = getByFieldValue(users, "uuid", message.sentBy);
  const chatUserInfo = !isNullOrUndefined(chatUser)
    ? getUserInfo(chatUser, turnaroundsForDispatch, positions)
    : null;

  return (
    <>
      {isIncomingMessage && !isNullOrUndefined(chatUserInfo) && (
        <div className="chat-message incoming">
          <div>
            <div className="chat-message-userpic">
              <CrewInfo
                user={chatUserInfo?.user}
                mapStatus={chatUserInfo?.mapStatus}
                userPicSize={UserPicSize.LARGE}
                userPicTheme={UserPicTheme.DARK}
                hideInfo={true}
                mediaSignatures={mediaSignatures}
              />
            </div>
          </div>
          <div className="chat-message-content">
            <div className="chat-message-sender">{chatUserInfo.fullName}</div>
            <div className="chat-message-text">
              <ChatBubble
                message={message}
                mediaSignatures={mediaSignatures}
                onLoaded={onImageLoaded}
                timezone={timezone}
              />
            </div>
            <div className="chat-message-timestamp">{`${formatTime(
              message.sentAt,
              airportTimezone
            )}`}</div>
          </div>
        </div>
      )}
      {isOutgoingMessage && (
        <div className="chat-message outgoing">
          <div className="chat-message-text">
            <div>
              <ChatBubble
                message={message}
                onLoaded={onImageLoaded}
                index={index}
                mediaSignatures={mediaSignatures}
                timezone={timezone}
              />
            </div>
          </div>
          <div className="chat-message-timestamp">{`${formatTime(
            message.sentAt,
            airportTimezone
          )}`}</div>
        </div>
      )}
      {isSystemMessage && (
        <div className="chat-system">
          <div>{message.content.text}</div>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
