import moment from "moment-timezone";
import { selectInputNoCaretFix } from "../../styleUtils";

function TimeInput(props) {
  const { timeValue, onTimeChange, timezone } = props;
  const mTimeValue = moment(timeValue).tz(timezone);
  const hoursValue = mTimeValue.get("hour");
  const minutesValue = mTimeValue.get("minutes");
  return (
    <div className="time-input">
      <div>
        <select
          style={selectInputNoCaretFix}
          className="hour-input"
          value={hoursValue}
          onChange={(e) => {
            const updatedTimeValue = moment(timeValue).tz(timezone);
            updatedTimeValue.set("hour", e.target.value);
            onTimeChange(updatedTimeValue.toDate().toISOString());
          }}
        >
          <option value={0}>00</option>
          <option value={1}>01</option>
          <option value={2}>02</option>
          <option value={3}>03</option>
          <option value={4}>04</option>
          <option value={5}>05</option>
          <option value={6}>06</option>
          <option value={7}>07</option>
          <option value={8}>08</option>
          <option value={9}>09</option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
          <option value={13}>13</option>
          <option value={14}>14</option>
          <option value={15}>15</option>
          <option value={16}>16</option>
          <option value={17}>17</option>
          <option value={18}>18</option>
          <option value={19}>19</option>
          <option value={20}>20</option>
          <option value={21}>21</option>
          <option value={22}>22</option>
          <option value={23}>23</option>
        </select>
      </div>
      <div className="time-input-colon">:</div>
      <div>
        <select
          style={selectInputNoCaretFix}
          className="minute-input"
          value={minutesValue}
          onChange={(e) => {
            const updatedTimeValue = moment(timeValue).tz(timezone);
            updatedTimeValue.set("minute", e.target.value);
            onTimeChange(updatedTimeValue.toDate().toISOString());
          }}
        >
          <option value={0}>00</option>
          <option value={5}>05</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={30}>30</option>
          <option value={35}>35</option>
          <option value={40}>40</option>
          <option value={45}>45</option>
          <option value={50}>50</option>
          <option value={55}>55</option>
        </select>
      </div>
    </div>
  );
}
export default TimeInput;
