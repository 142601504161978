import { useState } from "react";
import Modal from "@mui/material/Modal";
import { deepCopy, isBlank, isNullOrUndefined } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import { useTranslation } from "react-i18next";

export default function PinMarkerModal(props) {
  const { isOpen, handleClose, handleSave, pinMarker } = props;
  const { t } = useTranslation();
  const defaultState = !isNullOrUndefined(pinMarker)
    ? deepCopy(pinMarker)
    : {
        latitude: "",
        longitude: "",
      };
  const [pinMarkerToEdit, setPinMakerToEdit] = useState(defaultState);

  // Enter a Lng/Lat coordinate like:
  // -118.41163, 33.94311
  // -118.41413, 33.94233;
  const labelText = `${t("latitude")}, ${t("longitude")}`;
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal pin-marker-modal">
          <div>
            <div className="modal-header">
              <h2>{t("pin_marker")}</h2>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="field-pairs">
                <div className="field-pair">
                  <label>{labelText}</label>
                  <div className="field-input">
                    <input
                      type="text"
                      placeholder={`${t("latitude")}, ${t("longitude")}`}
                      value={`${
                        !isBlank(pinMarkerToEdit.latitude)
                          ? pinMarkerToEdit.latitude
                          : ""
                      }${
                        !isBlank(pinMarkerToEdit.longitude)
                          ? `, ${pinMarkerToEdit.longitude}`
                          : ""
                      }`}
                      onChange={(e) => {
                        setPinMakerToEdit((prev) => {
                          const valuePair = e.target.value.split(",");
                          const valuesToUpdate = deepCopy({ ...prev });
                          valuesToUpdate.latitude = valuePair[0].trim();
                          valuesToUpdate.longitude =
                            valuePair.length === 2 ? valuePair[1].trim() : null;
                          return valuesToUpdate;
                        });
                      }}
                    />
                    <a
                      href="/"
                      onClick={(e) => {
                        setPinMakerToEdit((prev) => {
                          const valuesToUpdate = deepCopy({ ...prev });
                          valuesToUpdate.latitude = prev.longitude;
                          valuesToUpdate.longitude = prev.latitude;
                          return valuesToUpdate;
                        });
                        e.preventDefault();
                      }}
                    >
                      Switch order
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    handleSave({
                      latitude: parseFloat(pinMarkerToEdit.latitude),
                      longitude: parseFloat(pinMarkerToEdit.longitude),
                    });
                  }}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
