import "./styles.css";

function ToggleSwitch(props) {
  const { isSelected, onChange, isDisabled } = props;
  return (
    <div
      className={`toggle-switch ${isSelected ? " selected" : ""}${
        isDisabled ? " disabled" : ""
      }`}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        onChange(!isSelected);
      }}
    >
      <div>
        <div className="knob"></div>
      </div>
    </div>
  );
}

export default ToggleSwitch;
