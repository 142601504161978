import "./styles.css";
import { isNullOrUndefined } from "../../utils";
import TitleBadge from "../TitleBadge";

function PageToolbar(props) {
  const { title, subheading, actionButtons, badgeValue } = props;

  const buttonCount = !isNullOrUndefined(actionButtons)
    ? actionButtons.length
    : 0;
  const styles = {
    gridTemplateColumns: `repeat(${buttonCount}, auto)`,
  };
  const hasBadge = !isNullOrUndefined(badgeValue);
  return (
    <div className="page-toolbar">
      <div>
        <h2 className={`${hasBadge ? "badged" : ""}`}>
          {title}
          {hasBadge && <TitleBadge value={badgeValue} />}
        </h2>
        {subheading && (
          <span className="page-toolbar-subheading">{subheading}</span>
        )}
      </div>
      <div className="page-toolbar-actions" style={styles}>
        {!isNullOrUndefined(actionButtons) &&
          actionButtons.map((actionButton, idx) => (
            <div key={idx}>{actionButton}</div>
          ))}
      </div>
    </div>
  );
}
export default PageToolbar;
