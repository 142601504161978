import { useTranslation } from "react-i18next";
import { ReactComponent as PencilWhite } from "../../assets/pencil-white.svg";
import { ReactComponent as Allocate } from "../../assets/allocate.svg";
import { DEFAULT_TIMEZONE, EMPTY_TIME } from "../../constants";
import {
  formatTime,
  getRelativeTimeString,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import CriticalMessages from "./CriticalMessages";

function UpcomingTurnaroundAllocations(props) {
  const { t } = useTranslation();
  const { item, configureAction, actionsDisabled, currentUser } = props;
  const { airport } = currentUser;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const mNow = moment().tz(airportTimezone).startOf("minute");
  const mGateIn = !isNullOrUndefined(item.gateInTime)
    ? moment(item.gateInTime).tz(airportTimezone).startOf("minute")
    : null;

  const gateInTime = !isNullOrUndefined(mGateIn)
    ? `${formatTime(
        item.gateInTime,
        airportTimezone,
        true
      )} - ${getRelativeTimeString(mGateIn, mNow)}`
    : EMPTY_TIME;

  return (
    <div className="listing-row">
      <div>{item.displayName}</div>
      <div>
        <div>{item && <CriticalMessages turnaroundAllocation={item} />}</div>
      </div>
      <div>{gateInTime}</div>
      <div>
        <div className="actions">
          <div>
            {!item.isCritical && (
              <button
                disabled={actionsDisabled}
                className="secondary"
                onClick={configureAction}
              >
                <PencilWhite />
                {t("edit")}
              </button>
            )}

            {item.isCritical && (
              <button disabled={actionsDisabled} onClick={configureAction}>
                <Allocate />
                {t("allocate")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpcomingTurnaroundAllocations;
