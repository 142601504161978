import "./styles.css";
import Checkbox from "../Checkbox";
import { ReactComponent as CaretDown } from "../../assets/caret-down.svg";
import { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../utils";

function OptionBox(props) {
  const { name, options, selectedOptions, selectedUserUuid, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      setIsOpen(true);
    }
  }, [selectedUserUuid]);
  return (
    <div className={`option-box${isOpen ? " open" : ""}`}>
      <div
        className="option-box-header"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <div>
          <div>{name}</div>
          <div className="option-box-toggle">
            <CaretDown />
          </div>
        </div>
      </div>
      <div className="option-box-body">
        <div>
          {options &&
            options.map((option) => (
              <div
                className={`option-box-row${
                  selectedOptions?.includes(option.value) ? " selected" : ""
                }`}
                key={option.value}
              >
                <div>
                  <Checkbox
                    value={option.value}
                    isChecked={selectedOptions?.includes(option.value)}
                    changeHandler={() => {
                      onChange(option.value);
                    }}
                  />
                </div>
                <div>
                  <label
                    onClick={() => {
                      onChange(option.value);
                    }}
                  >
                    {option.name}
                  </label>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default OptionBox;
