import "./styles.css";
import TimeInput from "./TimeInput";
import moment from "moment-timezone";

function TimeRangeInput(props) {
  const { onTimeRangeChange, timeRangeValues, isValid, timezone } = props;
  const { startTime, endTime } = timeRangeValues;

  function fitTimerangeValues(startTime, endTime) {
    // When updating the shift hours, make sure we are only setting hours
    // Always start with dates anchored to the start time
    const mStartTime = moment(startTime).tz(timezone).startOf("day");
    const mEndTime = moment(startTime).tz(timezone).startOf("day");

    // Use only the hour/minute values from the timerange
    const mTimerangeStart = moment(startTime).tz(timezone);
    const mTimerangeEnd = moment(endTime).tz(timezone);

    mStartTime
      .set("hour", mTimerangeStart.get("hour"))
      .set("minute", mTimerangeStart.get("minute"));
    mEndTime
      .set("hour", mTimerangeEnd.get("hour"))
      .set("minute", mTimerangeEnd.get("minute"));

    // If the end is before the start, consider this overnight and make the end on the next day
    if (mEndTime.isBefore(mStartTime)) {
      mEndTime.add(1, "day");
    }
    // Finally, set on the crewShift
    return {
      startTime: mStartTime.toDate().toISOString(),
      endTime: mEndTime.toDate().toISOString(),
    };
  }
  return (
    <div className={`time-range-input${!isValid ? " not-valid" : ""}`}>
      <div>
        <TimeInput
          timeValue={startTime}
          onTimeChange={(timeValue) => {
            const timerange = fitTimerangeValues(timeValue, endTime);
            onTimeRangeChange(timerange);
          }}
          timezone={timezone}
        />
        <div>-</div>
        <TimeInput
          timeValue={endTime}
          onTimeChange={(timeValue) => {
            const timerange = fitTimerangeValues(startTime, timeValue);
            onTimeRangeChange(timerange);
          }}
          timezone={timezone}
        />
      </div>
    </div>
  );
}
export default TimeRangeInput;
