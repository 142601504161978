import { useTranslation } from "react-i18next";
import StatusBadge from "../StatusBadge";
import { isEmptyList, isNullOrUndefined } from "../../utils";
import { getCriticalOperationsFromTurnaround } from "../../turnaroundUtils";

function CriticalMessages(props) {
  const { t } = useTranslation();
  const { turnaroundInfo, selectedOperation } = props;

  // If selectedOperation is not specified,
  // then all critical operations on the turnaround are shown
  const hasSelectedOperation = !isNullOrUndefined(selectedOperation);
  const criticalOperations = hasSelectedOperation
    ? [selectedOperation]
    : getCriticalOperationsFromTurnaround(turnaroundInfo);

  const criticalMessages = !isEmptyList(criticalOperations)
    ? criticalOperations.map((turnaroundOperation) =>
        hasSelectedOperation
          ? turnaroundOperation.status.displayName
          : `${turnaroundOperation?.type.name}: ${turnaroundOperation.status.displayName}`
      )
    : [];

  const messageLabel =
    criticalMessages.length === 1
      ? t("ack_confirm_message")
      : t("ack_confirm_plural_message");
  return (
    <div>
      <div className="confirmation-detail">
        <div>{turnaroundInfo.combinedFlightName}</div>
      </div>
      <div>
        <span>{messageLabel}</span>
      </div>
      <div className="confirmation-detail-rows">
        {criticalMessages &&
          criticalMessages.map((item, idx) => (
            <div className="confirmation-detail-row" key={idx}>
              <StatusBadge isCritical={true} message={item} />
            </div>
          ))}
      </div>
    </div>
  );
}
export default CriticalMessages;
