import { useTranslation } from "react-i18next";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { formatTime, isBlank } from "../../utils";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";

function TurnaroundSection(props) {
  const {
    type,
    flightName,
    snappedStand,
    arrivalStand,
    departureStand,
    gateIn,
    gateOut,
    landing,
    takeoff,
    origin,
    destination,
    timezone,
  } = props;
  const isInboundFlight = type === "inboundFlight";

  const { t } = useTranslation();

  const displayTime = isInboundFlight ? landing : takeoff;
  const displayGateTime = isInboundFlight ? gateIn : gateOut;

  let standDisplay = EMPTY_STAND;
  let standDisplayLabel = isInboundFlight
    ? t("assigned_arr_stand_abbr")
    : t("assigned_dep_stand_abbr");
  if (isInboundFlight) {
    const hasSnappedStand = !isBlank(snappedStand?.name);
    if (hasSnappedStand) {
      standDisplay = snappedStand.name;
      standDisplayLabel = t("current_stand");
    } else {
      standDisplay = !isBlank(arrivalStand?.name)
        ? arrivalStand?.name
        : EMPTY_STAND;
    }
  } else {
    standDisplay = !isBlank(departureStand?.name)
      ? departureStand?.name
      : EMPTY_STAND;
  }

  return (
    <div className="turnaround-section">
      <div className="icon">
        {type === "inboundFlight" && <Arrival />}
        {type === "outboundFlight" && <Departure />}
      </div>
      <div className="info-box">
        <span>{`${
          isInboundFlight ? t("arriving_non_cap") : t("departing_non_cap")
        }`}</span>
        <h3>{flightName}</h3>
      </div>
      <div className="info-box">
        <div>
          <span>{standDisplayLabel}</span>
        </div>
        <h3>{`${standDisplay}`}</h3>
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("coming_from_non_cap") : t("heading_to_non_cap")
        }`}</span>

        {isInboundFlight && (
          <h3 title={origin}>{`${!isBlank(origin) ? origin : "-"}`}</h3>
        )}
        {!isInboundFlight && (
          <h3 title={destination}>
            {`${!isBlank(destination) ? destination : "-"}`}
          </h3>
        )}
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("gate_in_non_cap") : t("gate_out_non_cap")
        }`}</span>
        <h3>
          {`${
            !isBlank(displayGateTime)
              ? formatTime(displayGateTime, timezone, true)
              : EMPTY_TIME
          }`}
        </h3>
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("landing_non_cap") : t("takeoff_non_cap")
        }`}</span>
        <h3>{`${
          !isBlank(displayTime) ? formatTime(displayTime, timezone, true) : "-"
        }`}</h3>
      </div>
    </div>
  );
}

export default TurnaroundSection;
