import { getApiByResourceName } from "./api";

export async function getTasksOverview(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "tasks-overview",
    "TasksOverview",
    "get"
  );
}
