import {
  getListByField,
  getMapFromList,
  isEmptyList,
  isNullOrUndefined,
} from "./utils";
import i18next from "./i18n";

// Returns an object with isValid and validationMessage
export function getProfileValidation(turnaround) {
  if (isNullOrUndefined(turnaround)) return null;
  const requirements = getEnabledTurnaroundProfileRequirements(turnaround);
  if (isNullOrUndefined(requirements)) return null;
  let missingAssignments = 0;
  let validationMessage = null;
  for (let i = 0; i < requirements.length; i++) {
    const requirement = requirements[i];
    let crewAssignmentCount = 0;
    if (!isNullOrUndefined(requirement.crewAssignments)) {
      for (let j = 0; j < requirement.crewAssignments.length; j++) {
        if (!isNullOrUndefined(requirement.crewAssignments[j].user)) {
          crewAssignmentCount++;
        }
      }
    }
    const quantity = requirement.quantity;
    if (crewAssignmentCount < quantity) {
      missingAssignments =
        missingAssignments + (quantity - crewAssignmentCount);
    }
  }
  if (missingAssignments > 0) {
    validationMessage =
      missingAssignments === 1
        ? i18next.t("crew_member_required_web", { value: "1" })
        : i18next.t("crew_members_required_web", { value: missingAssignments });
  }

  return {
    isValid: missingAssignments === 0,
    validationMessage: validationMessage,
  };
}

// Returns an object with crewAssignedCount, crewAcceptedCount, and dispatchAcceptanceMessage
export function getDispatchAcceptance(turnMonitor) {
  if (isNullOrUndefined(turnMonitor)) return null;
  let crewAssigned = 0;
  let crewAccepted = 0;
  const requirements = getEnabledTurnaroundProfileRequirements(turnMonitor);
  for (let i = 0; i < requirements.length; i++) {
    const requirement = requirements[i];
    if (!isNullOrUndefined(requirement.crewAssignments)) {
      for (let j = 0; j < requirement.crewAssignments.length; j++) {
        const crewAssignment = requirement.crewAssignments[j];
        crewAssigned++;
        if (!isNullOrUndefined(crewAssignment.acceptedAt)) {
          crewAccepted++;
        }
      }
    }
  }
  let dispatchAcceptanceMessage = null;
  const missingCrewCount = crewAssigned - crewAccepted;
  if (missingCrewCount > 0) {
    dispatchAcceptanceMessage =
      missingCrewCount === 1
        ? i18next.t("crew_member_not_accepted_web", { value: "1" })
        : i18next.t("crew_members_not_accepted_web", {
            value: missingCrewCount,
          });
  }
  return {
    dispatchAcceptanceMessage: dispatchAcceptanceMessage,
    missingCrewCount: missingCrewCount,
  };
}

export function getEnabledTurnaroundProfileRequirements(turnaroundProfile) {
  return getTurnaroundProfileRequirements(turnaroundProfile, true);
}
export function getTurnaroundProfileRequirements(
  turnaroundProfile,
  enabledOnly
) {
  if (isNullOrUndefined(turnaroundProfile)) return null;
  const operations = turnaroundProfile.turnaroundOperations;
  if (isNullOrUndefined(operations)) return null;
  const requirements = [];
  for (let i = 0; i < operations.length; i++) {
    const operation = operations[i];
    const turnaroundRequirements = operation.turnaroundRequirements;
    if (
      (!enabledOnly || operation.enabled) &&
      !isNullOrUndefined(turnaroundRequirements)
    ) {
      for (let j = 0; j < turnaroundRequirements.length; j++) {
        requirements.push({
          ...turnaroundRequirements[j],
          parentOperation: operation,
        });
      }
    }
  }
  return requirements;
}

export function getTurnaroundProfileRequirementsMap(
  turnaroundProfile,
  enabledOnly
) {
  const requirementsList = getTurnaroundProfileRequirements(
    turnaroundProfile,
    enabledOnly
  );
  return getMapFromList(requirementsList, "uuid");
}

export function getProfilePatchRequest(
  originalProfile,
  profileToUpdate,
  isTemplate
) {
  const profilePatchRequest = {
    uuid: profileToUpdate.uuid,
    parentLabelUuidsToApply: [],
    labelUuidsToRemove: [],
  };

  const origOperations = originalProfile.turnaroundOperations;
  const origRequirements = getTurnaroundProfileRequirements(
    originalProfile,
    false
  );

  const operationsToUpdate = getMapFromList(
    profileToUpdate.turnaroundOperations,
    "uuid"
  );
  const requirementsToUpdate = getTurnaroundProfileRequirementsMap(
    profileToUpdate,
    false
  );

  const enabledTurnaroundRequirements = [];
  if (!isNullOrUndefined(origOperations)) {
    profilePatchRequest.operations = [];
    for (let i = 0; i < origOperations.length; i++) {
      const operation = origOperations[i];
      const operationToUpdate = operationsToUpdate[operation.uuid];
      if (isTemplate) {
        if (
          operation.enabled !== operationToUpdate.enabled ||
          operation.startOffsetMinutes !==
            operationToUpdate.startOffsetMinutes ||
          operation.duration !== operationToUpdate.duration ||
          operation.offsetTypeId !== operationToUpdate.offsetTypeId
        ) {
          profilePatchRequest.operations.push({
            duration: operationToUpdate.duration,
            enabled: operationToUpdate.enabled,
            startOffsetMinutes: operationToUpdate.startOffsetMinutes,
            uuid: operationToUpdate.uuid,
            offsetTypeId: operationToUpdate.offsetTypeId,
          });
        }
      } else {
        if (
          operation.enabled !== operationToUpdate.enabled ||
          operation.startOffsetMinutes !== operationToUpdate.startOffsetMinutes
        ) {
          profilePatchRequest.operations.push({
            // duration
            enabled: operationToUpdate.enabled,
            startOffsetMinutes: operationToUpdate.startOffsetMinutes,
            uuid: operationToUpdate.uuid,
            // offsetTypeId,
          });
        }
      }

      const isOperationEnabled = !isNullOrUndefined(operationToUpdate)
        ? operationToUpdate.enabled
        : operation.enabled;
      if (isOperationEnabled) {
        const requirementsForOperation = operation.turnaroundRequirements;
        for (let i = 0; i < requirementsForOperation.length; i++) {
          const requirementForOperation = requirementsForOperation[i];
          enabledTurnaroundRequirements.push(requirementForOperation.uuid);
        }
      }
    }
  }
  if (!isNullOrUndefined(origRequirements)) {
    profilePatchRequest.requirements = [];
    for (let i = 0; i < origRequirements.length; i++) {
      const origRequirement = origRequirements[i];
      const requirementToUpdate = requirementsToUpdate[origRequirement.uuid];
      const origRequirementUserUuids = getListByField(
        origRequirement?.crewAssignments,
        "userUuid"
      );
      const updatedRequirementUserUuids = getListByField(
        requirementToUpdate?.crewAssignments,
        "userUuid"
      );

      // Only include the patch for requirements if:
      // 1) the associated operation is enabled
      // 2) the quantity has changed
      // 3) the assigned users have been added/removed
      const userUuidsToAdd = getCrewAssignmentsToAdd(
        origRequirementUserUuids,
        updatedRequirementUserUuids
      );
      const hasUsersToAdd = !isEmptyList(userUuidsToAdd);

      const userUuidsToRemove = getCrewAssignmentsToRemove(
        origRequirementUserUuids,
        updatedRequirementUserUuids
      );
      const hasUsersToRemove = !isEmptyList(userUuidsToRemove);
      const isEnabled = enabledTurnaroundRequirements.includes(
        requirementToUpdate.uuid
      );
      if (
        isEnabled &&
        (origRequirement.quantity !== requirementToUpdate.quantity ||
          hasUsersToAdd ||
          hasUsersToRemove)
      ) {
        // NOTE: Include userUuidsToAdd and userUuidsToRemove if needed
        const requirementPatch = {
          quantity: requirementToUpdate.quantity,
          uuid: requirementToUpdate.uuid,
        };
        if (hasUsersToAdd) {
          requirementPatch.userUuidsToAdd = userUuidsToAdd;
        }
        if (hasUsersToRemove) {
          requirementPatch.userUuidsToRemove = userUuidsToRemove;
        }
        profilePatchRequest.requirements.push(requirementPatch);
      }
    }
  }
  return profilePatchRequest;
}

export function getCrewAssignmentsToAdd(
  origCrewAssignments,
  updatedCrewAssignments
) {
  if (!isEmptyList(updatedCrewAssignments)) {
    const crewAssignmentsToAdd = [];
    for (let i = 0; i < updatedCrewAssignments.length; i++) {
      const updatedCrewAssignment = updatedCrewAssignments[i];
      if (!origCrewAssignments.includes(updatedCrewAssignment)) {
        crewAssignmentsToAdd.push(updatedCrewAssignment);
      }
    }
    if (!isEmptyList(crewAssignmentsToAdd)) {
      return crewAssignmentsToAdd;
    }
  }
  return null;
}
export function getCrewAssignmentsToRemove(
  origCrewAssignments,
  updatedCrewAssignments
) {
  if (!isEmptyList(origCrewAssignments)) {
    const crewAssignmentsToRemove = [];
    for (let i = 0; i < origCrewAssignments.length; i++) {
      const origCrewAssignment = origCrewAssignments[i];
      if (!updatedCrewAssignments.includes(origCrewAssignment)) {
        crewAssignmentsToRemove.push(origCrewAssignment);
      }
    }
    if (!isEmptyList(crewAssignmentsToRemove)) {
      return crewAssignmentsToRemove;
    }
  }
  return null;
}
