import "./styles.css";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import TitleBadge from "../TitleBadge";
import {
  formatLongDate,
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import ButtonDatePicker from "../ButtonDatePicker";
import { MIN_DATE, SEARCH_QUERY_PLACEHOLDERS } from "../../constants";
import DataTable from "../DataTable";
import {
  getTaskInfoForTurnaround,
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
} from "../../turnaroundUtils";
import {
  getTurnaroundDetails,
  getTurnaroundsSummarySearch,
  getVehicles,
} from "../../api";
import { TurnaroundsModuleColumns } from "../DataTable/TurnaroundsModuleColumns";
import { useNavigate, useParams } from "react-router-dom";
import TurnaroundsModuleTasks from "./TurnaroundsModuleTasks";

function TurnaroundsModule() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { turnaroundUuid } = useParams();

  const {
    currentUser,
    turnaroundsSummarySearch,
    turnaroundDetails,
    searchQuery,
    vehicles,
  } = mainContext;
  const turnaroundsCount = turnaroundsSummarySearch?.length;
  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");

  const [selectedDate, setSelectedDate] = useState(mToday);

  useEffect(() => {
    // Load supporting data
    getVehicles(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load required data
    const start = moment(selectedDate).toDate().toISOString();
    const end = moment(selectedDate).endOf("day").toDate().toISOString();
    getTurnaroundsSummarySearch(dispatch, start, end);
    getVehicles(dispatch);
  }, [dispatch, selectedDate]);

  useEffect(() => {
    // Load turnaround details
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, { uuid: turnaroundUuid });
    } else {
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [dispatch, turnaroundUuid]);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
      },
    });
  }, [dispatch]);

  const dataList = useMemo(() => {
    const resultsToList = [];
    if (!isEmptyList(turnaroundsSummarySearch)) {
      for (let i = 0; i < turnaroundsSummarySearch.length; i++) {
        const turnaround = turnaroundsSummarySearch[i];
        const turnaroundInfo =
          getTurnaroundInfoForTurnaroundSummary(turnaround);
        if (turnaroundInfo.isCompleted) {
          resultsToList.push({
            ...turnaroundInfo,
            timezone: airportTimezone,
          });
        }
      }
    }
    return resultsToList;
  }, [turnaroundsSummarySearch, airportTimezone]);

  const [selectedTurnaroundInfo, taskInfoList] = useMemo(() => {
    const turnaroundInfoToRender = !isNullOrUndefined(turnaroundDetails)
      ? getTurnaroundInfo(turnaroundDetails)
      : null;
    const taskInfoForTurnaround = !isNullOrUndefined(turnaroundInfoToRender)
      ? getTaskInfoForTurnaround(turnaroundInfoToRender, vehicles)
      : null;

    return [turnaroundInfoToRender, taskInfoForTurnaround];
  }, [turnaroundDetails, vehicles]);

  return (
    <div className="turnarounds-module">
      <div className="turnarounds-module-header">
        <div>
          <div>
            {isNullOrUndefined(selectedTurnaroundInfo) && (
              <>
                <h3 className="badged">
                  {t("turnarounds")} <TitleBadge value={turnaroundsCount} />
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(selectedDate)}
                    {selectedDate.isSame(mToday) && (
                      <>&nbsp;&middot;&nbsp;{t("today")}</>
                    )}
                  </span>
                </div>
              </>
            )}
            {!isNullOrUndefined(selectedTurnaroundInfo) && (
              <>
                <h3>
                  {t("turnarounds")}:{" "}
                  {selectedTurnaroundInfo.combinedFlightName}
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(selectedTurnaroundInfo.landing)}
                    {selectedDate.isSame(mToday) && (
                      <>&nbsp;&middot;&nbsp;{t("today")}</>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="actions">
            <div>
              {isNullOrUndefined(selectedTurnaroundInfo) && (
                <ButtonDatePicker
                  label={
                    selectedDate == null
                      ? null
                      : selectedDate.format("MM/DD/YYYY")
                  }
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  minDate={moment(MIN_DATE)}
                  maxDate={moment(mToday).add(2, "month")}
                />
              )}
              {!isNullOrUndefined(selectedTurnaroundInfo) && (
                <button
                  onClick={() => {
                    navigate("/turnarounds");
                  }}
                >
                  {t("back")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isNullOrUndefined(selectedTurnaroundInfo) && (
        <div className="turnarounds-module-container tasks">
          <TurnaroundsModuleTasks
            taskInfoList={taskInfoList}
            turnaroundInfo={selectedTurnaroundInfo}
            timezone={airportTimezone}
          />
        </div>
      )}
      {isNullOrUndefined(selectedTurnaroundInfo) && (
        <div className="turnarounds-module-container">
          <DataTable
            initialSort={TurnaroundsModuleColumns.Landing.id}
            dataList={dataList}
            columnNames={[
              TurnaroundsModuleColumns.TurnaroundName,
              TurnaroundsModuleColumns.Registration,
              TurnaroundsModuleColumns.Landing,
              TurnaroundsModuleColumns.GateIn,
              TurnaroundsModuleColumns.GateOut,
              TurnaroundsModuleColumns.Takeoff,
              TurnaroundsModuleColumns.TotalGate,
              TurnaroundsModuleColumns.TotalGround,
            ]}
            rowActions={{
              viewAction: (turnaroundInfo) => {
                navigate(`/turnarounds/${turnaroundInfo.uuid}`);
              },
            }}
            gridColumns={
              "repeat(2, minmax(0, 1fr)) repeat(4, minmax(0,120px)) repeat(2, minmax(0,140px))  140px"
            }
            searchQuery={searchQuery}
          />
        </div>
      )}
    </div>
  );
}

export default TurnaroundsModule;
