import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { useEffect, useState } from "react";
import {
  deepCopy,
  isEmptyList,
  isNullOrUndefined,
  makeKeyFriendlyString,
} from "../../utils";
import ToggleSwitch from "../ToggleSwitch";
import DispatchOverrideDetails from "./DispatchOverrideDetails";
import {
  getTurnaroundDetails,
  getTurnaroundLabelsPreview,
  patchTurnaroundProfile,
} from "../../api";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";

function DispatchOverridesEditor(props) {
  const { t } = useTranslation();
  const { onCancel, onApply, turnaroundInfo, parentTemplate } = props;
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundLabelsPreview } = mainContext;

  const availableLabels = parentTemplate?.turnaroundLabels;
  const appliedLabels = turnaroundInfo?.originalProfile?.activeLabels;
  const appliedLabelsByParentUuid = {};
  if (!isEmptyList(appliedLabels)) {
    for (let i = 0; i < appliedLabels.length; i++) {
      const appliedLabel = appliedLabels[i];
      appliedLabelsByParentUuid[appliedLabel.parentUuid] = appliedLabel;
    }
  }
  const [enabledLabels, setEnabledLabels] = useState(appliedLabelsByParentUuid);
  const [hasChanges, setHasChanges] = useState(false);
  const [applyLabelsRequest, setApplyLabelsRequest] = useState(null);
  const [openedLabels, setOpenLabels] = useState([]);

  useEffect(() => {
    dispatch({
      type: "setTurnaroundLabelsPreview",
      value: null,
    });
  }, [dispatch]);

  async function handleUpdateLabels(label) {
    // add or remove the label
    const updatedEnabledLabels = deepCopy(enabledLabels);
    if (label.uuid in updatedEnabledLabels) {
      delete updatedEnabledLabels[label.uuid];
    } else {
      updatedEnabledLabels[label.uuid] = label;
    }

    const previewRequest = {
      uuid: turnaroundInfo?.originalProfile.uuid,
      labelUuidsToRemove: [],
      parentLabelUuidsToApply: [],
      operations: [],
      requirements: [],
    };
    // When adding label, provide the parent label's uuid
    const enabledLabelUuids = Object.keys(updatedEnabledLabels);
    for (let i = 0; i < enabledLabelUuids.length; i++) {
      const enabledLabelUuid = enabledLabelUuids[i];
      if (!(enabledLabelUuid in appliedLabelsByParentUuid)) {
        // new label was added
        previewRequest.parentLabelUuidsToApply.push(enabledLabelUuid);
      }
    }

    for (let i = 0; i < appliedLabels?.length; i++) {
      const appliedLabel = appliedLabels[i];
      if (!(appliedLabel.parentUuid in updatedEnabledLabels)) {
        // label was removed
        previewRequest.labelUuidsToRemove.push(appliedLabel.uuid);
      }
    }
    // Save the request to use for Apply later
    setApplyLabelsRequest(previewRequest);

    // Cleanup previous conflicts
    dispatch({
      type: "setTurnaroundLabelsPreview",
      value: null,
    });

    // Check for conflicts
    const result = await getTurnaroundLabelsPreview(dispatch, previewRequest);
    if (result) {
      setHasChanges(
        !isEmptyList(previewRequest.labelUuidsToRemove) ||
          !isEmptyList(previewRequest.parentLabelUuidsToApply)
      );
      setEnabledLabels(updatedEnabledLabels);
    }
  }

  async function handleApplyLabels() {
    if (!isNullOrUndefined(applyLabelsRequest)) {
      const result = await patchTurnaroundProfile(dispatch, applyLabelsRequest);
      if (result) {
        await getTurnaroundDetails(dispatch, { uuid: turnaroundInfo.uuid });
        dispatch({
          type: "setAlertMessage",
          alertMessage: t("special_cases_applied"),
        });
        onApply();
      }
    }
  }

  function handleToggleLabel(labelUuid) {
    setOpenLabels((prev) => {
      const updatedValues = deepCopy(prev);
      const idx = updatedValues.indexOf(labelUuid);
      if (idx > -1) {
        updatedValues.splice(idx, 1);
      } else {
        updatedValues.push(labelUuid);
      }
      return updatedValues;
    });
  }
  const hasSummaryChanges = !isEmptyList(turnaroundLabelsPreview?.changes);
  const hasSummaryConflicts = !isEmptyList(turnaroundLabelsPreview?.conflicts);
  const hasSummaryErrors = !isEmptyList(turnaroundLabelsPreview?.errors);
  const showSummary = hasSummaryChanges || hasSummaryConflicts;
  const applyDisabled = hasSummaryConflicts || !hasChanges || hasSummaryErrors;

  return (
    <div className="dispatch-editor-module dispatch-overrides-editor">
      <div className="dispatch-editor-module-header">
        <div className="title">
          <h4>{t("special_cases")}</h4>
        </div>
        <div></div>
      </div>
      <div className="dispatch-editor-module-body">
        <div className="dispatch-editor-module-main">
          <div className="dispatch-editor-module-inset">
            {!isEmptyList(availableLabels) && (
              <div className="turnaround-labels">
                {availableLabels &&
                  availableLabels.map((label) => (
                    <div
                      key={label.uuid}
                      data-label-uuid={label.uuid}
                      className={`turnaround-label-module${
                        openedLabels.includes(label.uuid) ? " opened" : ""
                      }${
                        !isNullOrUndefined(enabledLabels[label.uuid])
                          ? " selected"
                          : ""
                      }`}
                    >
                      <div className="turnaround-label-info">
                        <div>
                          <div>
                            <div
                              className="toggle"
                              onClick={() => {
                                handleToggleLabel(label.uuid);
                              }}
                            >
                              <Caret />
                            </div>
                          </div>
                          <div
                            className="toggle-label"
                            onClick={() => {
                              handleToggleLabel(label.uuid);
                            }}
                          >
                            {label.name}
                          </div>
                          <div>
                            <ToggleSwitch
                              isSelected={
                                !isNullOrUndefined(enabledLabels[label.uuid])
                              }
                              onChange={() => {
                                handleUpdateLabels(label);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="turnaround-label-overrides">
                        {!isEmptyList(label?.operationOverrides) &&
                          label?.operationOverrides.map(
                            (operationOverride, idx) => (
                              <DispatchOverrideDetails
                                key={`${makeKeyFriendlyString(
                                  operationOverride.operationName
                                )}_${idx}`}
                                operationOverride={operationOverride}
                              />
                            )
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="dispatch-editor-module-side">
          {showSummary && (
            <div className="fadeIn">
              <div>
                <h4>{t("summary")}</h4>
              </div>
              {hasSummaryConflicts && (
                <div className="turnaround-label-summary conflicts">
                  {turnaroundLabelsPreview.conflicts &&
                    turnaroundLabelsPreview.conflicts.map((item, idx) => (
                      <div key={`preview_conflicts_${idx}`}>{item}</div>
                    ))}
                </div>
              )}
              {hasSummaryChanges && (
                <div className="turnaround-label-summary">
                  {turnaroundLabelsPreview.changes &&
                    turnaroundLabelsPreview.changes.map((item, idx) => (
                      <div key={`preview_changes_${idx}`}>{item}</div>
                    ))}
                </div>
              )}
            </div>
          )}
          <div>
            <button
              className="green"
              onClick={handleApplyLabels}
              disabled={applyDisabled}
            >
              {t("apply_special_cases")}
            </button>
            <button className="outlined" onClick={onCancel}>
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DispatchOverridesEditor;
