import moment from "moment-timezone";
import {
  deepCopy,
  formatHoursMins,
  formatTime,
  getNearestTimeInterval,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { TaskInfoType } from "../../turnaroundUtils";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";

function TurnaroundsModuleTasks(props) {
  const { taskInfoList, turnaroundInfo, timezone } = props;
  const { landing, takeoff, gateIn, gateOut } = turnaroundInfo;
  const [expandedTasks, setExpandedTasks] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [pointMarkerTime, setPointMarkerTime] = useState(false);
  const [pointMarkerLeftOffset, setPointMarkerLeftOffset] = useState(0);

  const mLanding = moment(landing).tz(timezone);
  const mTakeoff = moment(takeoff).tz(timezone);
  const mUpperLabel = getNearestTimeInterval(
    moment(gateOut).tz(timezone),
    -15 / 2
  );
  const mLowerLabel = getNearestTimeInterval(
    moment(gateIn).tz(timezone),
    15 / 2
  );

  const rangeStart = getNearestTimeInterval(mLanding, -15);
  const rangeEnd = getNearestTimeInterval(mTakeoff, 15);
  const rangeStartAdj = moment(rangeStart).add(0, "minute");

  // 15 min increments
  const timeBuckets = [];
  const timeInterval = moment(rangeStart);
  while (timeInterval.isBefore(rangeEnd)) {
    if (timeInterval.isAfter(rangeStart)) {
      timeBuckets.push(moment(timeInterval));
    }
    timeInterval.add(15, "minute");
  }
  const taskInfoListToRender = useMemo(() => {
    const taskList = [];
    if (!isEmptyList(taskInfoList)) {
      for (let i = 0; i < taskInfoList.length; i++) {
        const taskInfo = taskInfoList[i];
        if (taskInfo.type === TaskInfoType.OPERATION) {
          taskList.push(taskInfo);
          if (
            !isEmptyList(taskInfo.tasks) &&
            expandedTasks.includes(taskInfo.uuid)
          ) {
            for (let j = 0; j < taskInfo.tasks.length; j++) {
              const childTask = taskInfo.tasks[j];
              taskList.push(childTask);
            }
          }
        }
      }
    }
    return taskList;
  }, [taskInfoList, expandedTasks]);

  const containerRef = useRef(null);
  useEffect(() => {
    if (!isNullOrUndefined(containerRef.current)) {
      setIsInitialized(true);
    }
  }, []);

  const gridColumns = {
    gridTemplateColumns: `repeat(${timeBuckets.length + 1}, minmax(0,1fr))`,
  };
  const gridRows = {
    gridTemplateRows: `repeat(${taskInfoListToRender.length}, minmax(0,1fr))`,
  };

  function handleToggleOperation(operationUuid) {
    setExpandedTasks((prev) => {
      const updatedVal = deepCopy(prev);
      if (updatedVal.indexOf(operationUuid) > -1) {
        updatedVal.splice(updatedVal.indexOf(operationUuid), 1);
      } else {
        updatedVal.push(operationUuid);
      }

      return updatedVal;
    });
  }

  function getTimelineBarStyle(taskInfo) {
    const mTaskStart = moment(taskInfo.start).tz(timezone);
    const durationFromRangeStart = mTaskStart.diff(rangeStartAdj, "minutes");
    const timelineWidth = getTimeAsPixels(taskInfo.duration);
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    return {
      width: `${timelineWidth}px`,
      left: `${timelineLeft}px`,
    };
  }

  function getTimelineMarkerStyle(markerTime) {
    if (isNullOrUndefined(markerTime)) return {};
    const mMakerTime = moment(markerTime).tz(timezone);
    const durationFromRangeStart = mMakerTime.diff(rangeStartAdj, "minutes");
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    return {
      left: `${timelineLeft}px`,
    };
  }

  // Styles to position the markers
  const landingMarkerStyle = getTimelineMarkerStyle(landing);
  const takeoffMarkerStyle = getTimelineMarkerStyle(takeoff);
  const gateInMarkerStyle = getTimelineMarkerStyle(gateIn);
  const gateOutMarkerStyle = getTimelineMarkerStyle(gateOut);

  function getTimeAsPixels(minutes) {
    const containerEl = containerRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? containerEl.getBoundingClientRect().width - 2
      : 0;
    const totalWidth = containerWidth;
    const totalDuration = rangeEnd.diff(rangeStartAdj, "minutes");
    return minutes * (totalWidth / totalDuration);
  }

  function getPixelsAsTime(pixels) {
    const containerEl = containerRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? containerEl.getBoundingClientRect().width - 2
      : 0;
    const totalWidth = containerWidth;
    const totalDuration = rangeEnd.diff(rangeStartAdj, "minutes");
    const minutes = pixels * (totalDuration / totalWidth);
    return moment(rangeStartAdj).add(minutes, "minute");
  }

  function handleHoverOver(uuid, parentUuid) {
    const relatedEls = document.querySelectorAll(`[data-task-uuid="${uuid}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.add("hovered");
      }
    }
  }
  function handleHoverOut(uuid, parentUuid) {
    const relatedEls = document.querySelectorAll(`[data-task-uuid="${uuid}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.remove("hovered");
      }
    }
  }
  const contentRef = useRef(null);
  const pointMarkerRef = useRef(null);
  const gridlinesRef = useRef(null);
  const markersRef = useRef(null);
  function handleShowPointMarker(e) {
    if (
      !isNullOrUndefined(gridlinesRef.current) &&
      !isNullOrUndefined(e?.pageX) &&
      !isNullOrUndefined(pointMarkerRef.current)
    ) {
      const distanceInPixels =
        e.pageX - gridlinesRef.current.getBoundingClientRect().left;
      const pointerTime = getPixelsAsTime(distanceInPixels);
      setPointMarkerTime(formatTime(pointerTime, timezone));
      setPointMarkerLeftOffset(distanceInPixels);
      pointMarkerRef.current.style.display = "grid";
    }
  }
  function handleHidePointMarker() {
    pointMarkerRef.current.style.display = "none";
  }

  // Need to correct the vertical position of the label so that it doesn't get covered by header
  function handleContentScroll() {
    const scrollEl = contentRef.current;
    const markersEl = markersRef.current;
    if (isNullOrUndefined(scrollEl) || isNullOrUndefined(markersEl)) return;

    const scrollTop = scrollEl.scrollTop;
    markersEl.style.top = `${scrollTop}px`;
  }
  return (
    <div
      className="turnarounds-module-content"
      onScroll={handleContentScroll}
      ref={contentRef}
    >
      <div className="turnarounds-module-tasks-header">
        <div>
          <div></div>
          <div className="value-cell value-label">Time</div>
        </div>
        <div style={gridColumns}>
          {timeBuckets &&
            timeBuckets.map((timeBucket, idx) => (
              <div key={idx}>
                <div className="value-cell value-label">
                  {timeBucket.isAfter(mLowerLabel) &&
                    timeBucket.isBefore(mUpperLabel) &&
                    formatTime(timeBucket, timezone)}
                </div>
              </div>
            ))}
          <div></div>
        </div>
      </div>
      <div className="turnarounds-module-tasks">
        <div className="turnarounds-module-tasks-sidepanel" style={gridRows}>
          {taskInfoListToRender &&
            taskInfoListToRender.map((taskInfo) => {
              const isExpanded = expandedTasks.includes(taskInfo.uuid);
              const hasChildTasks = !isEmptyList(taskInfo.tasks);
              const timeDisplay =
                !isNullOrUndefined(taskInfo.start) &&
                !isNullOrUndefined(taskInfo.end)
                  ? `${formatTime(taskInfo.start, timezone)} - ${formatTime(
                      taskInfo.end,
                      timezone
                    )}`
                  : "";
              return (
                <div
                  className="turnarounds-module-row"
                  key={taskInfo.keyVal}
                  data-task-uuid={taskInfo.uuid}
                  data-task-parent-uuid={taskInfo.parentUuid}
                  onMouseOver={() => {
                    handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  onMouseOut={() => {
                    handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                >
                  {isBlank(taskInfo.parentUuid) && (
                    <>
                      <div
                        className={`task-label parent-task${
                          isExpanded && hasChildTasks ? " expanded" : ""
                        }${hasChildTasks ? " expandable" : ""}`}
                        onClick={() => {
                          handleToggleOperation(taskInfo.uuid);
                        }}
                      >
                        <div>{hasChildTasks && <Caret />}</div>
                        <div>{taskInfo.name}</div>
                      </div>
                      <div>{timeDisplay}</div>
                    </>
                  )}
                  {!isBlank(taskInfo.parentUuid) && (
                    <>
                      <div className="task-label child-task">
                        <div></div>
                        <div>
                          <div className="task-details">
                            <div>{taskInfo.name}</div>
                            <div>{taskInfo.detail}</div>
                          </div>
                        </div>
                      </div>
                      <div>{timeDisplay}</div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
        <div className="turnarounds-module-tasks-container" ref={containerRef}>
          <div
            className="gridlines"
            style={gridRows}
            onMouseMove={handleShowPointMarker}
            onMouseOut={handleHidePointMarker}
            ref={gridlinesRef}
          >
            {taskInfoListToRender &&
              taskInfoListToRender.map((taskInfo) => (
                <div
                  className="turnarounds-module-task"
                  data-task-uuid={taskInfo.uuid}
                  data-task-parent-uuid={taskInfo.parentUuid}
                  onMouseOver={() => {
                    handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  onMouseOut={() => {
                    handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                  }}
                  key={taskInfo.keyVal}
                  style={gridColumns}
                >
                  {timeBuckets &&
                    timeBuckets.map((timeBucket, idx) => (
                      <div
                        data-bucket={formatTime(timeBucket, timezone)}
                        key={idx}
                      ></div>
                    ))}
                  <div></div>
                </div>
              ))}
          </div>
          {isInitialized && (
            <div
              className="timelinebars"
              style={gridRows}
              onMouseMove={handleShowPointMarker}
              onMouseOut={handleHidePointMarker}
            >
              {taskInfoListToRender &&
                taskInfoListToRender.map((taskInfo) => {
                  const hasDuration = taskInfo.duration > 0;
                  const timelineBarStyle = getTimelineBarStyle(taskInfo);
                  const isChildTask = !isNullOrUndefined(taskInfo.parentUuid);
                  const hasChildTasks = !isEmptyList(taskInfo.tasks);
                  return (
                    <div key={taskInfo.keyVal}>
                      {hasDuration && (
                        <div
                          className={`turnarounds-module-timelinebar${
                            isChildTask ? " child-task" : ""
                          }${hasChildTasks ? " expandable" : ""}`}
                          style={timelineBarStyle}
                          data-task-uuid={taskInfo.uuid}
                          data-task-parent-uuid={taskInfo.parentUuid}
                          onMouseOver={() => {
                            handleHoverOver(taskInfo.uuid, taskInfo.parentUuid);
                          }}
                          onMouseOut={() => {
                            handleHoverOut(taskInfo.uuid, taskInfo.parentUuid);
                          }}
                          onClick={() => {
                            if (hasChildTasks) {
                              handleToggleOperation(taskInfo.uuid);
                            }
                          }}
                        >
                          <div>
                            <div className="turnarounds-module-timelinebar-label">
                              {formatHoursMins(taskInfo.duration)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <div className="turnarounds-module-markers" ref={markersRef}>
        <div className="timeline-marker" style={landingMarkerStyle}>
          <div>
            <div className="timeline-marker-label-anchor">
              <div className="timeline-marker-label">
                <div>
                  <div className="timeline-marker-label-title">Landing</div>
                  <div className="timeline-marker-label-value">
                    {formatTime(landing, timezone)}
                  </div>
                </div>
              </div>
            </div>
            <div className="timeline-marker-line"></div>
          </div>
        </div>
        <div className="timeline-marker" style={gateInMarkerStyle}>
          <div>
            <div className="timeline-marker-label-anchor">
              <div className="timeline-marker-label">
                <div>
                  <div className="timeline-marker-label-title">Gate-in</div>
                  <div className="timeline-marker-label-value">
                    {formatTime(gateIn, timezone)}
                  </div>
                </div>
              </div>
            </div>
            <div className="timeline-marker-line"></div>
          </div>
        </div>
        <div className="timeline-marker" style={gateOutMarkerStyle}>
          <div>
            <div className="timeline-marker-label-anchor">
              <div className="timeline-marker-label">
                <div>
                  <div className="timeline-marker-label-title">Gate-out</div>
                  <div className="timeline-marker-label-value">
                    {formatTime(gateOut, timezone)}
                  </div>
                </div>
              </div>
            </div>
            <div className="timeline-marker-line"></div>
          </div>
        </div>
        <div className="timeline-marker" style={takeoffMarkerStyle}>
          <div>
            <div className="timeline-marker-label-anchor">
              <div className="timeline-marker-label">
                <div>
                  <div className="timeline-marker-label-title">Takeoff</div>
                  <div className="timeline-marker-label-value">
                    {formatTime(takeoff, timezone)}
                  </div>
                </div>
              </div>
            </div>
            <div className="timeline-marker-line"></div>
          </div>
        </div>

        <div
          className="timeline-marker point-marker"
          ref={pointMarkerRef}
          style={{
            left: `${pointMarkerLeftOffset}px`,
            display: "none",
          }}
        >
          <div>
            <div className="timeline-marker-label-anchor">
              <div className="timeline-marker-label">
                <div className="timeline-marker-label-value">
                  <div>{pointMarkerTime}</div>
                </div>
              </div>
            </div>
            <div className="timeline-marker-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundsModuleTasks;
