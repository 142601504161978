import { isNullOrUndefined } from "./utils";

// Sync with https://github.com/Moonware-Inc/halo/blob/d6f400c416dc882031f162a55775e18653652827/src/sdk/db/enums/ground_vehicle_status.go
export const GroundVehicleStatus = {
  UnknownGroundVehicleStatus: 0,
  Available: 1,
  Offline: 2,
};

export function getAllGseRecords(turnarounds) {
  let allGseRecords = [];
  for (let i = 0; i < turnarounds.length; i++) {
    const gseRecords = getAllGseRecordsForTurnaround(turnarounds[i]);
    if (!isNullOrUndefined(gseRecords)) {
      allGseRecords = [...allGseRecords, ...gseRecords];
    }
  }
  return allGseRecords;
}

export function getAllGseRecordsForTurnaround(turnaround) {
  let allGseRecords = [];
  const turnaroundOperations = getTurnaroundOperations(turnaround);

  if (!isNullOrUndefined(turnaroundOperations)) {
    for (let j = 0; j < turnaroundOperations.length; j++) {
      const turnaroundOperation = turnaroundOperations[j];
      // Note: this is a bit hacky because we want some of the fields from operation/turnaround
      const gseRecords = !isNullOrUndefined(turnaroundOperation.gseRecords)
        ? turnaroundOperation.gseRecords.map((item) => {
            return {
              ...item,
              operationInfo: {
                uuid: turnaroundOperation.uuid,
                name: turnaroundOperation?.type.name,
                expectedStartTime: turnaroundOperation.expectedStartTime,
                expectedEndTime: turnaroundOperation.expectedEndTime,
                parentTurnaround: turnaround,
              },
            };
          })
        : null;

      if (!isNullOrUndefined(gseRecords)) {
        allGseRecords = [...allGseRecords, ...gseRecords];
      }
    }
  }
  return allGseRecords;
}

export function getAllOperationsForTurnaround(turnaround) {
  let allOperations = [];
  const turnaroundOperations = getTurnaroundOperations(turnaround);

  if (!isNullOrUndefined(turnaroundOperations)) {
    for (let j = 0; j < turnaroundOperations.length; j++) {
      const turnaroundOperation = turnaroundOperations[j];
      allOperations.push(turnaroundOperation);
    }
  }
  return allOperations;
}

function getTurnaroundOperations(turnaround) {
  const profile = !isNullOrUndefined(turnaround) ? turnaround.profile : null;
  const monitor = !isNullOrUndefined(turnaround) ? turnaround.monitor : null;
  return !isNullOrUndefined(monitor)
    ? monitor.turnaroundOperations
    : !isNullOrUndefined(profile)
    ? profile.turnaroundOperations
    : null;
}

// Mapping of certification to a GSE type/identifier
export const GseTypeByCertificationName = {
  Admin: "admin",
  "Air conditioning truck": "ac-truck",
  "Air start unit": "air-start-unit",
  "Baggage tractor": "baggage-tractor",
  "Belt loader": "belt-loader",
  "Bobtail truck": "bobtail-truck",
  "Cabin cleaning truck": "cabin-cleaning-truck",
  "Cargo tractor": "cargo-tractor",
  "Catering truck": "catering-truck",
  Engineering: "engineering",
  Extra: "extra",
  "Fuel truck": "fuel-truck",
  "Garbage truck": "garbage-truck",
  "Ground power unit": "ground-power-unit",
  "Lavatory truck": "lavatory-truck",
  "Lower deck loader": "lower-deck-loader",
  "Main deck loader": "main-deck-loader",
  Maintenance: "maintenance",
  Other: "other",
  "Potable water truck": "potable-water-truck",
  "Pushback tug": "pushback-tug",
  Stairs: "stairs",
  "Stair truck": "stair-truck",
  "Utility vehicle": "utility-vehicle",
};
// Case-insensitive search
export function getGseTypeByCertificationName(name) {
  const keys = Object.keys(GseTypeByCertificationName);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key.toLowerCase() === name.toLowerCase()) {
      return GseTypeByCertificationName[key];
    }
  }
  return null;
}
