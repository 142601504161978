import "./styles.css";
import { selectInputFix } from "../../styleUtils";
import { useTranslation } from "react-i18next";
import {
  useSettingsContext,
  useSettingsDispatchContext,
} from "../../SettingsContext";
import { useEffect, useState } from "react";
import { getAvailableLanguages, updateUserLanguage } from "../../settingsApi";
import { getUser } from "../../api";
import { useMainContext, useMainDispatchContext } from "../../MainContext";

function Language() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser } = mainContext;

  const settingsDispatch = useSettingsDispatchContext();
  const settingsContext = useSettingsContext();
  const { availableLanguages } = settingsContext;

  const [isSaving, setIsSaving] = useState(false);
  const [userLanguageCode, setUserLanguageCode] = useState(
    currentUser?.language?.code
  );

  useEffect(() => {
    getAvailableLanguages(settingsDispatch);
  }, [settingsDispatch]);

  async function handleUpdateUserLanguage() {
    setIsSaving(true);
    const result = await updateUserLanguage(settingsDispatch, userLanguageCode);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("Settings saved"),
      });
    } else {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("Error saving settings"),
      });
    }
    // Refresh the user
    getUser(dispatch);
    setIsSaving(false);
  }

  // const languages = Object.keys(LANGUAGES);
  return (
    <div className="language">
      <div>
        <div className="settings-form-header">
          <h3>{t("choose_language")}</h3>
        </div>
        <div>
          <div className="form-container">
            <div className="field-row">
              <label>{t("language")}</label>
              <div className="language-picker">
                <div>
                  <select
                    value={userLanguageCode}
                    onChange={(e) => {
                      setUserLanguageCode(e.target.value);
                    }}
                    style={selectInputFix}
                  >
                    {availableLanguages &&
                      availableLanguages.map((lang) => (
                        <option value={lang.code} key={lang.code}>
                          {lang.displayName}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <button
                    className="primary"
                    onClick={handleUpdateUserLanguage}
                    disabled={isSaving}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
