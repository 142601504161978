import { useTranslation } from "react-i18next";
import { METRICS_SORT_FIELDS } from "../../constants";
import { isEmptyList, isNullOrUndefined, sortByField } from "../../utils";
import OperationSortBar from "./OperationSortBar";
import TurnaroundOperationTile from "./TurnaroundOperationTile";
import { useMemo, useState } from "react";

function OperationOnTimePerformance(props) {
  const { t } = useTranslation();
  const {
    chartData,
    wideLayout,
    showSort,
    selectedOperations,
    operationsList,
  } = props;
  const [selectedSort, setSelectedSort] = useState(
    METRICS_SORT_FIELDS.COMPLETED_ONTIME
  );

  const filteredList = useMemo(() => {
    const dataList = [];
    if (
      !isNullOrUndefined(chartData?.aggregatesByOperationType) &&
      !isEmptyList(operationsList)
    ) {
      for (let i = 0; i < operationsList?.length; i++) {
        const operation = operationsList[i];
        const shouldInclude =
          isEmptyList(selectedOperations) ||
          selectedOperations.includes(operation.uuid);
        if (shouldInclude) {
          const operationMetric =
            chartData?.aggregatesByOperationType[operation.uuid];
          if (!isNullOrUndefined(operationMetric)) {
            const dataValues = {
              ...operationMetric,
              type: operation,
              completedLatePercent: operationMetric.latePercent,
              completedOnTimePercent: operationMetric.onTimePercent,
              partiallyCompletedPercent:
                operationMetric.partiallyCompletedPercent,
              missingPercent: operationMetric.missingPercent,
            };
            dataList.push(dataValues);
          }
        }
      }
    }

    const altSortFields = [
      "completedOnTimePercent",
      "completedLatePercent",
      "partiallyCompletedPercent",
      "missingPercent",
    ];
    if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_LATE) {
      sortByField(dataList, "completedLatePercent", altSortFields);
    } else if (selectedSort === METRICS_SORT_FIELDS.MISSING) {
      sortByField(dataList, "missingPercent", altSortFields);
    } else if (selectedSort === METRICS_SORT_FIELDS.AVG_DELAY) {
      sortByField(dataList, "averageDelay", altSortFields);
    } else if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_PARTIALLY) {
      sortByField(dataList, "partiallyCompletedPercent", altSortFields);
    } else if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_ONTIME) {
      sortByField(dataList, "completedOnTimePercent", altSortFields);
    }
    dataList.reverse();

    return dataList;
  }, [chartData, selectedOperations, operationsList, selectedSort]);

  const layoutClass = wideLayout ? "two" : "three";

  return (
    <div className={`chart-container${showSort ? " sortable" : ""}`}>
      {showSort && (
        <>
          <div className="chart-title-bar">
            <h4>{t("ground_operations_on_time_performance")}</h4>
          </div>
          <OperationSortBar
            selectedSort={selectedSort}
            onChange={(value) => {
              setSelectedSort(value);
            }}
          />
        </>
      )}
      <div className={`chart-tiles ${layoutClass}`}>
        {filteredList &&
          filteredList.map((item, idx) => (
            <TurnaroundOperationTile
              operation={item}
              key={`module-${idx}`}
              highlightMetric={selectedSort}
              wideLayout={wideLayout}
            />
          ))}
      </div>
    </div>
  );
}

export default OperationOnTimePerformance;
