import "./styles.css";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { isNullOrUndefined } from "../../utils";
import { useMainContext } from "../../MainContext";
import { useTranslation } from "react-i18next";

function Confirmation(props) {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const { confirmation, confirmationProcessing } = mainContext;
  if (isNullOrUndefined(confirmation)) {
    return null;
  }

  const { message, actionOK, actionCancel, actionLabel, cancelLabel } =
    confirmation;
  return (
    <Dialog
      className="confirmation-message"
      open={!isNullOrUndefined(message)}
      onClose={actionCancel}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          bgcolor: "transparent",
        },
        "& .MuiDialogActions-root": {
          padding: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          bgcolor: "#1D1F2C",
          minWidth: "350px",
        }}
      >
        {/** Avoid using DialogContentText since the implemenation uses a <p> tag **/}
        <div className="confirmation-content">{message}</div>
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: "#1D1F2C",
          color: "#FFF",
        }}
      >
        <Button
          onClick={actionCancel}
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            borderRadius: "8px",
            bgcolor: "transparent",
            border: "1px solid #4A4C56",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#4A4C56",
            },
            textTransform: "none",
          }}
          disabled={confirmationProcessing}
        >
          {cancelLabel || t("cancel")}
        </Button>
        <Button
          onClick={actionOK}
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            borderRadius: "8px",
            bgcolor: "#1779da",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#1779da",
            },
            textTransform: "none",
          }}
          disabled={confirmationProcessing}
        >
          {actionLabel || t("okay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirmation;
