import { useTranslation } from "react-i18next";
import "./styles.css";
import { useRef, useState } from "react";
import { useMainDispatchContext } from "../../MainContext";
import { isBlank } from "../../utils";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import { isDebugLoggingEnabled } from "../../logging";
import ToggleSwitch from "../ToggleSwitch";

function AdminConsole() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const [eventName, setEventName] = useState("");
  const debugLoggingEnabled = isDebugLoggingEnabled();
  const [debugValue, setDebugValue] = useState(debugLoggingEnabled);
  const eventNameInputRef = useRef(null);

  function handleCreateEvent() {
    if (isBlank(eventName)) return;
    dispatch({
      type: "setAnalyticsEvent",
      value: {
        eventName: eventName,
      },
    });
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("created_event_message"),
    });
    setEventName("");
    eventNameInputRef.current.focus();
  }

  return (
    <div className="admin-console">
      <div>
        <div className="settings-form-header">
          <h3>{t("admin_console")}</h3>
        </div>
        <div>
          <div className="form-container">
            <div className="field-row">
              <label>{t("create_analytics_event")}</label>
              <div className="field-input">
                <div>
                  <input
                    type="text"
                    value={eventName}
                    onChange={(e) => {
                      setEventName(e.target.value);
                    }}
                    ref={eventNameInputRef}
                  />
                </div>
                <div>
                  <button className="primary" onClick={handleCreateEvent}>
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
            <div className="field-row">
              <label>Debug logging enabled</label>
              <div className="field-input">
                <div>
                  <ToggleSwitch
                    isSelected={debugValue}
                    onChange={() => {
                      const newValue = !debugValue;
                      SettingsManager.setSetting(
                        SettingNames.DEBUG_LOGGING.name,
                        newValue
                      );
                      setDebugValue(newValue);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminConsole;
