import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import OptionBox from "../OptionBox";
import { useEffect, useState } from "react";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";

function CrewAssignmentModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { users } = mainContext;

  const { selectedTurnaroundInfo, selectedUserUuid, onClose, onAssignedUser } =
    props;
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [isSavingAssignment, setIsSavingAssignment] = useState(false);
  useEffect(() => {
    setSelectedRequirements([]);
  }, [selectedUserUuid]);

  function updateSelectedRequirements(requirement) {
    setSelectedRequirements((prev) => {
      const updatedValues = deepCopy(prev);
      const idx = updatedValues.indexOf(requirement);
      if (idx === -1) {
        updatedValues.push(requirement);
      } else {
        updatedValues.splice(idx, 1);
      }
      return updatedValues;
    });
  }
  const userToAssign = getByFieldValue(users, "uuid", selectedUserUuid);
  if (
    isNullOrUndefined(selectedTurnaroundInfo) ||
    isNullOrUndefined(selectedUserUuid) ||
    isNullOrUndefined(userToAssign)
  ) {
    return null;
  }

  const fullName = `${userToAssign.firstName} ${userToAssign.lastName}`;
  const isButtonDisabled =
    isEmptyList(selectedRequirements) || isSavingAssignment;
  const operations = selectedTurnaroundInfo?.operations;

  const operationsToRender = [];
  const requirementsByOperation = {};
  const requirementsByRequirementUuid = {};
  if (!isEmptyList(operations) && !isNullOrUndefined(userToAssign)) {
    sortByField(operations, "name");
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      if (operation.enabled) {
        const validRequirements = [];
        const operationRequirements = operation.turnaroundRequirements;
        for (let j = 0; j < operationRequirements.length; j++) {
          const operationRequirement = operationRequirements[j];
          const certToCheck = operationRequirement.certification;
          if (
            userToAssign?.certifications.some(
              (item) => item.uuid === certToCheck.uuid
            )
          ) {
            validRequirements.push({
              value: operationRequirement.uuid,
              name: operationRequirement.certification.name,
            });
            requirementsByRequirementUuid[operationRequirement.uuid] =
              operationRequirement;
          }
        }
        if (!isEmptyList(validRequirements)) {
          operationsToRender.push(operation);
          requirementsByOperation[operation.uuid] = validRequirements;
        }
      }
    }
  }

  async function handleResolve() {
    if (isEmptyList(selectedRequirements)) return;
    setIsSavingAssignment(true);

    const requirementsForPatch = [];
    for (let i = 0; i < selectedRequirements.length; i++) {
      const requirementUuid = selectedRequirements[i];
      const requirementToAssign =
        requirementsByRequirementUuid[requirementUuid];

      // Adjust the quantity only if there aren't enough exisiting slots
      // Otherwise this just counts towards the requirement quantity
      const existingQuantity = !isEmptyList(
        requirementToAssign?.crewAssignments
      )
        ? requirementToAssign.crewAssignments.length
        : 0;
      const adjustedQuantity = Math.max(
        existingQuantity + 1,
        requirementToAssign.quantity
      );

      requirementsForPatch.push({
        userUuidsToAdd: [selectedUserUuid],
        uuid: requirementToAssign.uuid,
        quantity: adjustedQuantity,
      });
    }

    const patchRequest = !isNullOrUndefined(
      selectedTurnaroundInfo.originalProfile
    )
      ? {
          uuid: selectedTurnaroundInfo.originalProfile.uuid,
          operations: [],
          requirements: requirementsForPatch,
          parentLabelUuidsToApply: [],
          labelUuidsToRemove: [],
        }
      : null;
    const result = !isNullOrUndefined(patchRequest)
      ? await patchTurnaroundProfile(dispatch, patchRequest)
      : false;
    await getTurnaroundDetails(dispatch, selectedTurnaroundInfo);

    if (result) {
      if (!isNullOrUndefined(onAssignedUser)) {
        // Function to call when the user has been assigned
        onAssignedUser();
      }
      let dispatchMessage = `${t("saved_web", {
        name: selectedTurnaroundInfo.combinedFlightName,
      })}`;
      dispatch({
        type: "setAlertMessage",
        alertMessage: dispatchMessage,
      });
    }
    onClose();
    setIsSavingAssignment(false);
  }

  return (
    <div className="crew-assignment-module">
      <div className="crew-assignment-module-header">
        <div>
          <div className="crew-assignment-module-header-title">
            <div>{t("resolve_crew_services")}</div>
            <div>{fullName}</div>
          </div>
          <div className="crew-assignment-module-actions">
            <div className="close-btn" onClick={onClose}>
              <CloseBtn />
            </div>
          </div>
        </div>
      </div>
      <div className="crew-assignment-module-body">
        <div className="crew-assignment-module-content">
          <div>
            {!isEmptyList(operationsToRender) &&
              operationsToRender.map((operation) => (
                <OptionBox
                  key={operation.uuid}
                  name={operation?.type.name}
                  options={requirementsByOperation[operation.uuid]}
                  selectedOptions={selectedRequirements}
                  selectedUserUuid={selectedUserUuid}
                  onChange={(requirement) => {
                    updateSelectedRequirements(requirement);
                  }}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="crew-assignment-module-footer">
        <div>
          <button onClick={handleResolve} disabled={isButtonDisabled}>
            {t("resolve")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CrewAssignmentModule;
