import "./styles.css";
import { useEffect, useMemo } from "react";
import PageToolbar from "../PageToolbar";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DataTable, { ColumnNames } from "../DataTable";
import { saveNotificationsLastRead } from "../../api";
import NotificationsEmpty from "./NotificationsEmpty";
import { useTranslation } from "react-i18next";
import {
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";

function Notifications() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { notifications, currentUser } = mainContext;

  const notificationsToRender = useMemo(() => {
    if (isNullOrUndefined(currentUser) || isEmptyList(notifications)) {
      return [];
    }
    const airportTimezone = getTimezoneFromUser(currentUser);

    return notifications.map((item) => {
      return { ...item, timezone: airportTimezone };
    });
  }, [notifications, currentUser]);

  useEffect(() => {
    // Update the read timestamp after the page loaded for first time and 5 sec has passed
    const st = setTimeout(() => {
      saveNotificationsLastRead(dispatch);
    }, 5000);

    // clean up
    return () => {
      // When exiting the page also mark everything as read
      clearTimeout(st);
      saveNotificationsLastRead(dispatch);
    };
  }, [dispatch]);

  const actionButtons = [];
  return (
    <div className="notifications show-new">
      <div className="notifications-container">
        <PageToolbar title={t("notifications")} actionButtons={actionButtons} />

        <DataTable
          dataList={notificationsToRender}
          columnNames={[
            ColumnNames.NotificationTitle,
            ColumnNames.NotificationMessage,
            ColumnNames.NotificationSentAt,
            ColumnNames.NotificationStatus,
          ]}
          initialSort={ColumnNames.Datetime.id}
          initialSortDesc={true}
          emptyState={<NotificationsEmpty />}
        />
      </div>
    </div>
  );
}
export default Notifications;
