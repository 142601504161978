import { createContext, useContext } from "react";

export const ChatContext = createContext(null);
export const ChatDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useChatContext() {
  return useContext(ChatContext);
}

export function useChatDispatchContext() {
  return useContext(ChatDispatchContext);
}
