import Modal from "@mui/material/Modal";
import {
  formatDate,
  formatTime,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useTranslation } from "react-i18next";
import { useCrewShiftsDispatchContext } from "../../CrewShiftsContext";
import { useMemo } from "react";
import moment from "moment-timezone";
import { getUserInfo } from "../../userUtils";
import { importCrewShifts } from "../../crewShiftsApi";
import { useMainDispatchContext } from "../../MainContext";
import { getDayAbbreviation } from "../../dateUtils";

export default function CrewShiftPreviewModal(props) {
  const {
    crewShiftsImportPreview,
    timezone,
    users,
    fileUploadUuid,
    onCrewShiftImport,
  } = props;
  const { t } = useTranslation();
  const crewShiftsDispatch = useCrewShiftsDispatchContext();
  const dispatch = useMainDispatchContext();

  function handleClose() {
    crewShiftsDispatch({
      type: "setCrewShiftsImportPreview",
      value: null,
    });
  }

  async function handleImport() {
    const importPreviewResult = await importCrewShifts(
      crewShiftsDispatch,
      fileUploadUuid
    );
    if (importPreviewResult) {
      onCrewShiftImport();
    } else {
      dispatch({
        type: "setGlobalError",
        globalError: t("error_import_csv"),
      });
    }
  }

  const [previewUsers, previewDays, previewCrewShifts] = useMemo(() => {
    if (isEmptyList(crewShiftsImportPreview)) return [[], [], {}];
    const usersList = [];
    const daysList = [];
    const crewShiftsByUser = {};

    for (let i = 0; i < crewShiftsImportPreview.length; i++) {
      const crewShift = crewShiftsImportPreview[i];
      const shiftDayValue = moment(crewShift.startTime)
        .tz(timezone)
        .startOf("day")
        .toDate();
      const shiftDay = formatDate(shiftDayValue, timezone);
      const shiftDayOfWeek = getDayAbbreviation(shiftDayValue);

      if (isNullOrUndefined(getByFieldValue(daysList, "shiftDay", shiftDay))) {
        daysList.push({
          shiftDayOfWeek: shiftDayOfWeek,
          shiftDayValue: shiftDayValue,
          shiftDay: shiftDay,
        });
      }

      const crewShiftForDay = {
        ...crewShift,
      };
      if (crewShift.userUuid in crewShiftsByUser) {
        // Add to existing user
        const crewShiftsForUser = crewShiftsByUser[crewShift.userUuid];
        if (shiftDay in crewShiftsForUser) {
          crewShiftsForUser[shiftDay].push(crewShiftForDay);
        } else {
          crewShiftsForUser[shiftDay] = [crewShiftForDay];
        }
        sortByField(crewShiftsForUser[shiftDay], "startTime");
      } else {
        // Add another user
        const crewShiftsForUser = {};
        crewShiftsForUser[shiftDay] = [crewShiftForDay];
        crewShiftsByUser[crewShift.userUuid] = crewShiftsForUser;

        const user = getByFieldValue(users, "uuid", crewShift.userUuid);
        const userInfo = !isNullOrUndefined(user) ? getUserInfo(user) : null;
        if (!isNullOrUndefined(userInfo)) {
          usersList.push(userInfo);
        }
      }
    }
    sortByField(daysList, "shiftDayValue");
    sortByField(usersList, "fullName");
    const daysToRender = daysList;
    return [usersList, daysToRender, crewShiftsByUser];
  }, [timezone, crewShiftsImportPreview, users]);

  const rowStyle = {
    gridTemplateColumns: `96px repeat(${previewDays.length}, 1fr)`,
  };

  const importCount = !isEmptyList(previewUsers) ? previewUsers.length : 0;

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(crewShiftsImportPreview)}
        onClose={handleClose}
      >
        <div className="modal crew-shift-preview">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("confirm_import")}</h2>
                <span>{t("confirm_import_note")}</span>
              </div>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>

          <div className="modal-body crew-shift-preview-container">
            <div className="crew-shift-preview-content">
              <div className="crew-shift-preview-rows">
                <div className="crew-shift-preview-row header" style={rowStyle}>
                  <div className="crew-shift-preview-cell">
                    <div>{t("name")}</div>
                  </div>
                  {previewDays &&
                    previewDays.map((previewDay) => (
                      <div className="crew-shift-preview-cell">
                        <div className="crew-shift-preview-day">
                          <div>{previewDay.shiftDayOfWeek}</div>
                          <div>
                            <span>{previewDay.shiftDay}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {!isEmptyList(previewUsers) &&
                  previewUsers.map((userInfo) => (
                    <div
                      className="crew-shift-preview-row"
                      style={rowStyle}
                      key={userInfo.uuid}
                    >
                      <div className="crew-shift-preview-cell">
                        <div>{userInfo.fullName}</div>
                      </div>
                      {previewDays &&
                        previewDays.map((previewDay) => {
                          const crewShiftsForUser =
                            previewCrewShifts[userInfo.uuid];
                          const crewShiftsForDay =
                            crewShiftsForUser[previewDay.shiftDay];
                          return (
                            <div className="crew-shift-preview-cell">
                              {!isNullOrUndefined(crewShiftsForDay) &&
                                crewShiftsForDay.map((crewShiftForDay, idx) => (
                                  <div key={`${crewShiftForDay.uuid}_${idx}`}>
                                    <div className="crew-shift-preview-shift">
                                      {`${formatTime(
                                        crewShiftForDay.startTime
                                      )}-${formatTime(
                                        crewShiftForDay.endTime
                                      )}`}
                                    </div>
                                    <div>
                                      <span>
                                        {crewShiftForDay.operationType?.name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              {isNullOrUndefined(crewShiftsForDay) && (
                                <div>
                                  <span className="de-emphasize">
                                    {t("no_assignments")}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button onClick={handleImport}>
                  {importCount === 1
                    ? t("import_crew_shift")
                    : t("import_crew_shifts_web", { value: importCount })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
