import { useTranslation } from "react-i18next";

function NotificationsEmpty() {
  const { t } = useTranslation();

  return (
    <div className="alerts-empty-state">
      <div>
        <div>
          <h3>{t("notifications_empty")}</h3>
        </div>
      </div>
    </div>
  );
}

export default NotificationsEmpty;
