import "./styles.css";
import moment from "moment-timezone";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import { deepCopy, isEmptyList, isNullOrUndefined } from "../../utils";
import { DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import { getOperationTypes, getPerformanceOperations } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import OperationOnTimePerformance from "./OperationOnTimePerformance";
import RangePicker from "../ButtonDatePicker/RangePicker";
import MetricsFilter from "../MetricsFilter";
import TurnaroundOperationsTrend from "./TurnaroundOperationsTrend";
import Checkbox from "../Checkbox";
// import DataExport from "../DataExport";
import { useTranslation } from "react-i18next";

function OperationPerformance() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, operationTypes, performanceOperations } = mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDaysAgo = moment(mToday).add(-90, "day").tz(airportTimezone);
  const [selectedStartDate, setSelectedStartDate] = useState(mThirtyDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(mToday);
  const [selectedOperations, setSelectedOperations] = useState([]);

  // Load required data
  useEffect(() => {
    getOperationTypes(dispatch);

    // Turn off polling
    dispatch({
      type: "setIsRefreshPaused",
      value: true,
    });

    return () => {
      // Turn on polling when navigating away
      dispatch({
        type: "setIsRefreshPaused",
        value: false,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (isEmptyList(operationTypes)) return () => {};
    const startTime = moment(selectedStartDate).startOf("day").format();
    const endTime = moment(selectedEndDate).endOf("day").format();
    getPerformanceOperations(dispatch, startTime, endTime);
  }, [dispatch, selectedStartDate, selectedEndDate, operationTypes]);

  const handleChangeDateRange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  function handleSidePanelSelectedOperations(value) {
    const updatedOperations = deepCopy(selectedOperations);
    const idx = updatedOperations.indexOf(value);
    if (idx > -1) {
      updatedOperations.splice(idx, 1);
    } else if (value === MetricsFilter.ALL_METRICS_FILTER_VALUE) {
      // If all, remove everything else
      updatedOperations.splice(0);
    } else {
      updatedOperations.push(value);
    }
    setSelectedOperations(updatedOperations);
  }

  const tilesWideLayout = selectedOperations.length === 1;
  const showTilesSort =
    selectedOperations.length === 0 || selectedOperations.length > 1;

  /** TODO
  function handleDataExport() {
    return null;
  }
  **/

  const showLoadingProgress = false;
  return (
    <div className="performance">
      <div className="performance-container">
        <div className="performance-toolbar">
          <div>
            <h2>{t("on_time_performance")}</h2>
          </div>
          <div></div>
          <div className="metrics-filters">
            <div>
              <div></div>
              <div></div>
              <div>
                <RangePicker
                  today={mToday}
                  airportTimezone={airportTimezone}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  minDate={mMinDate}
                  maxDate={mToday}
                  maxRange={180}
                  onChange={(startValue, endValue) => {
                    handleChangeDateRange(startValue, endValue);
                  }}
                />
              </div>
              {/** TODO: Implement when we have API support
              <DataExport handleDataExport={handleDataExport} />
              **/}
            </div>
          </div>
        </div>

        <div className="charts-container">
          <div className="charts-side-panel-content">
            <div className="charts-side-panel">
              <div className="charts-side-panel-container">
                <div className="charts-side-panel-module">
                  <div
                    className={`charts-side-panel-item${
                      selectedOperations.length === 0 ? " selected" : ""
                    }`}
                    onClick={() => {
                      handleSidePanelSelectedOperations(
                        MetricsFilter.ALL_METRICS_FILTER_VALUE
                      );
                    }}
                  >
                    <div>{t("all_operations")}</div>
                    <div>
                      <Checkbox
                        isChecked={selectedOperations.length === 0}
                        changeHandler={() => {
                          handleSidePanelSelectedOperations(
                            MetricsFilter.ALL_METRICS_FILTER_VALUE
                          );
                        }}
                      />
                    </div>
                  </div>
                  {!isEmptyList(operationTypes) &&
                    operationTypes.map((operation) => (
                      <div
                        className={`charts-side-panel-item${
                          selectedOperations.includes(operation.name)
                            ? " selected"
                            : ""
                        }`}
                        key={operation.uuid}
                        onClick={() => {
                          handleSidePanelSelectedOperations(operation.uuid);
                        }}
                      >
                        <div>{operation?.name}</div>
                        <div>
                          <Checkbox
                            isChecked={selectedOperations.includes(
                              operation?.uuid
                            )}
                            changeHandler={() => {
                              handleSidePanelSelectedOperations(operation.uuid);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="charts-content">
            <div className="chart-module mainchart">
              {showLoadingProgress && <LoadingIndicator />}
              {!showLoadingProgress && (
                <div>
                  <TurnaroundOperationsTrend
                    airportTimezone={airportTimezone}
                    chartData={performanceOperations}
                    startTime={selectedStartDate}
                    endTime={selectedEndDate}
                    selectedOperations={selectedOperations}
                    operationTypes={operationTypes}
                  />
                </div>
              )}
            </div>
            {!showLoadingProgress && (
              <div className="chart-module transparent">
                <OperationOnTimePerformance
                  chartData={performanceOperations}
                  selectedOperations={selectedOperations}
                  wideLayout={tilesWideLayout}
                  showSort={showTilesSort}
                  operationsList={operationTypes}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OperationPerformance;
