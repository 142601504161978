import { useMemo } from "react";
import { useMainContext } from "../../MainContext";
import { getByFieldValue, isEmptyList, isNullOrUndefined } from "../../utils";
import { useLocation } from "react-router-dom";

function NotificationsIndicator() {
  const mainContext = useMainContext();
  const { notifications } = mainContext;
  const location = useLocation();

  const showIndicator = useMemo(() => {
    if (isEmptyList(notifications)) return false;
    if (location.pathname === "/notifications") return false;

    // Show indicator if there are any unread notifications
    const unreadNotification = getByFieldValue(notifications, "read", false);
    return !isNullOrUndefined(unreadNotification);
  }, [notifications, location]);

  return <>{showIndicator && <div className="status-indicator"></div>}</>;
}
export default NotificationsIndicator;
