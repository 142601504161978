import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { isBlank, isNullOrUndefined } from "../utils";

// BASE contains all string keys used within the webapp
import BASE from "./base.json";

import EN from "./en.json";
import { LANGUAGES } from "../constants";
// Additional supported languages should go here
// import FR from "./fr.json";
import ES from "./es.json";
import { debugLog } from "../logging";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: createStringResource(EN, "EN"),
      es: createStringResource(ES, "ES"),
    },
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function createStringResource(sourceLanguageFile, languageFileName) {
  const stringResource = { translation: {} };
  const keys = Object.keys(BASE.translation);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const translation = sourceLanguageFile.translation[key];
    if (!isBlank(translation)) {
      stringResource.translation[key] = translation;
    } else {
      // Use BASE file as fallback
      stringResource.translation[key] = BASE.translation[key];
      // debugLog(
      //   `Translation needed for [${key}]: language file: [${languageFileName}]`
      // );
    }
  }
  return stringResource;
}

// This does NOT set the user's language setting
// Only sets the webapp's language to match the user's browser language if supported
export function setDefaultLanguage() {
  const userLang = navigator.language || navigator.userLanguage;
  const langParts = !isBlank(userLang) ? userLang.split("-") : null;
  const lang = langParts?.length > 0 ? langParts[0] : null;
  if (!isNullOrUndefined(lang)) {
    debugLog(`Browser language detected: ${lang}`);
    if (!(lang in LANGUAGES)) {
      debugLog(`Browser language not supported: ${lang}`);
    } else if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
      debugLog(`Default language set to: ${lang}`);
    }
  }
}
export default i18n;
