import { useTranslation } from "react-i18next";
import { isEmptyList, sortByField } from "../../utils";
import StatusBadge from "../StatusBadge";
import StatusBadges from "../StatusBadge/StatusBadges";

function DispatchOverrides(props) {
  const { t } = useTranslation();
  const { turnaroundInfo, onEditClick, showEditButtons } = props;
  const labelsToRender = turnaroundInfo?.activeLabels;
  const hasLabelsToRender = !isEmptyList(labelsToRender);
  if (hasLabelsToRender) {
    sortByField(labelsToRender, "name");
  }

  return (
    <div className="dispatch-overrides">
      {!hasLabelsToRender && (
        <div className="dispatch-overrides-empty">
          <div>
            {showEditButtons && (
              <button className="outlined" onClick={onEditClick}>
                {t("add_special_cases")}
              </button>
            )}
          </div>
        </div>
      )}
      {hasLabelsToRender && (
        <div className="dispatch-overrides-list">
          <div>
            <StatusBadges>
              {labelsToRender &&
                labelsToRender.map((item) => (
                  <StatusBadge
                    key={item.uuid}
                    isGeneric={true}
                    isAllCap={true}
                    message={item.name}
                  />
                ))}
            </StatusBadges>
            <div>
              {showEditButtons && (
                <button className="outlined" onClick={onEditClick}>
                  {t("edit_special_cases")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default DispatchOverrides;
