import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";
import {
  getAircraftInfoForMap,
  getFlightByRegistration,
  handleHover,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import {
  formatTime,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import TurnaroundProgressBar from "../Turnarounds/TurnaroundProgressBar";
import {
  TurnaroundOperationStatus,
  getCriticalOperationsFromTurnaround,
  getTurnaroundInfo,
} from "../../turnaroundUtils";
import {
  getTurnaroundDetailsForConfirm,
  getTurnaroundsSummary,
  patchTurnaroundOperationAckStatus,
} from "../../api";
import { useEffect } from "react";
import CriticalMessages from "../Confirmation/CriticalMessages";

function TurnaroundInfoModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundInfo } = props;
  const { turnaroundDetailsForConfirm, flightsToRender } = mainContext;
  const airportTimezone = turnaroundInfo.airportTimezone;

  const inboundName = !isNullOrUndefined(turnaroundInfo.inboundFlightName)
    ? turnaroundInfo.inboundFlightName
    : "-";
  const outboundName = !isNullOrUndefined(turnaroundInfo.outboundFlightName)
    ? turnaroundInfo.outboundFlightName
    : "-";
  const landingTime = !isNullOrUndefined(turnaroundInfo.landing)
    ? formatTime(turnaroundInfo.landing, airportTimezone, true)
    : EMPTY_TIME;

  const takeoffTime = !isNullOrUndefined(turnaroundInfo.takeoff)
    ? formatTime(turnaroundInfo.takeoff, airportTimezone, true)
    : EMPTY_TIME;

  const hasSnappedStand = !isNullOrUndefined(
    turnaroundInfo?.snappedStand?.name
  );
  const arrivalStand = hasSnappedStand
    ? turnaroundInfo?.snappedStand?.name
    : !isBlank(turnaroundInfo?.arrivalStand?.name)
    ? turnaroundInfo?.arrivalStand.name
    : EMPTY_STAND;

  const departureStand = !isBlank(turnaroundInfo?.departureStand?.name)
    ? turnaroundInfo?.departureStand.name
    : EMPTY_STAND;

  const hasOutbound = !isBlank(turnaroundInfo.outboundFlightName);

  const status = turnaroundInfo?.turnaroundLevelOperationStatus;
  const statusMissingCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.MISSING]
    : 0;
  const statusDelayedCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.IN_PROGRESS_LATE]
    : 0;

  const flightInfo = !isNullOrUndefined(turnaroundInfo)
    ? getFlightByRegistration(flightsToRender, turnaroundInfo?.registration)
    : null;
  const aircraft = flightInfo?.aircraft;
  const aircraftInfo = !isNullOrUndefined(flightInfo)
    ? getAircraftInfoForMap(flightInfo, aircraft, turnaroundInfo)
    : null;

  // NOTE: Missing or Delayed both are considered Critical
  // These counts are decremented if the user has ack'd them
  const totalCriticalCount = statusMissingCount + statusDelayedCount;
  const showCriticalState = totalCriticalCount > 0;

  // Show a confirmation dialog with some details
  async function handleNotCritical() {
    await getTurnaroundDetailsForConfirm(dispatch, {
      uuid: turnaroundInfo.uuid,
    });
  }

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetailsForConfirm)) {
      const turnaroundInfo = getTurnaroundInfo(turnaroundDetailsForConfirm);
      const criticalOperations =
        getCriticalOperationsFromTurnaround(turnaroundInfo);
      const criticalOperationUuids = !isEmptyList(criticalOperations)
        ? criticalOperations.map((item) => item.uuid)
        : [];

      // Callback function when the user confirms
      async function handleConfirmations() {
        dispatch({
          type: "setConfirmationProcessing",
          confirmationProcessing: true,
        });
        for (let i = 0; i < criticalOperationUuids.length; i++) {
          const criticalOperationUuid = criticalOperationUuids[i];
          await patchTurnaroundOperationAckStatus(
            dispatch,
            criticalOperationUuid
          );
        }
        // Refresh the summaries
        await getTurnaroundsSummary(dispatch);

        // Completed message
        let dispatchMessage = `${t("saved_web", {
          name: turnaroundInfo.combinedFlightName,
        })}`;
        dispatch({
          type: "setAlertMessage",
          alertMessage: dispatchMessage,
        });

        dispatch({
          type: "setConfirmation",
          confirmation: null,
        });
      }

      dispatch({
        type: "setConfirmation",
        confirmation: {
          message: <CriticalMessages turnaroundInfo={turnaroundInfo} />,
          actionLabel: t("confirm_non_cap"),
          actionOK: () => {
            handleConfirmations();
          },
          actionCancel: () => {
            dispatch({
              type: "setConfirmation",
              confirmation: null,
            });
          },
        },
      });

      // Cleanup
      dispatch({
        type: "setTurnaroundDetailsForConfirm",
        value: null,
      });
    }
  }, [t, dispatch, turnaroundDetailsForConfirm]);

  return (
    <div
      className={`overlay-module${
        turnaroundInfo?.isCritical ? ` status-critical` : ""
      }`}
      data-id={turnaroundInfo.uuid}
      data-inbound-id={turnaroundInfo?.inboundFlightId}
      data-outbound-id={turnaroundInfo?.outboundFlightId}
      data-registration={turnaroundInfo?.registration}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              aircraft: {
                registration: turnaroundInfo.registration,
              },
            },
          },
        });
      }}
      onMouseOver={(e) => {
        handleHover(true, aircraftInfo);
      }}
      onMouseOut={(e) => {
        handleHover(false, aircraftInfo);
      }}
    >
      <div className="overlay-module-box">
        <div className="turnaround-flight-info">
          <div>
            <Arrival />
          </div>
          <div>
            <div className="value-pair">
              <label>{t("inbound")}</label>
              <div className="value">{inboundName}</div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>{t("arrival")}</label>
              <div className="value">{landingTime}</div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>
                {hasSnappedStand
                  ? t("current_stand")
                  : t("assigned_arr_stand_abbr")}
              </label>
              <div className="value">{arrivalStand}</div>
            </div>
          </div>
        </div>
        <div className="turnaround-info">
          <div>
            <Turnaround />
          </div>
          <div>
            <TurnaroundProgressBar
              turnaroundInfo={turnaroundInfo}
              turnaroundLevelOperationStatus={true}
              showOperationStatus={true}
            />
          </div>
        </div>

        {hasOutbound && (
          <div className="turnaround-flight-info">
            <div>
              <Departure />
            </div>
            <div>
              <div className="value-pair">
                <label>{t("outbound")}</label>
                <div className="value">{outboundName}</div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>{t("departure")}</label>
                <div className="value">{takeoffTime}</div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>{t("assigned_dep_stand_abbr")}</label>
                <div className="value">{departureStand}</div>
              </div>
            </div>
          </div>
        )}
        {showCriticalState && (
          <div className="overlay-module-actions">
            <button
              className="outlined"
              onClick={(e) => {
                handleNotCritical();
                e.stopPropagation();
              }}
            >
              {t("ack_critical")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TurnaroundInfoModule;
