import "./styles.css";
function TitleBadge(props) {
  const { value } = props;
  return (
    <div className="title-badge">
      <div>{value}</div>
    </div>
  );
}

export default TitleBadge;
