import { GroundVehicleStatus } from "./gseUtils";
import {
  filterList,
  getByFieldValue,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  scrollElementIntoView,
  sortByField,
} from "./utils";
import AgentProfilePic from "./assets/profile-pic-dark.svg";
import AgentEmptyAvailableSelected from "./assets/map/agent-empty-available-selected.svg";
import AgentEmptyAvailable from "./assets/map/agent-empty-available.svg";
// import AgentEmptyInServiceSelected from "./assets/map/agent-empty-inservice-selected.svg";
// import AgentEmptyInService from "./assets/map/agent-empty-inservice.svg";
// import PinpointArrowBlueSelected from "./assets/map/pinpoint-arrow-blue-selected.svg";
// import PinpointArrowBlue from "./assets/map/pinpoint-arrow-blue.svg";
// import PinpointArrowGreenSelected from "./assets/map/pinpoint-arrow-green-selected.svg";
// import PinpointArrowGreen from "./assets/map/pinpoint-arrow-green.svg";
import PinpointArrowRedSelected from "./assets/map/pinpoint-arrow-red-selected.svg";
import PinpointArrowRed from "./assets/map/pinpoint-arrow-red.svg";
import PinpointArrowYellowSelected from "./assets/map/pinpoint-arrow-yellow-selected.svg";
// import PinpointArrowYellow from "./assets/map/pinpoint-arrow-yellow.svg";
import PinpointArrowSelected from "./assets/map/pinpoint-arrow-selected.svg";
import PinpointArrow from "./assets/map/pinpoint-arrow.svg";
import PinpointSelected from "./assets/map/pinpoint-selected.svg";
import PinpointYellow from "./assets/map/pinpoint-yellow.svg";
import Pinpoint from "./assets/map/pinpoint.svg";
import Plane from "./assets/map/plane.svg";
import PlaneMedium from "./assets/map/plane-medium.svg";
import PlaneLarge from "./assets/map/plane-large.svg";
import PlaneSelected from "./assets/map/plane-selected.svg";
import PlaneMediumSelected from "./assets/map/plane-selected-medium.svg";
import PlaneLargeSelected from "./assets/map/plane-selected-large.svg";
import PlaneCircle from "./assets/map/plane-circle.svg";
import BatteryEmpty from "./assets/map/battery-empty.svg";
import BatteryPartial from "./assets/map/battery-partial.svg";
import moment from "moment-timezone";
import {
  TurnaroundPhaseType,
  getCurrentTurnaroundPhase,
  getTurnaroundInfoForTurnaroundSummary,
} from "./turnaroundUtils";
import { getActiveTurnaroundForUser } from "./userUtils";
import i18next from "./i18n";

// Icons
import { getSignedImageSrc } from "./mediaUtils";
import { OVERLAY_PANEL_MODES, SCROLL_STYLES } from "./constants";

// Earth radius in kilometers
const RADIUS_OF_EARTH = 6371;

export const MAPBOX_STANDARD_STYLE = "mapbox://styles/mapbox/standard";
export const MAPBOX_STYLE =
  "mapbox://styles/moonmapper/cloxllb7700q601qj462k2q76";

export const MAPINFO_STATUS = {
  UNKNOWN: 0,
  AVAILABLE: 1,
  OFFLINE: 2,
  IN_PROGRESS: 3,
  EN_ROUTE: 4,
  DELAYED: 5,
  COMPLETE: 6,
};

export const MAPINFO_USER_STATUS = {
  NONE: 0, // not on map
  AVAILABLE: 1, // on map, not currently in-service
  IN_SERVICE: 2, // on map, currently in-service
};

export const MAPINFO_STATUS_TEXT = {
  UNKNOWN: "Unknown",
  AVAILABLE: "Available",
  OFFLINE: "Offline",
  IN_PROGRESS: "In progress",
  EN_ROUTE: "En route",
  DELAYED: "Delayed",
  COMPLETE: "Complete",
};

export const MAPINFO_MARKER_TYPE = {
  ALL: "all",
  AIRPORT: "airport",
  BOUNDARY: "boundary",
  VEHICLE: "vehicle",
  AIRCRAFT: "aircraft",
  USER: "user",
};

export const MAPINFO_SUB_FILTER = {
  CRITICAL: "CRITICAL",
  ACTIVE: "ACTIVE",
};

export const MAPINFO_TURNAROUND_PHASE = {
  INBOUND: 0,
  TURNAROUND: 0,
  OUTBOUND: 0,
};

// NOTE: Disable this for now
export const MAP_SHOW_LABEL_ZOOM_LEVEL = 17;

export const MAP_ZOOM_LEVELS = {
  VEHICLE: 18.0,
  AIRCRAFT: 18.0,
  TERMINAL: 15.5,
  AIRPORT: 13.5,
  INFLIGHT: 5.5,
};

export const MAP_ZOOM_PRESETS = {
  OFF: -1,
  STATION: 2.5,
  APPROACH: 10,
  IN_RANGE: 100,
  EN_ROUTE: 500,
  MAX: 1000,
};

export const TIME_OF_DAY = {
  DAWN: "dawn",
  DAY: "day",
  DUSK: "dusk",
  NIGHT: "night",
};
export const TIME_OF_DAY_PRESETS = [
  TIME_OF_DAY.DAWN,
  TIME_OF_DAY.DAY,
  TIME_OF_DAY.DUSK,
  TIME_OF_DAY.NIGHT,
];

export const DEFAULT_MAP_FILTERS = {
  fitToBounds: true,
  mode: OVERLAY_PANEL_MODES.TURNAROUNDS,
  markerTypes: [MAPINFO_MARKER_TYPE.ALL],
  vehicleTypes: [MAPINFO_MARKER_TYPE.ALL],
  turnaroundSubFilter: MAPINFO_SUB_FILTER.CRITICAL,
};

export const FIT_MODES = {
  AIRPORT: "AIRPORT",
  POINTS: "POINTS",
};

export const MAP_ZOOM_PADDING = {
  top: 50,
  bottom: 50,
  left: 250,
  right: 100,
};

export function guessTimeOfDay(now) {
  // These are temp time of day ranges until we have an API
  // DAWN 5:00 AM
  const dawnStart = moment(now).hour(5).minute(0);
  // DAY 7:00 AM
  const dayStart = moment(now).hour(7).minute(0);
  // DUSK 8:00 PM
  const duskStart = moment(now).hour(20).minute(0);
  // NIGHT 9:00 PM
  const nightStart = moment(now).hour(21).minute(0);

  if (now.isBetween(dawnStart, dayStart)) {
    return TIME_OF_DAY.DAWN;
  } else if (now.isBetween(dayStart, duskStart)) {
    return TIME_OF_DAY.DAY;
  } else if (now.isBetween(duskStart, nightStart)) {
    return TIME_OF_DAY.DUSK;
  } else {
    return TIME_OF_DAY.NIGHT;
  }
}

export function getCriteriaForPositions(
  vehicles,
  flightsToRender,
  includeAircraftGeofence,
  includeRoutes
) {
  const trackerCodes = !isNullOrUndefined(vehicles)
    ? vehicles.map((item) => item.trackerCode)
    : [];
  const validFlights = filterList(
    flightsToRender,
    (item) => item?.registration
  );
  const registrations = !isNullOrUndefined(validFlights)
    ? validFlights.map((item) => item?.registration)
    : [];
  const criteria = {
    trackerCodes: trackerCodes,
    registrations: registrations,
  };
  if (includeAircraftGeofence) {
    criteria.includeAircraftGeofence = true;
  }
  if (includeRoutes) {
    criteria.includeAircraftPositionSequence = true;
    criteria.includeTrackerPositionSequence = true;
  }
  return criteria;
}

export function getPositionByTrackerCode(positions, trackerCode) {
  if (
    isNullOrUndefined(positions?.groundVehicles) ||
    isNullOrUndefined(trackerCode)
  )
    return null;
  return getByFieldValue(positions.groundVehicles, "trackerCode", trackerCode);
}

// Turnaround lookup by trackerCode
// Prefers: 1) was active for selectedTurnaround 2) turnarounds where the trackerCode is in-progress
// Returns an object with the associated turnaroundSummary and the context in-progress/complete
function getTurnaroundContextForGseTrackerCode(
  turnaroundsSummary,
  trackerCode,
  selectedTurnaroundId
) {
  if (isNullOrUndefined(turnaroundsSummary)) return null;
  let inProgressResult = null;
  let completedResult = null;
  for (let i = 0; i < turnaroundsSummary.length; i++) {
    const turnaroundToCheck = turnaroundsSummary[i];
    if (
      !isNullOrUndefined(selectedTurnaroundId) &&
      turnaroundToCheck.uuid === selectedTurnaroundId
    ) {
      if (turnaroundToCheck.inProgressTrackers.includes(trackerCode)) {
        return {
          turnaround: turnaroundToCheck,
          isInProgress: true,
        };
      } else if (turnaroundToCheck.completedTrackers.includes(trackerCode)) {
        return {
          turnaround: turnaroundToCheck,
          isComplete: true,
        };
      }
    } else if (
      !isNullOrUndefined(turnaroundToCheck.inProgressTrackers) &&
      turnaroundToCheck.inProgressTrackers.includes(trackerCode)
    ) {
      inProgressResult = {
        turnaround: turnaroundToCheck,
        isInProgress: true,
      };
    } else if (
      !isNullOrUndefined(turnaroundToCheck.completedTrackers) &&
      turnaroundToCheck.completedTrackers.includes(trackerCode)
    ) {
      completedResult = {
        turnaround: turnaroundToCheck,
        isComplete: true,
      };
    }
  }
  return !isNullOrUndefined(inProgressResult)
    ? inProgressResult
    : completedResult;
}

export function getVehicleInfoForMap(
  vehicle,
  position,
  turnaroundsSummary,
  selectedTurnaroundId
) {
  const vehicleInfo = { ...vehicle };
  const name = !isNullOrUndefined(vehicle.name) ? vehicle.name : "-";
  const type = !isNullOrUndefined(vehicle.gseType) ? vehicle.gseType.name : "-";
  const typeUuid = !isNullOrUndefined(vehicle.gseType)
    ? vehicle.gseType.uuid
    : null;

  const turnaroundContext = getTurnaroundContextForGseTrackerCode(
    turnaroundsSummary,
    vehicle.trackerCode,
    selectedTurnaroundId
  );
  const parentTurnaroundSummary = turnaroundContext?.turnaround;
  const turnaroundInfo = !isNullOrUndefined(parentTurnaroundSummary)
    ? getTurnaroundInfoForTurnaroundSummary(parentTurnaroundSummary)
    : null;

  vehicleInfo.mapInfo = {
    uuid: vehicle.uuid,
    markerType: "vehicle",
    name: name,
    type: type,
    typeUuid: typeUuid,
    status: MAPINFO_STATUS.AVAILABLE,
    statusText: "Available",
    position: { ...position },
    turnaroundInfo: turnaroundInfo,
    searchableName: `${name} ${type} ${position?.trackerCode}`,
    timestamp: position?.timestamp,
  };

  const isInProgress = turnaroundContext?.isInProgress;
  const isComplete = turnaroundContext?.isComplete;

  if (vehicle.statusId === GroundVehicleStatus.Offline) {
    vehicleInfo.mapInfo.status = MAPINFO_STATUS.OFFLINE;
    vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.OFFLINE;
  } else if (isComplete) {
    vehicleInfo.mapInfo.status = MAPINFO_STATUS.COMPLETE;
    vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.COMPLETE;
  } else if (position?.speedKmh > 0) {
    vehicleInfo.mapInfo.status = MAPINFO_STATUS.EN_ROUTE;
    vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.EN_ROUTE;
  } else if (isInProgress) {
    vehicleInfo.mapInfo.status = MAPINFO_STATUS.IN_PROGRESS;
    vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.IN_PROGRESS;
  } else if (vehicle.statusId === GroundVehicleStatus.Available) {
    vehicleInfo.mapInfo.status = MAPINFO_STATUS.AVAILABLE;
    vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.AVAILABLE;
  }
  return vehicleInfo;
}

export function getUserInfoForMap(
  user,
  position,
  turnarounds,
  selectedTurnaround
) {
  const activeTurnaround = getActiveTurnaroundForUser(turnarounds, user);
  let activeTurnaroundInfo = !isNullOrUndefined(activeTurnaround)
    ? getTurnaroundInfoForTurnaroundSummary(activeTurnaround)
    : null;
  if (
    isNullOrUndefined(activeTurnaroundInfo) &&
    !isNullOrUndefined(selectedTurnaround)
  ) {
    // If no active turnaround but there is a selectedTurnaround, then include this if it is associated
    if (!isNullOrUndefined(selectedTurnaround) && !isNullOrUndefined(user)) {
      // Check if user is in either an operation or unassigned bucket
      const turnaroundOperationForUser = getTurnaroundOperationForUser(
        selectedTurnaround,
        user.uuid
      );
      const unassignedUserRecord = isNullOrUndefined(turnaroundOperationForUser)
        ? getUnassignedUserRecordForUser(selectedTurnaround, user.uuid)
        : null;
      if (
        !isNullOrUndefined(turnaroundOperationForUser) ||
        !isNullOrUndefined(unassignedUserRecord)
      ) {
        activeTurnaroundInfo = selectedTurnaround;
      }
    }
  }
  const userInfo = { ...user };
  userInfo.mapInfo = {
    markerType: "user",
    position: { ...position },
    name: !isNullOrUndefined(user) ? `${user.firstName} ${user.lastName}` : "",
    turnaroundInfo: activeTurnaroundInfo,
  };
  return userInfo;
}

export function getAircraftInfoForMap(flight, position, turnaroundInfo) {
  const aircraftInfo = { ...flight };

  const flightTitle = `${
    !isBlank(turnaroundInfo?.inboundFlightName)
      ? turnaroundInfo.inboundFlightName
      : ""
  } - ${
    !isBlank(turnaroundInfo?.outboundFlightName)
      ? turnaroundInfo.outboundFlightName
      : ""
  }`;

  // Determine if the aircraft is Inbound/Turning/Outbound
  const aircraftTurnaroundPhase = getCurrentTurnaroundPhase(turnaroundInfo);
  aircraftInfo.mapInfo = {
    markerType: "aircraft",
    position: { ...position },
    name: `${flightTitle}`,
    inboundFlightName: turnaroundInfo?.inboundFlightName,
    outboundFlightName: turnaroundInfo?.outboundFlightName,
    combinedFlightName: turnaroundInfo?.combinedFlightName,
    stand: turnaroundInfo?.stand,
    aircraftTurnaroundPhase: aircraftTurnaroundPhase,
    turnaroundInfo: turnaroundInfo,
  };
  return aircraftInfo;
}

export function addOrUpdateMarker(mapboxgl, mapRef, options) {
  const {
    type,
    selected,
    markerInfo,
    existingMarker,
    clickHandler,
    mouseoverHandler,
    mouseoutHandler,
    isInSelectedTurnaroundContext,
  } = options;
  const mapInfo = markerInfo?.mapInfo;
  const hasExistingMarker = !isNullOrUndefined(existingMarker);
  const prevHovered =
    hasExistingMarker &&
    existingMarker.getElement().classList.contains("hovered");
  const markerClass = `marker-image-anchor${selected ? " selected" : ""}${
    prevHovered ? " hovered" : ""
  }`;

  const markerImageBoxEl = document.createElement("div");
  markerImageBoxEl.className = markerClass;
  markerImageBoxEl.setAttribute("data-asset-type", type);
  const markerUserPic = document.createElement("div");

  // Generate the proper icon image and content
  const markerImageEl = document.createElement("img");
  if (type === MAPINFO_MARKER_TYPE.AIRPORT) {
    markerImageEl.src = PlaneCircle;
  } else if (type === MAPINFO_MARKER_TYPE.BOUNDARY) {
    markerImageEl.src = Pinpoint;
  } else if (type === MAPINFO_MARKER_TYPE.VEHICLE) {
    if (mapInfo.status === MAPINFO_STATUS.IN_PROGRESS) {
      markerImageEl.src = selected
        ? PinpointArrowYellowSelected
        : PinpointYellow;
    } else if (
      mapInfo.status === MAPINFO_STATUS.COMPLETE ||
      mapInfo.status === MAPINFO_STATUS.EN_ROUTE ||
      mapInfo.status === MAPINFO_STATUS.AVAILABLE
    ) {
      markerImageEl.src = selected ? PinpointArrowSelected : PinpointArrow;
    } else if (mapInfo.status === MAPINFO_STATUS.OFFLINE) {
      markerImageEl.src = selected
        ? PinpointArrowRedSelected
        : PinpointArrowRed;
    } else {
      markerImageEl.src = selected ? PinpointSelected : Pinpoint;
    }
  } else if (type === MAPINFO_MARKER_TYPE.USER) {
    const profileImageBoxEl = document.createElement("div");
    // The main image is just an empty circle with status
    // Users on the map always appear as available (GREEN)
    markerImageEl.src = selected
      ? AgentEmptyAvailableSelected
      : AgentEmptyAvailable;
    const userProfilePic = getSignedImageSrc(
      markerInfo?.profilePicture?.thumbnail
    );

    // Create an element with the profile pic
    markerUserPic.className = "marker-user-pic";
    markerUserPic.style.position = "relative";
    markerUserPic.style.marginBottom = "2px";
    // Specify the profile image and the default fallback image
    profileImageBoxEl.style.backgroundImage = `url(${userProfilePic}), url(${AgentProfilePic})`;
    profileImageBoxEl.style.width = "18px";
    profileImageBoxEl.style.height = "18px";
    profileImageBoxEl.style.borderRadius = "18px";
    profileImageBoxEl.style.backgroundSize = "cover";
    profileImageBoxEl.style.backgroundPosition = "center";
    profileImageBoxEl.style.overflow = "hidden";

    // Additional styles to overlay the empty circle above the profile pic
    markerImageEl.style.position = "absolute";
    markerImageEl.style.width = "20px";
    markerImageEl.style.height = "20px";
    markerImageEl.style.top = "-1px";
    markerImageEl.style.left = "-1px";

    // Append user pic
    markerUserPic.appendChild(profileImageBoxEl);
  } else if (type === MAPINFO_MARKER_TYPE.AIRCRAFT) {
    const zoom = mapRef.current.getZoom();
    const showSelectedAircraft =
      isInSelectedTurnaroundContext || selected || prevHovered;
    if (zoom > MAP_ZOOM_LEVELS.AIRCRAFT) {
      markerImageEl.src = showSelectedAircraft
        ? PlaneLargeSelected
        : PlaneLarge;
      markerImageEl.setAttribute("data-default-icon", PlaneLarge);
      markerImageEl.setAttribute("data-selected-icon", PlaneLargeSelected);
    } else if (zoom > MAP_ZOOM_LEVELS.AIRPORT) {
      markerImageEl.src = showSelectedAircraft
        ? PlaneMediumSelected
        : PlaneMedium;
      markerImageEl.setAttribute("data-default-icon", PlaneMedium);
      markerImageEl.setAttribute("data-selected-icon", PlaneMediumSelected);
    } else {
      markerImageEl.src = showSelectedAircraft ? PlaneSelected : Plane;
      markerImageEl.setAttribute("data-default-icon", Plane);
      markerImageEl.setAttribute("data-selected-icon", PlaneSelected);
    }
    markerImageEl.className = "aircraft-icon";
    markerImageEl.alt = `${markerInfo.registration} ${mapInfo.combinedFlightName} (lng: ${mapInfo.position.longitude} lat:${mapInfo.position.latitude})`;
    const aircraftLat = mapInfo.position.latitude;
    const wingspanInPixels = getWingspanInPixels(zoom, aircraftLat);
    markerImageEl.style.width = `${wingspanInPixels}px`;
    markerImageEl.style.height = `${wingspanInPixels}px`;

    // Aircraft has custom rotation handling
    const adjustedRotation = getAdjustedRotation(mapRef, mapInfo);
    markerImageEl.style.transform = `rotate(${adjustedRotation}deg) translate(0, ${
      wingspanInPixels / 2
    }px)`;
  }
  if (
    type !== MAPINFO_MARKER_TYPE.AIRCRAFT &&
    !isNullOrUndefined(mapInfo?.position?.heading)
  ) {
    const adjustedRotation = getAdjustedRotation(mapRef, mapInfo);
    markerImageEl.style.transform = `rotate(${adjustedRotation}deg)`;
  }
  if (!isNullOrUndefined(markerUserPic)) {
    markerImageBoxEl.appendChild(markerUserPic);
  }
  markerImageBoxEl.appendChild(markerImageEl);
  const labelId =
    type === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? markerInfo.registration
      : markerInfo.uuid;

  let isHoverable = !selected;
  if (
    type === MAPINFO_MARKER_TYPE.AIRCRAFT &&
    (isInSelectedTurnaroundContext || selected)
  ) {
    // Do not enable hover on the aircraft when it is selected or is the selected turnaround
    isHoverable = false;
  }

  if (isHoverable) {
    markerImageEl.classList.add("hoverable");
  }
  markerImageBoxEl.setAttribute("data-anchor-id", labelId);

  // Mouse event handlers
  if (isHoverable) {
    markerImageEl.addEventListener("mouseover", (e) => {
      if (!isNullOrUndefined(mouseoverHandler)) mouseoverHandler();
    });
    markerImageEl.addEventListener("mouseout", (e) => {
      if (!isNullOrUndefined(mouseoutHandler)) mouseoutHandler();
    });
  }

  // Click handlers
  markerImageEl.addEventListener("click", (e) => {
    clickHandler();
    e.stopPropagation();
  });
  if (hasExistingMarker) {
    existingMarker.remove();
  }

  const marker = new mapboxgl.Marker(markerImageBoxEl)
    .setLngLat([mapInfo.position.longitude, mapInfo.position.latitude])
    .setPitchAlignment("map");
  marker.addTo(mapRef.current);
  return marker;
}

export function clearHoverStates() {
  const hoveredEls = document.querySelectorAll(".hovered");
  if (!isEmptyList(hoveredEls)) {
    hoveredEls.forEach((el) => {
      el.classList.remove("hovered");
    });
  }
}

// Handle hover events
export function handleHover(isHovered, markerInfo) {
  const type = markerInfo.mapInfo.markerType;
  const labelId =
    type === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? markerInfo.registration
      : markerInfo.uuid;
  let anchorEl = document.querySelector(`[data-anchor-id='${labelId}']`);
  let labelEl = document.querySelector(`[data-label-id='${labelId}']`);
  let tileEl =
    type === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? document.querySelector(`[data-registration='${labelId}']`)
      : document.querySelector(`[data-uuid='${labelId}']`);
  let labelElMarker = !isNullOrUndefined(labelEl)
    ? labelEl.closest(".marker-label-anchor")
    : null;

  const isSelected = anchorEl?.classList.contains("selected");

  if (!isNullOrUndefined(labelElMarker)) {
    if (isHovered) {
      labelElMarker.classList.add("hovered");
    } else {
      clearHoverStates();
    }
  }
  if (!isNullOrUndefined(anchorEl)) {
    if (isHovered) {
      anchorEl.classList.add("hovered");
    } else {
      clearHoverStates();
    }
    if (type === MAPINFO_MARKER_TYPE.AIRCRAFT && !isSelected) {
      // swap the icon image as well
      const imgEl = anchorEl.querySelector("img.aircraft-icon");
      if (isHovered) {
        imgEl.src = imgEl.getAttribute("data-selected-icon");
      } else {
        imgEl.src = imgEl.getAttribute("data-default-icon");
      }
    }
  }
  if (tileEl) {
    if (isHovered) {
      tileEl.classList.add("hovered");
      scrollElementIntoView(tileEl.parentNode, tileEl, SCROLL_STYLES.INSTANT);
    } else {
      clearHoverStates();
    }
  }
}

// Use only for Users and Vehicles marker labels
export function addOrUpdateMarkerLabel(mapboxgl, mapRef, options) {
  const {
    type,
    selected,
    markerInfo,
    existingMarker,
    clickHandler,
    mouseoverHandler,
    mouseoutHandler,
  } = options;
  const mapInfo = markerInfo?.mapInfo;
  const hasExistingMarker = !isNullOrUndefined(existingMarker);
  const prevHovered =
    hasExistingMarker &&
    existingMarker.getElement().classList.contains("hovered");
  const markerEl = document.createElement("div");
  const markerClass = `marker${selected ? " selected" : ""}${
    prevHovered ? " hovered" : ""
  }`;
  markerEl.className = markerClass;
  const hasTurnaroundContext = !isNullOrUndefined(mapInfo.turnaroundInfo);

  const markerLabelBoxEl = document.createElement("div");

  markerLabelBoxEl.className = "marker-label";

  const markerLabelInnerBoxEl = document.createElement("div");

  if (!isBlank(mapInfo.name)) {
    const markerLabelEl = document.createElement("span");
    markerLabelEl.className = "marker-label-name";
    markerLabelEl.textContent = mapInfo.name;
    //
    markerLabelInnerBoxEl.appendChild(markerLabelEl);

    if (type === MAPINFO_MARKER_TYPE.VEHICLE) {
      markerLabelBoxEl.setAttribute("data-label-id", markerInfo.uuid);
      // Check battery levels and show status if under threshold
      const hasPower = mapInfo?.position?.hasPower;
      const batteryAmt = Math.floor(mapInfo?.position?.battery);
      if (batteryAmt < 20 && !hasPower) {
        const batteryContainerEl = document.createElement("div");
        batteryContainerEl.className = "marker-label-battery";
        const batteryEl = document.createElement("div");
        batteryEl.className = "battery-status";
        const batteryInnerEl = document.createElement("div");
        const batteryInnerLeftEl = document.createElement("div");
        const batteryIconEl = document.createElement("img");
        batteryIconEl.src = batteryAmt < 5 ? BatteryEmpty : BatteryPartial;
        batteryInnerLeftEl.appendChild(batteryIconEl);
        const batteryInnerRightEl = document.createElement("div");
        batteryInnerRightEl.textContent = `${batteryAmt}%`;
        batteryInnerEl.appendChild(batteryInnerLeftEl);
        batteryInnerEl.appendChild(batteryInnerRightEl);
        batteryEl.appendChild(batteryInnerEl);
        batteryContainerEl.appendChild(batteryEl);
        markerLabelInnerBoxEl.appendChild(batteryContainerEl);
      }
    } else if (type === MAPINFO_MARKER_TYPE.USER) {
      markerLabelBoxEl.setAttribute("data-label-id", markerInfo.uuid);

      if (hasTurnaroundContext && !isNullOrUndefined(mapInfo.operation)) {
        const markerStatusEl = document.createElement("span");
        markerStatusEl.className = `marker-label-status`;
        markerStatusEl.innerText = mapInfo.operation.name;
        markerLabelInnerBoxEl.appendChild(markerStatusEl);
      }
    }
  }
  if (!isBlank(mapInfo.type)) {
    const markerLabelEl = document.createElement("span");
    markerLabelEl.className = "marker-label-type";
    markerLabelEl.textContent = mapInfo.type;
    markerLabelInnerBoxEl.appendChild(markerLabelEl);
  }
  markerLabelBoxEl.appendChild(markerLabelInnerBoxEl);
  markerEl.appendChild(markerLabelBoxEl);

  // Add vertical adjustment so that it is always below the icon
  if (type === MAPINFO_MARKER_TYPE.VEHICLE) {
    markerLabelBoxEl.style.transform = `translateY(25%)`;
  } else {
    markerLabelBoxEl.style.transform = `translateY(75%)`;
  }

  // Click handlers
  markerLabelBoxEl.addEventListener("click", (e) => {
    clickHandler();
    e.stopPropagation();
  });

  // Mouse event handlers
  markerLabelBoxEl.addEventListener("mouseover", (e) => {
    if (!isNullOrUndefined(mouseoverHandler)) mouseoverHandler();
  });
  markerLabelBoxEl.addEventListener("mouseout", (e) => {
    if (!isNullOrUndefined(mouseoutHandler)) mouseoutHandler();
  });

  // Additional wrapper to use as an anchor point
  // The element is 0px by 0px and the actual content overflows outside
  const markerContainer = document.createElement("div");
  markerContainer.className = `marker-label-anchor${
    selected ? " selected" : ""
  }${prevHovered ? " hovered" : ""}`;
  markerContainer.appendChild(markerEl);

  const marker = new mapboxgl.Marker(markerContainer)
    .setLngLat([mapInfo.position.longitude, mapInfo.position.latitude])
    .setPitchAlignment("map");

  marker.addTo(mapRef.current);
  if (hasExistingMarker) {
    existingMarker.remove();
  }

  return marker;
}

export function addGeofenceMarkers(mapboxgl, mapRef, options) {
  const { markerInfo } = options;
  const mapInfo = markerInfo?.mapInfo;
  const markers = [];

  if (!isNullOrUndefined(mapInfo.position.geofencePoints)) {
    const zoom = mapRef.current.getZoom();
    const aircraftLat = mapInfo.position.latitude;
    // show the geofence of the aircraft
    const geofencePointInPixels = getGeofencePointInPixels(zoom, aircraftLat);

    for (let i = 0; i < mapInfo.position.geofencePoints.length; i++) {
      const geofencePoint = mapInfo.position.geofencePoints[i];
      const gfMarkerEl = document.createElement("div");
      gfMarkerEl.className = "marker-geofence";
      gfMarkerEl.style.width = `${geofencePointInPixels}px`;
      gfMarkerEl.style.height = `${geofencePointInPixels}px`;
      if (
        !isNullOrUndefined(geofencePoint.longitude) &&
        !isNullOrUndefined(geofencePoint.latitude)
      ) {
        const marker = new mapboxgl.Marker(gfMarkerEl)
          .setLngLat([geofencePoint.longitude, geofencePoint.latitude])
          .setPitchAlignment("map");
        markers.push(marker);
        marker.addTo(mapRef.current);
      }
    }
    const gfMarkerEl = document.createElement("div");
    gfMarkerEl.className = "marker-geofence aircraft";
    gfMarkerEl.style.width = "8px";
    gfMarkerEl.style.height = "8px";
    const marker = new mapboxgl.Marker(gfMarkerEl)
      .setLngLat([mapInfo.position.longitude, mapInfo.position.latitude])
      .setPitchAlignment("map");
    markers.push(marker);
    marker.addTo(mapRef.current);
  }
  return markers;
}
export function addAircraftLabelMarker(mapboxgl, mapRef, options) {
  const { markerInfo, selected, clickHandler, existingMarker } = options;
  const prevHovered =
    !isNullOrUndefined(existingMarker) &&
    existingMarker.getElement().classList.contains("hovered");
  const mapInfo = markerInfo?.mapInfo;
  const markerEl = document.createElement("div");
  const markerClass = selected ? "marker selected" : "marker";
  markerEl.className = markerClass;
  const markerLabelBoxEl = document.createElement("div");
  markerLabelBoxEl.setAttribute("data-label-id", markerInfo.registration);
  // Aircraft has a split label when there is a stand
  // Use the snappedStand as it will get updated when the aircraft ungates
  const showStandLabel =
    mapInfo.aircraftTurnaroundPhase === MAPINFO_TURNAROUND_PHASE.TURNAROUND &&
    !isBlank(mapInfo?.turnaroundInfo?.snappedStand?.name);
  if (showStandLabel) {
    const turnaroundLevelOperationStatus =
      mapInfo?.turnaroundInfo?.turnaroundLevelOperationStatus;
    markerLabelBoxEl.className = `marker-label split${
      !isNullOrUndefined(turnaroundLevelOperationStatus?.primaryStatus)
        ? ` status-${turnaroundLevelOperationStatus?.primaryStatus}`
        : ""
    }`;

    const markerLabelLeftEl = document.createElement("div");
    const markerLabelLeftBoxEl = document.createElement("div");
    const markerLabelLeftUpperEl = document.createElement("span");
    markerLabelLeftUpperEl.textContent = i18next.t("stand_non_cap");
    const markerLabelLeftLowerEl = document.createElement("span");
    markerLabelLeftLowerEl.textContent =
      mapInfo.turnaroundInfo.snappedStand.name;
    markerLabelLeftBoxEl.appendChild(markerLabelLeftUpperEl);
    markerLabelLeftBoxEl.appendChild(markerLabelLeftLowerEl);
    markerLabelLeftEl.appendChild(markerLabelLeftBoxEl);
    markerLabelBoxEl.appendChild(markerLabelLeftEl);
  } else {
    markerLabelBoxEl.className = "marker-label";
  }
  const markerLabelMainEl = document.createElement("div");
  const markerLabelMainBoxEl = document.createElement("div");
  const turnaroundPhaseType = getCurrentTurnaroundPhase(
    mapInfo?.turnaroundInfo
  );

  // Show flight names based on the turnaround phase
  let showInbound = false;
  let showOutbound = false;
  if (turnaroundPhaseType === TurnaroundPhaseType.INBOUND) {
    showInbound = !isBlank(mapInfo.inboundFlightName);
  } else if (turnaroundPhaseType === TurnaroundPhaseType.TURNAROUND) {
    showInbound = !isBlank(mapInfo.inboundFlightName);
    showOutbound = !isBlank(mapInfo.outboundFlightName);
  } else if (turnaroundPhaseType === TurnaroundPhaseType.OUTBOUND) {
    showOutbound = !isBlank(mapInfo.outboundFlightName);
  }
  if (showInbound) {
    const markerLabelTextEl = document.createElement("span");
    markerLabelTextEl.textContent = `${mapInfo.inboundFlightName}`;
    markerLabelMainBoxEl.appendChild(markerLabelTextEl);
  }
  if (showOutbound) {
    const markerLabelTextEl = document.createElement("span");
    markerLabelTextEl.textContent = `${mapInfo.outboundFlightName}`;
    markerLabelMainBoxEl.appendChild(markerLabelTextEl);
  }
  markerLabelMainEl.appendChild(markerLabelMainBoxEl);
  markerLabelBoxEl.appendChild(markerLabelMainEl);

  // Plane is rotated upwards so adjust label translation
  const zoom = mapRef.current.getZoom();
  const aircraftLat = mapInfo.position.latitude;
  const adjustedRotation = getAdjustedRotation(mapRef, mapInfo);
  const isRotationHorizontal =
    (adjustedRotation > 45 && adjustedRotation < 135) ||
    (adjustedRotation > 225 && adjustedRotation < 315);
  const isRotationUp = adjustedRotation >= 135 && adjustedRotation <= 225;
  const wingspanInPixels = getWingspanInPixels(zoom, aircraftLat);
  const aircraftCenterOffset = getAircraftCenterOffset(
    adjustedRotation,
    wingspanInPixels
  );
  // Add vertical adjustment so that it is always below the aircraft icon
  let adjustmentFactor = zoom > 19 ? 0.65 : zoom > 17 ? 0.6 : 0.55;

  let verticalAdj = isRotationHorizontal
    ? wingspanInPixels * adjustmentFactor
    : isRotationUp
    ? wingspanInPixels
    : wingspanInPixels * adjustmentFactor;

  markerLabelBoxEl.style.transform = `translate(${aircraftCenterOffset.x}px, ${
    aircraftCenterOffset.y * -1 + verticalAdj
  }px)`;

  // Click handlers
  markerLabelBoxEl.addEventListener("click", (e) => {
    clickHandler();
    e.stopPropagation();
  });
  markerEl.appendChild(markerLabelBoxEl);

  // Additional wrapper to use as an anchor point
  // The element is 0px by 0px and the actual content overflows outside
  const markerContainer = document.createElement("div");
  const markerContainerClass = `marker-label-anchor${
    selected ? " selected" : ""
  }${prevHovered ? " hovered" : ""}`;
  markerContainer.className = markerContainerClass;
  markerContainer.appendChild(markerEl);

  const marker = new mapboxgl.Marker(markerContainer)
    .setLngLat([mapInfo.position.longitude, mapInfo.position.latitude])
    .setPitchAlignment("map");

  if (!isNullOrUndefined(existingMarker)) {
    existingMarker.remove();
  }
  marker.addTo(mapRef.current);
  return marker;
}

export function addPinMarker(mapboxgl, mapRef, options) {
  const pinMarkerEl = document.createElement("div");
  let markerClassName = "marker-pin";
  pinMarkerEl.className = markerClassName;
  const pinMarkerValueEl = document.createElement("div");

  pinMarkerEl.appendChild(pinMarkerValueEl);
  const pinMarker = new mapboxgl.Marker(pinMarkerEl)
    .setLngLat([options.longitude, options.latitude])
    .setPitchAlignment("map");

  pinMarker.addTo(mapRef.current);
  return pinMarker;
}

export function addAirportMarker(mapboxgl, mapRef, options) {
  const { airport } = options;
  const airportLat = !isNullOrUndefined(airport)
    ? (airport.northLatitude + airport.southLatitude) / 2
    : null;
  const airportLng = !isNullOrUndefined(airport)
    ? (airport.westLongitude + airport.eastLongitude) / 2
    : null;
  const airportCenter = [airportLng, airportLat];

  const markerEl = document.createElement("div");
  let markerClassName = "marker-airport"; // pulse?
  markerEl.className = markerClassName;
  const markerValueEl = document.createElement("div");
  markerValueEl.textContent = airport.iata;
  markerEl.appendChild(markerValueEl);
  const marker = new mapboxgl.Marker(markerEl)
    .setLngLat(airportCenter)
    .setPitchAlignment("map");

  marker.addTo(mapRef.current);

  // Stands
  if (airport?.stands?.length > 0) {
    for (let i = 0; i < airport.stands.length; i++) {
      const stand = airport.stands[i];
      const standMarkerEl = document.createElement("div");
      let markerClassName = "marker-airport-stand";
      standMarkerEl.className = markerClassName;
      const standMarkerValueEl = document.createElement("div");
      standMarkerValueEl.textContent = stand.name;
      standMarkerEl.appendChild(standMarkerValueEl);
      const standMarker = new mapboxgl.Marker(standMarkerEl)
        .setLngLat([stand.longitude, stand.latitude])
        .setPitchAlignment("map");

      standMarker.addTo(mapRef.current);
    }
  }
  return marker;
}

export function addClusterMarker(mapboxgl, mapRef, options) {
  const { count, coordinates, clickHandler } = options;
  const markerEl = document.createElement("div");
  let markerClassName = "marker-cluster";
  if (count < 5) {
    markerClassName = `${markerClassName} small`;
  } else if (count < 12) {
    markerClassName = `${markerClassName} medium`;
  } else {
    markerClassName = `${markerClassName} large`;
  }
  markerEl.className = markerClassName;
  const markerValueEl = document.createElement("div");
  markerValueEl.textContent = count;
  markerEl.appendChild(markerValueEl);
  // Click handlers
  markerEl.addEventListener("click", (e) => {
    clickHandler();
  });

  const marker = new mapboxgl.Marker(markerEl)
    .setLngLat(coordinates)
    .setPitchAlignment("map");

  marker.addTo(mapRef.current);
  return marker;
}

export function getVehicleByTrackerId(vehicles, trackerCode) {
  if (isNullOrUndefined(vehicles)) return null;
  for (let i = 0; i < vehicles.length; i++) {
    const vehicle = vehicles[i];
    if (vehicle.trackerCode === trackerCode) {
      return vehicle;
    }
  }
  return null;
}

export function getUserByUuid(users, uuid) {
  if (isNullOrUndefined(users)) return null;
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    if (user.uuid === uuid) {
      return user;
    }
  }
  return null;
}

export function getFlightByRegistration(flightsToRender, registration) {
  if (isNullOrUndefined(flightsToRender)) return null;
  for (let i = 0; i < flightsToRender.length; i++) {
    const flight = flightsToRender[i];
    if (flight?.registration === registration) {
      return flight;
    }
  }
  return null;
}

// Finds which operation the Gse is in
export function getTurnaroundOperationForGse(turnaroundInfo, gseUuid) {
  for (let i = 0; i < turnaroundInfo?.operations?.length; i++) {
    const operation = turnaroundInfo.operations[i];
    const gsesForOperation = getGsesForOperation(operation);
    if (!isNullOrUndefined(gsesForOperation[gseUuid])) {
      // Gse has a service record for this operation
      return operation;
    }
  }
  return null;
}

function getGsesForOperation(operation) {
  const gseRecordsByGseUuid = {};
  const gseRecords = operation?.gseRecords;
  if (!isNullOrUndefined(gseRecords)) {
    for (let i = 0; i < gseRecords.length; i++) {
      const gseRecord = gseRecords[i];
      const gseUuid = gseRecord.groundVehicle?.uuid;
      if (!isNullOrUndefined(gseUuid)) {
        gseRecordsByGseUuid[gseUuid] = gseRecord;
      }
    }
  }
  return gseRecordsByGseUuid;
}

// Finds the FIRST operation the User is in
// Checks in the user records or assignments
// NOTE: Maybe add some preferential treatment of In-progress/Completed/Assigned operations
export function getTurnaroundOperationForUser(turnaroundInfo, userUuid) {
  for (let i = 0; i < turnaroundInfo?.operations?.length; i++) {
    const operation = turnaroundInfo.operations[i];
    const userRecordsForOperation = operation.userRecords;

    // Check for an existing user record for this user
    const userRecordForUser = !isEmptyList(userRecordsForOperation)
      ? getByFieldValue(userRecordsForOperation, "user.uuid", userUuid)
      : null;
    if (!isNullOrUndefined(userRecordForUser)) {
      return operation; // Found
    }

    // Check for an existing crew assignment for this user
    const operationUserAssignments = getCrewAssignmentsFromRequirements(
      operation.turnaroundRequirements
    );
    const crewAssignmentForUser = getByFieldValue(
      operationUserAssignments,
      "userUuid",
      userUuid
    );
    if (!isNullOrUndefined(crewAssignmentForUser)) {
      return operation; // Found
    }
  }
  return null;
}

// Gets all the crew assignments for the given requirement
function getCrewAssignmentsFromRequirements(turnaroundRequirements) {
  const allCrewAssignments = [];
  if (!isEmptyList(turnaroundRequirements)) {
    for (let i = 0; i < turnaroundRequirements.length; i++) {
      const requirement = turnaroundRequirements[i];
      allCrewAssignments.push.apply(
        allCrewAssignments,
        requirement.crewAssignments
      );
    }
  }
  return allCrewAssignments;
}

// If the user has an unassigned user record in the given turnaround
export function getUnassignedUserRecordForUser(turnaroundInfo, userUuid) {
  if (!isEmptyList(turnaroundInfo?.originalMonitor?.unassignedUserRecords)) {
    const userRecord = getByFieldValue(
      turnaroundInfo?.originalMonitor?.unassignedUserRecords,
      "user.uuid",
      userUuid
    );
    if (!isNullOrUndefined(userRecord)) {
      return userRecord;
    }
  }
  return null;
}

// Locate a turnaround that has this aircraft in either inbound or outbound
// Prefer turnarounds if they have both the inbound and outbound
// NOTE: This has been refactored to use turnaroundsSummary instead of turnarounds
export function getTurnaroundInfoByRegistration(turnarounds, registration) {
  if (isNullOrUndefined(turnarounds)) return null;
  let turnaroundForRegistration = null;
  for (let i = 0; i < turnarounds.length; i++) {
    const turnaround = turnarounds[i];
    if (registration === turnaround?.registration) {
      turnaroundForRegistration =
        getTurnaroundInfoForTurnaroundSummary(turnaround);
      if (
        !isNullOrUndefined(turnaround?.outboundFlightSummary) &&
        !isNullOrUndefined(turnaround?.inboundFlightSummary)
      ) {
        // Found turnaround that has both inbound/outbound, return immediately
        return turnaroundForRegistration;
      }
    }
  }
  return turnaroundForRegistration;
}
export function getMilesInPixels(zoomValue, latitude, distanceInMiles) {
  const size = distanceInMiles * 1609.34; // convert miles to meters
  const metersPerPixel = getMetersPerPixel(zoomValue, latitude);
  return size / metersPerPixel;
}

export function getWingspanInPixels(zoomValue, latitude) {
  const wingpsanInMeters = 50.0; // hardcode this for now
  return getMarkerInPixels(wingpsanInMeters, zoomValue, latitude);
}
export function getGeofencePointInPixels(zoomValue, latitude) {
  const geofencePointInMeters = 30.0; // hardcode this for now
  return getMarkerInPixels(geofencePointInMeters, zoomValue, latitude);
}
export function getMarkerInPixels(size, zoomValue, latitude) {
  const metersPerPixel = getMetersPerPixel(zoomValue, latitude);
  const widthInPixels = size / metersPerPixel;
  return widthInPixels <= 25 ? 25 : widthInPixels;
}

function getMetersPerPixel(zoomValue, latitude) {
  return (
    (78271.484 / Math.pow(2, zoomValue)) *
    Math.cos(latitude * (3.14159 / 180.0))
  );
}

function getAdjustedRotation(mapRef, mapInfo) {
  const mapBearing = !isNullOrUndefined(mapRef.current)
    ? mapRef.current.getBearing()
    : 0;
  return mapInfo?.position?.heading
    ? mapInfo?.position?.heading - mapBearing
    : 0;
}

export function getVehicleTypesFromVehicles(vehicles) {
  const result = [];
  if (!isNullOrUndefined(vehicles)) {
    const vehicleTypes = {};
    for (let i = 0; i < vehicles.length; i++) {
      const vehicle = vehicles[i];
      const gseType = vehicle.gseType;
      if (isNullOrUndefined(vehicleTypes[gseType.uuid])) {
        vehicleTypes[gseType.uuid] = gseType;
        result.push(gseType);
      }
    }
    sortByField(result, "name");
  }
  return result;
}

export function getAircraftCenterOffset(bearing, wingspanInPixels) {
  const radius = wingspanInPixels / 2;
  const offset = {
    x: 0,
    y: -radius,
  };
  if (bearing > 0 && bearing <= 45) {
    const sides = getOppositeAdjacentSides(bearing, radius);
    offset.x = -1 * sides.opposite;
    offset.y = -1 * sides.adjacent;
  } else if (bearing > 45 && bearing < 90) {
    const sides = getOppositeAdjacentSides(90 - bearing, radius);
    offset.x = -1 * sides.adjacent;
    offset.y = -1 * sides.opposite;
  } else if (bearing === 90) {
    offset.x = -radius;
    offset.y = 0;
  } else if (bearing > 90 && bearing <= 135) {
    const sides = getOppositeAdjacentSides(bearing - 90, radius);
    offset.x = -1 * sides.adjacent;
    offset.y = sides.opposite;
  } else if (bearing > 135 && bearing < 180) {
    const sides = getOppositeAdjacentSides(180 - bearing, radius);
    offset.x = -1 * sides.opposite;
    offset.y = sides.adjacent;
  } else if (bearing === 180) {
    offset.x = 0;
    offset.y = radius;
  } else if (bearing > 180 && bearing <= 225) {
    const sides = getOppositeAdjacentSides(bearing - 180, radius);
    offset.x = sides.opposite;
    offset.y = sides.adjacent;
  } else if (bearing > 225 && bearing < 270) {
    const sides = getOppositeAdjacentSides(270 - bearing, radius);
    offset.x = sides.adjacent;
    offset.y = sides.opposite;
  } else if (bearing === 270) {
    offset.x = radius;
    offset.y = 0;
  } else if (bearing > 270 && bearing <= 315) {
    const sides = getOppositeAdjacentSides(bearing - 270, radius);
    offset.x = sides.adjacent;
    offset.y = -1 * sides.opposite;
  } else if (bearing > 315 && bearing < 360) {
    const sides = getOppositeAdjacentSides(360 - bearing, radius);
    offset.x = sides.opposite;
    offset.y = -1 * sides.adjacent;
  }
  return offset;
}

export function getOppositeAdjacentSides(angle, hypotenuse) {
  const opposite = Math.sin((angle * Math.PI) / 180) * hypotenuse;
  const adjacent = opposite / Math.tan((angle * Math.PI) / 180);
  return {
    opposite: opposite,
    adjacent: adjacent,
  };
}

export function getSourceForRoute(coordinates) {
  return {
    type: "geojson",
    data: getSourceDataForRoute(coordinates),
    lineMetrics: true,
  };
}
export function getSourceDataForRoute(coordinates) {
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: coordinates.map((i) => [i.longitude, i.latitude]),
    },
  };
}

export function getLayerForRoute(layerId, sourceId) {
  return {
    id: layerId,
    type: "line",
    source: sourceId,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-opacity": 1,
      "line-color": "rgb(23, 121, 218)",
      "line-width": 5,
      "line-gradient": [
        "interpolate",
        ["linear"],
        ["line-progress"],
        0,
        "rgba(23, 121, 218, .5)",
        0.5,
        "rgba(23, 121, 218, .75)",
        1,
        "rgba(23, 121, 218, 1)",
      ],
      "line-emissive-strength": 0.8,
    },
  };
}

export function getLayerForTargetRoute(layerId, sourceId) {
  return {
    id: layerId,
    type: "line",
    source: sourceId,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-opacity": 1,
      "line-color": "rgba(23, 121, 218, .75)",
      "line-width": 3,
      "line-dasharray": [1, 2],
      "line-emissive-strength": 0.8,
    },
  };
}

export function getPositionSequenceForMarker(positions, markerType, id) {
  const positionsList =
    markerType === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? positions?.aircrafts
      : markerType === MAPINFO_MARKER_TYPE.VEHICLE
      ? positions?.groundVehicles
      : null;
  const idField =
    markerType === MAPINFO_MARKER_TYPE.AIRCRAFT
      ? "registration"
      : markerType === MAPINFO_MARKER_TYPE.VEHICLE
      ? "trackerCode"
      : null;

  const position = getByFieldValue(positionsList, idField, id);
  const positionSequence = position?.positionSequence;
  if (!isNullOrUndefined(positionSequence) && !isNullOrUndefined(position)) {
    // add current position
    positionSequence.push({
      longitude: position.longitude,
      latitude: position.latitude,
      timestamp: Math.round(new Date().getTime() / 1000), // Current timestamp
      isCurrent: true,
    });
  }
  return positionSequence;
}

export function toMeters(miles) {
  return miles * 1609.34;
}

export function getBoundingBoxCoordinates(coordinatePair, distanceInMiles) {
  const distanceInMeters = toMeters(distanceInMiles);
  const lng = coordinatePair[0];
  const lat = coordinatePair[1];

  // North=0, East=90, South=180 West=270
  const north = calculateDestinationPoint(lat, lng, distanceInMeters, 0);
  const east = calculateDestinationPoint(lat, lng, distanceInMeters, 90);
  const south = calculateDestinationPoint(lat, lng, distanceInMeters, 180);
  const west = calculateDestinationPoint(lat, lng, distanceInMeters, 270);

  // [lng, lat]
  const bbTopRight = [east.longitude, north.latitude];
  const bbBottomLeft = [west.longitude, south.latitude];
  return [bbTopRight, bbBottomLeft];
}

// Function to calculate the destination point given distance and bearing
export function calculateDestinationPoint(lat, lng, distance, bearing) {
  // Earth radius in meters
  const R = RADIUS_OF_EARTH * 1000;

  // Convert angles to radians
  const lat1 = toRadians(lat);
  const lng1 = toRadians(lng);
  const brng = toRadians(bearing);

  // Calculate destination point
  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(distance / R) +
      Math.cos(lat1) * Math.sin(distance / R) * Math.cos(brng)
  );
  const lng2 =
    lng1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(distance / R) * Math.cos(lat1),
      Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2)
    );

  // Convert back to degrees
  const newLat = toDegrees(lat2);
  const newLng = toDegrees(lng2);

  return { latitude: newLat, longitude: newLng };
}

// Function to convert degrees to radians
function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

// Function to convert radians to degrees
function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

function getDistance(pointA, pointB) {
  // Convert latitude and longitude to radians
  const lat1 = toRadians(pointA[1]);
  const lat2 = toRadians(pointB[1]);
  const latDelta = toRadians(pointB[1] - pointA[1]);
  const lngDelta = toRadians(pointB[0] - pointA[0]);

  // Haversine formula
  const a =
    Math.sin(latDelta / 2) * Math.sin(latDelta / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(lngDelta / 2) *
      Math.sin(lngDelta / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = RADIUS_OF_EARTH * c;
  return distance;
}

export function getDistanceInMiles(pointA, pointB) {
  return getDistance(pointA, pointB) * 0.621371;
}

export function getDistanceInMeters(pointA, pointB) {
  return getDistance(pointA, pointB) * 1000;
}

export function isWithinAirportBounds(airport, position) {
  if (
    !isNullOrUndefined(airport?.westLongitude) &&
    !isNullOrUndefined(airport?.eastLongitude) &&
    !isNullOrUndefined(airport?.northLatitude) &&
    !isNullOrUndefined(airport?.southLatitude) &&
    !isNullOrUndefined(position)
  ) {
    const airportLat = !isNullOrUndefined(airport)
      ? (airport.northLatitude + airport.southLatitude) / 2
      : null;
    const airportLng = !isNullOrUndefined(airport)
      ? (airport.westLongitude + airport.eastLongitude) / 2
      : null;
    const airportCenter = [airportLng, airportLat];
    const bbox = getBoundingBoxCoordinates(
      airportCenter,
      MAP_ZOOM_PRESETS.STATION
    );
    const [bbTopRight, bbBottomLeft] = bbox;
    return (
      position.latitude <= bbTopRight[1] &&
      position.latitude >= bbBottomLeft[1] &&
      position.longitude >= bbBottomLeft[0] &&
      position.longitude <= bbTopRight[0]
    );
  }
  return false;
}

export function getSourceDataForAirport(airport) {
  const boundary = [
    [airport.westLongitude, airport.northLatitude],
    [airport.eastLongitude, airport.northLatitude],
    [airport.eastLongitude, airport.southLatitude],
    [airport.westLongitude, airport.southLatitude],
    [airport.westLongitude, airport.northLatitude],
  ];

  return {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [boundary],
      },
    },
  };
}

export function getFillLayerForAirport(layerId, sourceId) {
  return {
    id: layerId,
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": "rgb(23, 121, 218)", // blue color fill
      "fill-opacity": 0.25,
    },
  };
}
export function getStrokeLayerForAirport(layerId, sourceId) {
  return {
    id: layerId,
    type: "line",
    source: sourceId,
    layout: {},
    paint: {
      "line-color": "rgb(23, 121, 218)",
      "line-width": 2,
      "line-dasharray": [2, 1],
      "line-opacity": 0.5,
    },
  };
}

// NOTE: Filtering is no longer super-aggressive mode by default
const MAP_FILTER_MODE = false;
export function isFilteredOnMap(
  mapMarkerType,
  itemInfo,
  mapFilters,
  searchQuery
) {
  if (isNullOrUndefined(itemInfo)) {
    return false;
  }
  if (MAP_FILTER_MODE) {
    // NOTE: This is effectively disabled
    if (!mapFilters.markerTypes.includes(mapMarkerType)) {
      return true;
    }
    const searchTerm = searchQuery?.searchTerm;
    const adjustedSearchQuery = searchTerm?.trim().toLowerCase();
    let valueToSearch = null; // Name to search by

    // Check sub-filters and sub-types (should exit early if possible)
    if (mapMarkerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
      if (mapFilters?.mode?.value === OVERLAY_PANEL_MODES.TURNAROUNDS.value) {
        // Check sub-filter (Only for turnarounds)
        if (!itemInfo.isActive) {
          return true;
        }
      }
      valueToSearch = !isBlank(itemInfo?.combinedFlightName)
        ? itemInfo?.combinedFlightName
        : itemInfo?.flightName;
    } else if (mapMarkerType === MAPINFO_MARKER_TYPE.VEHICLE) {
      valueToSearch = itemInfo?.name;
      const allVehicleTypesAllowed = mapFilters.vehicleTypes.includes(
        MAPINFO_MARKER_TYPE.ALL
      );
      if (
        !allVehicleTypesAllowed &&
        !mapFilters.vehicleTypes.includes(itemInfo.mapInfo.typeUuid)
      ) {
        // Specific vehicle type not allowed
        return true;
      }
    } else if (mapMarkerType === MAPINFO_MARKER_TYPE.USER) {
      valueToSearch = !isBlank(itemInfo?.mapInfo?.name)
        ? itemInfo?.mapInfo?.name
        : itemInfo?.fullName;
    }

    if (!isBlank(adjustedSearchQuery) && !isBlank(valueToSearch)) {
      return valueToSearch.toLowerCase().indexOf(adjustedSearchQuery) === -1;
    }
  } else {
    // In this mode, ACTIVE aircraft always visible (regardless if in turnaround context)
    // All other assets can be filtered
    if (mapMarkerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
      if (mapFilters.mode.value === OVERLAY_PANEL_MODES.TURNAROUNDS.value) {
        return !itemInfo.isActive;
      } else {
        return false;
      }
    } else if (mapFilters.markerTypes.includes(MAPINFO_MARKER_TYPE.ALL)) {
      return false;
    } else {
      if (mapMarkerType === MAPINFO_MARKER_TYPE.VEHICLE) {
        if (!mapFilters.markerTypes.includes(MAPINFO_MARKER_TYPE.VEHICLE)) {
          // Top-level vehicle filter not enabled
          return true;
        } else {
          const allVehicleTypesAllowed = mapFilters.vehicleTypes.includes(
            MAPINFO_MARKER_TYPE.ALL
          );
          if (
            !allVehicleTypesAllowed &&
            !mapFilters.vehicleTypes.includes(itemInfo.mapInfo.typeUuid)
          ) {
            // Specific vehicle type not allowed
            return true;
          }
        }
      } else if (mapMarkerType === MAPINFO_MARKER_TYPE.USER) {
        if (!mapFilters.markerTypes.includes(MAPINFO_MARKER_TYPE.USER)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isWhitelistedOnMap(itemInfo, mapFilters) {
  return (
    !isNullOrUndefined(mapFilters?.whitelist) &&
    !isNullOrUndefined(itemInfo?.uuid) &&
    mapFilters.whitelist.includes(itemInfo.uuid)
  );
}

export function getBoundingBoxForMarkers(coordinates) {
  let north = null,
    south = null,
    east = null,
    west = null;
  for (let i = 0; i < coordinates.length; i++) {
    const coordinate = coordinates[i];
    if (isNullOrUndefined(north) || coordinate[0] < north) {
      north = coordinate[0];
    }
    if (isNullOrUndefined(south) || coordinate[0] > south) {
      south = coordinate[0];
    }
    if (isNullOrUndefined(east) || coordinate[1] < east) {
      east = coordinate[1];
    }
    if (isNullOrUndefined(west) || coordinate[1] > west) {
      west = coordinate[1];
    }
  }
  // southWest, northEast
  return [
    [south, west],
    [north, east],
  ];
}

// Checks if the selected marker is in the scope of the given Turnaround
// It will check all turnaround operations or a specific operation
export function isInTurnaroundScope(
  selectedTurnaround,
  selectedOperation,
  selectedMarker
) {
  if (isNullOrUndefined(selectedTurnaround)) return false;
  if (selectedMarker?.mapInfo?.markerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
    if (selectedTurnaround.registration === selectedMarker.registration) {
      return true;
    }
  }

  // Check all operations unless one is specified
  const operationsToCheck = !isNullOrUndefined(selectedOperation)
    ? [selectedOperation]
    : selectedTurnaround.operations;

  if (!isEmptyList(operationsToCheck)) {
    for (let i = 0; i < operationsToCheck.length; i++) {
      const operationToCheck = operationsToCheck[i];
      if (isInTurnaroundOperation(operationToCheck, selectedMarker)) {
        return true;
      }
    }
  }
  return false;
}

function isInTurnaroundOperation(operationToCheck, selectedMarker) {
  // For GSEs also check selectedOperation if present
  if (selectedMarker?.mapInfo?.markerType === MAPINFO_MARKER_TYPE.VEHICLE) {
    // GSE in-service
    if (!isEmptyList(operationToCheck.gseRecords)) {
      for (let i = 0; i < operationToCheck.gseRecords.length; i++) {
        const gseRecord = operationToCheck.gseRecords[i];
        if (gseRecord?.groundVehicle?.uuid === selectedMarker?.mapInfo?.uuid) {
          return true;
        }
      }
    }
  } else if (selectedMarker?.mapInfo?.markerType === MAPINFO_MARKER_TYPE.USER) {
    // Crew in-service and assigned
    if (!isEmptyList(operationToCheck.userRecords)) {
      for (let i = 0; i < operationToCheck.userRecords.length; i++) {
        const userRecord = operationToCheck.userRecords[i];
        if (userRecord?.user?.uuid === selectedMarker?.uuid) {
          return true;
        }
      }
    }
    const turnaroundRequirements = operationToCheck.turnaroundRequirements;
    if (!isEmptyList(turnaroundRequirements)) {
      for (let i = 0; i < turnaroundRequirements.length; i++) {
        const turnaroundRequirement = turnaroundRequirements[i];
        const crewAssignments = turnaroundRequirement.crewAssignments;
        if (!isEmptyList(crewAssignments)) {
          for (let j = 0; j < crewAssignments.length; j++) {
            const crewAssignment = crewAssignments[j];
            if (crewAssignment?.userUuid === selectedMarker?.uuid) {
              return true;
            }
          }
        }
      }
    }
  }
}
