import "./styles.css";
import { useEffect } from "react";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import MainMenu from "../MainMenu";
import UserBadge from "../UserBadge";
import Crew from "../Crew";
import Dashboard from "../Dashboard";
import Vehicles from "../Vehicles";
import { getDynamicEnv, getUser, logout } from "../../api";
import { isBlank, isNullOrUndefined } from "../../utils";
import Flights from "../Flights";
import {
  ResourceActions,
  userHasAnyResourceAction,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import Settings from "../Settings";
import SectionToolbar from "../SectionToolbar";
import Map from "../Map";
import { CookieManager } from "../../cookies";
import Dispatches from "../Dispatches";
import Templates from "../Templates";
import GateSchedule from "../GateSchedule";
import ActionBanner from "../ActionBanner";
import ConsoleDataHelper from "./ConsoleDataHelper";
import MoveAircraftReminder from "../MoveAircraftReminder";
import Chat from "../Chat";
import CrewShifts from "../CrewShifts";
import CrewShiftsProvider from "../../CrewShiftsProvider";
import SettingsProvider from "../../SettingsProvider";
import { useTranslation } from "react-i18next";
import { debugLog } from "../../logging";
import Notifications from "../Notifications";
import TurnaroundsModule from "../TurnaroundsModule";
import { useParams } from "react-router-dom";
import TasksOverview from "../TasksOverview";
import TasksOverviewProvider from "../../TasksOverviewProvider";
import OperationPerformance from "../OperationPerformance";
import { usePostHog } from "posthog-js/react";
import { FEATURE_FLAGS } from "../../constants";

function MainConsole(props) {
  const { i18n } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, dynamicEnv, showAircraftTypeSelector } = mainContext;
  const { section } = props;

  const isInternal = currentUser?.isInternal;

  useEffect(() => {
    getDynamicEnv(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // check if we can refresh the login user
    if (isNullOrUndefined(currentUser)) {
      // const accessToken = localStorage.getItem("accessToken");
      const accessToken = CookieManager.getCookieValue("accessToken");
      if (!isBlank(accessToken)) {
        getUser(dispatch);
        return () => {};
      }
      logout();
    }
  }, [currentUser, dispatch]);

  // Set the language for the user
  useEffect(() => {
    if (
      !isNullOrUndefined(currentUser) &&
      !isNullOrUndefined(currentUser.language)
    ) {
      i18n.changeLanguage(currentUser.language.code);
      debugLog(`Setting language ${currentUser.language.code}`);
    }
  }, [i18n, currentUser]);

  const hasEditAdminUsers = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminUsers
  );
  const hasEditAdminGse = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminGse
  );
  // Temp disable the performance pages because it needs refactoring
  const hasViewLeaderboard = false;
  const hasViewGseUtilization = false;
  const hasViewOnTimePerformance = userHasResourceAction(
    currentUser,
    ResourceActions.ViewOnTimePerformance
  );
  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );
  const hasViewTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewTurnaroundTemplate
  );
  const hasEditGateSchedule = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditStandAssignment
  );

  const showMap = !isNullOrUndefined(dynamicEnv?.mapBoxToken);
  const showDispatch = hasEditTurnProfile;
  const showTurnaroundTemplates = hasEditTurnProfile || hasViewTurnTemplate;
  const posthog = usePostHog();
  const showLiveRoster = posthog.isFeatureEnabled(FEATURE_FLAGS.LIVE_ROSTER);
  const showFlights = posthog.isFeatureEnabled(FEATURE_FLAGS.FLIGHTS);
  const showNotifications = posthog.isFeatureEnabled(
    FEATURE_FLAGS.NOTIFICATIONS
  );

  const { turnaroundUuid } = useParams();
  const isTurnarounds = section === "turnarounds";
  const showTurnaroundToolbar = isTurnarounds && isBlank(turnaroundUuid);

  return (
    <div className="main-console">
      <div className="main-menu">
        <MainMenu
          showAdminUsers={hasEditAdminUsers}
          showAdminGses={hasEditAdminGse}
          showLeaderboard={hasViewLeaderboard}
          showPerformance={hasViewOnTimePerformance}
          showUtilization={hasViewGseUtilization}
          showDispatch={showDispatch}
          showTurnaroundTemplates={showTurnaroundTemplates}
          showGateSchedule={hasEditGateSchedule}
          showCrewShifts={hasEditAdminUsers}
          showChat={!isInternal}
          showLiveRoster={showLiveRoster}
          showFlights={showFlights}
          showNotifications={showNotifications}
        />
      </div>
      <div className="main-container">
        <div className="main-header">
          <div className="header-left">
            {showTurnaroundToolbar && <SectionToolbar />}
            {section === "flights" && <SectionToolbar />}
            {section === "users" && <SectionToolbar />}
            {section === "vehicles" && <SectionToolbar />}
            {section === "map" && <SectionToolbar />}
            {section === "templates" && showAircraftTypeSelector && (
              <SectionToolbar />
            )}
          </div>
          <div className="header-center">
            <ActionBanner />
          </div>
          <div className="header-right">
            <UserBadge
              user={currentUser}
              menuEnabled={true}
              secondaryInfoField="email"
              isProfileBadge={true}
            />
          </div>
        </div>
        <div className="main-content-box">
          {section === "dashboard" && <Dashboard />}
          {showFlights && section === "flights" && <Flights />}
          {showLiveRoster && section === "live-roster" && (
            <CrewShiftsProvider>
              <TasksOverviewProvider>
                <TasksOverview />
              </TasksOverviewProvider>
            </CrewShiftsProvider>
          )}
          {section === "map" && showMap && <Map />}
          {section === "chat" && <Chat />}
          {section === "turnarounds" && <TurnaroundsModule />}
          {section === "allocations" && <Dispatches />}
          {section === "users" && hasEditAdminUsers && (
            <CrewShiftsProvider>
              <Crew />
            </CrewShiftsProvider>
          )}
          {section === "vehicles" && hasEditAdminGse && <Vehicles />}
          {section === "templates" && showTurnaroundTemplates && <Templates />}
          {section === "stand-assignments" && hasEditGateSchedule && (
            <GateSchedule />
          )}
          {section === "crew-shifts" && hasEditAdminUsers && (
            <CrewShiftsProvider>
              <CrewShifts />
            </CrewShiftsProvider>
          )}
          {showNotifications && section === "notifications" && (
            <Notifications />
          )}
          {section === "settings" && (
            <SettingsProvider>
              <Settings />
            </SettingsProvider>
          )}
          {section === "performance" && hasViewOnTimePerformance && (
            <OperationPerformance />
          )}
        </div>
      </div>
      <ConsoleDataHelper />
      {hasEditGateSchedule && <MoveAircraftReminder />}
    </div>
  );
}

export default MainConsole;
