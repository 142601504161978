import { createContext, useContext } from "react";

export const TasksOverviewContext = createContext(null);
export const TasksOverviewDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useTasksOverviewContext() {
  return useContext(TasksOverviewContext);
}

export function useTasksOverviewDispatchContext() {
  return useContext(TasksOverviewDispatchContext);
}
