import { useTranslation } from "react-i18next";
import {
  formatTime,
  formatTimeRange,
  getByFieldValue,
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import "./styles.css";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  useTasksOverviewContext,
  useTasksOverviewDispatchContext,
} from "../../TasksOverviewContext";
import { getTasksOverview } from "../../tasksOverviewApi";
import { getOperationTypes, getTurnaroundsSummary, getUsers } from "../../api";
import { getUserInfo } from "../../userUtils";
import {
  useCrewShiftsContext,
  useCrewShiftsDispatchContext,
} from "../../CrewShiftsContext";
import { searchCrewShifts } from "../../crewShiftsApi";
import { POLLING_INTERVALS } from "../../constants";
import { Fade, Tooltip } from "@mui/material";
import { tooltipStyles } from "../../styleUtils";
import CrewInfo from "../CrewInfo";
import { getTaskInfo } from "./utils";

// NOTE: This is renamed to Live Roster in the webapp
function TasksOverview() {
  const { t } = useTranslation();

  const dispatch = useMainDispatchContext();
  const tasksOverviewDispatch = useTasksOverviewDispatchContext();
  const crewShiftsDispatch = useCrewShiftsDispatchContext();

  const mainContext = useMainContext();
  const { currentUser, turnaroundsSummary, users, operationTypes, positions } =
    mainContext;
  const tasksOverviewContext = useTasksOverviewContext();
  const { tasksOverview } = tasksOverviewContext;
  const crewShiftsContext = useCrewShiftsContext();
  const { crewShifts } = crewShiftsContext;

  const airportTimezone = getTimezoneFromUser(currentUser);
  const mNow = moment().tz(airportTimezone);

  const [refreshOverviewRequested, setRefreshOverviewRequested] =
    useState(false);
  const [timelineRanges, setTimelineRanges] = useState({
    mRangeStart: moment(mNow).startOf("hour").add(-2, "hour"),
    mRangeEnd: moment(mNow).startOf("hour").add(10, "hour"),
  });

  // 1hr increments
  const timeBuckets = [];
  const timeInterval = moment(timelineRanges.mRangeStart);
  while (!timeInterval.isAfter(timelineRanges.mRangeEnd)) {
    if (timeInterval.isAfter(timelineRanges.mRangeStart)) {
      timeBuckets.push(moment(timeInterval));
    }
    timeInterval.add(1, "hour");
  }

  useEffect(() => {
    // Interval for refreshing the data within the timeframe
    const intervals = [];

    if (POLLING_INTERVALS.OVERVIEW > 0) {
      const interval = setInterval(() => {
        setRefreshOverviewRequested(true);
      }, POLLING_INTERVALS.OVERVIEW);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    if (!refreshOverviewRequested) return () => {};
    const mNowUpdated = moment().tz(airportTimezone);
    setTimelineRanges({
      mRangeStart: moment(mNowUpdated).startOf("hour").add(-2, "hour"),
      mRangeEnd: moment(mNowUpdated).startOf("hour").add(10, "hour"),
    });

    setRefreshOverviewRequested(false);
  }, [refreshOverviewRequested, airportTimezone]);

  useEffect(() => {
    // Load supporting data here
    if (isNullOrUndefined(timelineRanges)) return () => {};
    getUsers(dispatch);
    getOperationTypes(dispatch);
    getTurnaroundsSummary(dispatch);
  }, [dispatch, timelineRanges]);

  useEffect(() => {
    // Load supporting data here
    if (isNullOrUndefined(timelineRanges)) return () => {};
    const startTime = timelineRanges.mRangeStart.toDate().toISOString();
    const endTime = moment(timelineRanges.mRangeStart)
      .add(1, "day")
      .toDate()
      .toISOString();
    searchCrewShifts(crewShiftsDispatch, startTime, endTime);
  }, [crewShiftsDispatch, timelineRanges]);

  useEffect(() => {
    // Load required data here
    if (isNullOrUndefined(timelineRanges)) return () => {};
    getTasksOverview(tasksOverviewDispatch);
  }, [tasksOverviewDispatch, timelineRanges]);

  const containerRef = useRef(null);
  const markersRef = useRef(null);
  const gridlinesRef = useRef(null);

  function getTimeAsPixels(minutes) {
    const containerEl = gridlinesRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? containerEl.getBoundingClientRect().width - 2
      : 0;
    const totalWidth = containerWidth;

    const totalDuration = timelineRanges.mRangeEnd.diff(
      timelineRanges.mRangeStart,
      "minutes"
    );
    return minutes * (totalWidth / totalDuration);
  }

  function getTimelineMarkerStyle(mMakerTime) {
    if (isNullOrUndefined(mMakerTime)) return {};
    const durationFromRangeStart = mMakerTime.diff(
      timelineRanges.mRangeStart,
      "minutes"
    );
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    return {
      left: `${timelineLeft}px`,
    };
  }

  function getTimelineBarStyle(taskInfo) {
    const mTaskStart = moment(taskInfo.expectedStartTime).tz(airportTimezone);
    const mTaskEnd = moment(taskInfo.expectedEndTime).tz(airportTimezone);
    const isBeforeRangeStart = mTaskStart.isBefore(timelineRanges.mRangeStart);
    const isAfterRangeEnd = mTaskEnd.isAfter(timelineRanges.mRangeEnd);
    const durationFromRangeStart = !isBeforeRangeStart
      ? mTaskStart.diff(timelineRanges.mRangeStart, "minutes")
      : 0;

    // Amount of the task that is trimmed off from the front
    const trimmedDuration = isBeforeRangeStart
      ? timelineRanges.mRangeStart.diff(mTaskStart, "minutes")
      : 0;
    const durationToRangeEnd = !isNullOrUndefined(mTaskStart)
      ? timelineRanges.mRangeEnd.diff(mTaskStart, "minutes")
      : 0;
    const adjustedDuration = isBeforeRangeStart
      ? taskInfo.duration - trimmedDuration
      : taskInfo.duration;
    const duration = isAfterRangeEnd ? durationToRangeEnd : adjustedDuration;
    const timelineWidth = getTimeAsPixels(duration);
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    let style = {
      left: `${timelineLeft}px`,
    };
    if (duration > 0) {
      style.width = `${timelineWidth}px`;
    }
    if (isBeforeRangeStart) {
      style.borderTopLeftRadius = "0px";
      style.borderBottomLeftRadius = "0px";
    }
    if (isAfterRangeEnd) {
      style.borderTopRightRadius = "0px";
      style.borderBottomRightRadius = "0px";
    }
    return style;
  }

  // Main function to calculate the gantt timelines
  const [taskInfoList, unassignedTaskInfoList] = useMemo(() => {
    const tasksToRender = [];
    const unassignedTasksToRender = [];
    if (
      !isNullOrUndefined(tasksOverview) &&
      !isEmptyList(users) &&
      !isEmptyList(turnaroundsSummary) &&
      !isEmptyList(operationTypes)
    ) {
      // Create crew shifts lookup by user
      const crewShiftsByUserUuid = {};
      if (!isEmptyList(crewShifts)) {
        for (let i = 0; i < crewShifts.length; i++) {
          const crewShift = crewShifts[i];

          const mStart = crewShift?.startTime
            ? moment(crewShift?.startTime).startOf("minute")
            : null;
          const mEnd = crewShift?.endTime
            ? moment(crewShift?.endTime).startOf("minute")
            : null;
          const durationInMinutes =
            !isNullOrUndefined(mEnd) && !isNullOrUndefined(mStart)
              ? mEnd.diff(mStart, "minutes")
              : 0;

          const isCrewShiftInRange =
            mStart.isBefore(timelineRanges.mRangeEnd) &&
            mEnd.isAfter(timelineRanges.mRangeStart);

          if (isCrewShiftInRange) {
            // Info object that can be rendered the same way as a task
            const crewShiftInfo = {
              expectedStartTime: crewShift.startTime,
              expectedEndTime: crewShift.endTime,
              duration: durationInMinutes,
            };
            if (crewShift.userUuid in crewShiftsByUserUuid) {
              crewShiftsByUserUuid[crewShift.userUuid].push(crewShiftInfo);
            } else {
              crewShiftsByUserUuid[crewShift.userUuid] = [crewShiftInfo];
            }
          }
        }
      }

      // Add all crew shifts
      const crewShiftsUserUuids = Object.keys(crewShiftsByUserUuid);
      if (!isEmptyList(crewShiftsUserUuids)) {
        for (let i = 0; i < crewShiftsUserUuids.length; i++) {
          const crewShiftsUserUuid = crewShiftsUserUuids[i];
          const user = getByFieldValue(users, "uuid", crewShiftsUserUuid);
          const userInfo = !isNullOrUndefined(user)
            ? getUserInfo(user, turnaroundsSummary, positions)
            : null;

          const userCrewShifts = crewShiftsByUserUuid[crewShiftsUserUuid];
          tasksToRender.push({
            userUuid: crewShiftsUserUuid,
            userInfo: userInfo,
            userCrewShifts: userCrewShifts,
          });
        }
      }

      if (!isEmptyList(tasksOverview?.userTasks)) {
        // Create a tasks info object for rendering
        for (let i = 0; i < tasksOverview.userTasks.length; i++) {
          const task = tasksOverview.userTasks[i];
          const mTaskStart = moment(task.expectedStartTime).tz(airportTimezone);

          const userTasks = [];
          if (!isEmptyList(task.tasks)) {
            for (let j = 0; j < task.tasks.length; j++) {
              const userTask = task.tasks[j];
              const taskInfo = getTaskInfo(
                userTask,
                operationTypes,
                turnaroundsSummary
              );
              if (!isNullOrUndefined(taskInfo)) {
                userTasks.push(taskInfo);
              }
            }
          }
          if (
            !isEmptyList(userTasks) &&
            mTaskStart.isBefore(timelineRanges.mRangeEnd)
          ) {
            let tasksForUser = getByFieldValue(
              tasksToRender,
              "userUuid",
              task.userUuid
            );
            if (!isNullOrUndefined(tasksForUser)) {
              // Update the user with crew shifts
              tasksForUser.tasks = task.tasks;
              tasksForUser.userTasks = userTasks;
            } else {
              // Add user with tasks but no crew shifts
              const user = getByFieldValue(users, "uuid", task.userUuid);
              const userInfo = !isNullOrUndefined(user)
                ? getUserInfo(user, turnaroundsSummary, positions)
                : null;
              tasksToRender.push({
                userUuid: task.userUuid,
                userInfo: userInfo,
                tasks: task.tasks,
                userTasks: userTasks,
              });
            }
          }
        }
        sortByField(tasksToRender, "userInfo.fullName");
      }
      // Process the unassignedTasks and info object for rendering
      if (!isEmptyList(tasksOverview?.unassignedTasks)) {
        for (let i = 0; i < tasksOverview.unassignedTasks.length; i++) {
          const unassignedTask = tasksOverview.unassignedTasks[i];
          const mTaskStart = moment(unassignedTask.expectedStartTime).tz(
            airportTimezone
          );
          const taskInfo = getTaskInfo(
            unassignedTask,
            operationTypes,
            turnaroundsSummary
          );
          if (
            !isNullOrUndefined(taskInfo) &&
            mTaskStart.isBefore(timelineRanges.mRangeEnd)
          ) {
            unassignedTasksToRender.push(taskInfo);
          }
        }
        sortByField(unassignedTasksToRender, "expectedStartTime", [
          "operationType.name",
        ]);
      }
    }
    return [tasksToRender, unassignedTasksToRender];
  }, [
    tasksOverview,
    users,
    turnaroundsSummary,
    operationTypes,
    crewShifts,
    positions,
    airportTimezone,
    timelineRanges,
  ]);

  // Need to correct the vertical position of the label so that it doesn't get covered by header
  function handleContentScroll() {
    const scrollEl = containerRef.current;
    const markersEl = markersRef.current;
    if (isNullOrUndefined(scrollEl) || isNullOrUndefined(markersEl)) return;

    const scrollTop = scrollEl.scrollTop;
    markersEl.style.top = `${scrollTop}px`;
  }

  function handleHoverOver(idx) {
    const relatedEls = document.querySelectorAll(`[data-rowindex="${idx}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.add("hovered");
      }
    }
  }
  function handleHoverOut(idx) {
    const relatedEls = document.querySelectorAll(`[data-rowindex="${idx}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.remove("hovered");
      }
    }
  }

  const columnStyle = {
    gridTemplateColumns: `repeat(${timeBuckets.length}, minmax(0, 1fr))`,
  };
  const totalRows = unassignedTaskInfoList?.length + taskInfoList.length;
  const rowStyle = {
    gridTemplateRows: `repeat(${totalRows}, minmax(0, 1fr))`,
  };

  // Styles to position the markers
  const nowMarkerStyle = getTimelineMarkerStyle(mNow);

  return (
    <div className="tasks-overview">
      <div className="tasks-overview-header">
        <div>
          <div>
            <h3>{t("overview")}</h3>
          </div>
        </div>
      </div>

      <div className="tasks-overview-body">
        <div
          className="tasks-overview-container"
          ref={containerRef}
          onScroll={handleContentScroll}
        >
          <div className="tasks-overview-header-row">
            <div className="cell-label">
              <div>Name</div>
            </div>
            <div style={columnStyle}>
              {timeBuckets &&
                timeBuckets.map((i, idx) => (
                  <div className="cell" key={`header-${idx}`}>
                    <div>{idx + 1 < timeBuckets.length && formatTime(i)}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="tasks-overview-content">
            <div className="tasks-overview-sidepanel" style={rowStyle}>
              {!isEmptyList(taskInfoList) &&
                taskInfoList.map((taskInfo, idx) => (
                  <div
                    className="cell-label"
                    key={`sidepanel-${idx}`}
                    data-rowindex={idx}
                    onMouseOver={() => {
                      handleHoverOver(idx);
                    }}
                    onMouseOut={() => {
                      handleHoverOut(idx);
                    }}
                  >
                    <div>
                      <CrewInfo
                        user={taskInfo.userInfo.user}
                        mapStatus={taskInfo.userInfo.mapStatus}
                      />
                    </div>
                  </div>
                ))}
              {!isEmptyList(unassignedTaskInfoList) &&
                unassignedTaskInfoList.map((unassignedTask, idx) => (
                  <div
                    className="cell-label unassigned"
                    key={`sidepanel-unassigned-${idx}`}
                    data-rowindex={`unassigned-${idx}`}
                    onMouseOver={() => {
                      handleHoverOver(`unassigned-${idx}`);
                    }}
                    onMouseOut={() => {
                      handleHoverOut(`unassigned-${idx}`);
                    }}
                  >
                    <div>
                      <CrewInfo
                        user={{
                          firstName: t("unassigned"),
                          lasName: "",
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="tasks-overview-mainpanel">
              <div
                className="tasks-overview-gridlines"
                style={rowStyle}
                ref={gridlinesRef}
              >
                {taskInfoList &&
                  taskInfoList.map((taskInfo, idx) => (
                    <div
                      key={`grid-${idx}`}
                      style={columnStyle}
                      data-rowindex={idx}
                      onMouseOver={() => {
                        handleHoverOver(idx);
                      }}
                      onMouseOut={() => {
                        handleHoverOut(idx);
                      }}
                    >
                      {timeBuckets &&
                        timeBuckets.map((i, subIdx) => (
                          <div
                            className="gridcell"
                            key={`grid-${idx}-${subIdx}`}
                          >
                            <div data-time-bucket={formatTime(i)}></div>
                          </div>
                        ))}
                    </div>
                  ))}
                {!isEmptyList(unassignedTaskInfoList) &&
                  unassignedTaskInfoList.map((unassignedTask, idx) => (
                    <div
                      key={`grid-${idx}`}
                      style={columnStyle}
                      data-rowindex={`unassigned-${idx}`}
                      onMouseOver={() => {
                        handleHoverOver(`unassigned-${idx}`);
                      }}
                      onMouseOut={() => {
                        handleHoverOut(`unassigned-${idx}`);
                      }}
                    >
                      {timeBuckets &&
                        timeBuckets.map((i, subIdx) => (
                          <div
                            className="gridcell"
                            key={`grid-${idx}-${subIdx}`}
                          >
                            <div data-time-bucket={formatTime(i)}></div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
              <div className="tasks-overview-timelinebars" style={rowStyle}>
                {taskInfoList &&
                  taskInfoList.map((taskInfo, idx) => (
                    <div key={`timelinebar-${idx}`}>
                      {/** Crew shift timeline bars **/}
                      {taskInfo?.userCrewShifts &&
                        taskInfo?.userCrewShifts.map((userTask, subIdx) => {
                          const timelineStyle = getTimelineBarStyle(userTask);
                          return (
                            <Tooltip
                              key={`timelinebar-crewshift-${idx}-${subIdx}`}
                              title={
                                <CrewShiftTooltip
                                  crewShift={userTask}
                                  timezone={airportTimezone}
                                />
                              }
                              placement="left"
                              componentsProps={tooltipStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                              arrow
                            >
                              <div
                                className="tasks-overview-timelinebar crewshift"
                                style={timelineStyle}
                              >
                                <div>
                                  <div className="tasks-overview-timelinebar-value"></div>
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                      {/** Task timeline bars **/}
                      {taskInfo?.userTasks &&
                        taskInfo?.userTasks.map((userTask, subIdx) => {
                          const timelineStyle = getTimelineBarStyle(userTask);
                          return (
                            <Tooltip
                              key={`timelinebar-task-${idx}-${subIdx}`}
                              title={
                                <TaskTooltip
                                  userTask={userTask}
                                  timezone={airportTimezone}
                                />
                              }
                              placement="top"
                              componentsProps={tooltipStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                              arrow
                            >
                              <div
                                className={`tasks-overview-timelinebar${
                                  userTask.duration === 0 ? " point" : ""
                                }`}
                                style={timelineStyle}
                              >
                                <div>
                                  <div className="tasks-overview-timelinebar-value">
                                    {userTask?.turnaroundInfo
                                      ?.combinedFlightName ||
                                      userTask?.turnaroundUuid}
                                    : {userTask?.operationType?.name}
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                    </div>
                  ))}
                {!isEmptyList(unassignedTaskInfoList) &&
                  unassignedTaskInfoList.map((unassignedTask, idx) => {
                    const timelineStyle = getTimelineBarStyle(unassignedTask);
                    return (
                      <div key={`unassigned-${idx}`}>
                        <Tooltip
                          title={
                            <TaskTooltip
                              userTask={unassignedTask}
                              timezone={airportTimezone}
                            />
                          }
                          placement="top"
                          componentsProps={tooltipStyles}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 500 }}
                          arrow
                        >
                          <div
                            className={`tasks-overview-timelinebar unassigned${
                              unassignedTask.duration === 0 ? " point" : ""
                            }`}
                            style={timelineStyle}
                          >
                            <div>
                              <div className="tasks-overview-timelinebar-value">
                                {unassignedTask?.turnaroundInfo
                                  ?.combinedFlightName ||
                                  unassignedTask?.turnaroundUuid}
                                : {unassignedTask?.operationType?.name}
                              </div>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="tasks-overview-timeline-markers"
            style={{ top: 0 }}
            ref={markersRef}
          >
            <div className="timeline-marker" style={nowMarkerStyle}>
              <div>
                <div className="timeline-marker-label-anchor">
                  <div className="timeline-marker-label">
                    <div>
                      <div className="timeline-marker-label-title">Now</div>
                      <div className="timeline-marker-label-value">
                        {formatTime(mNow, airportTimezone)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-marker-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CrewShiftTooltip(props) {
  const { crewShift, timezone } = props;
  return (
    <div>
      <div>Crew shift</div>
      <div className="tooltip-timestamps">
        {formatTimeRange(
          crewShift.expectedStartTime,
          crewShift.expectedEndTime,
          timezone
        )}
      </div>
    </div>
  );
}

function TaskTooltip(props) {
  const { userTask, timezone } = props;
  return (
    <div>
      <div>
        {userTask.turnaroundInfo.combinedFlightName}:{" "}
        {userTask.operationType.name}
      </div>
      <div className="tooltip-timestamps">
        {formatTimeRange(
          userTask.expectedStartTime,
          userTask.expectedEndTime,
          timezone
        )}
      </div>
    </div>
  );
}

export default TasksOverview;
