import { createContext, useContext } from "react";

export const SettingsContext = createContext(null);
export const SettingsDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useSettingsContext() {
  return useContext(SettingsContext);
}

export function useSettingsDispatchContext() {
  return useContext(SettingsDispatchContext);
}
