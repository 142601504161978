import { formatHoursMins, formatTime, isNullOrUndefined } from "../../utils";

export const TurnaroundsModuleColumns = {
  TurnaroundName: {
    id: "turnaround_name",
    displayName: "turnaround",
    formatter: (item) => {
      return item?.combinedFlightName;
    },
    sortValue: (item) => `${item.combinedFlightName}`,
  },
  Registration: {
    id: "registration",
    displayName: "registration",
    formatter: (item) => {
      return item?.registration;
    },
    sortValue: (item) => `${item.registration}`,
  },
  Landing: {
    id: "landing",
    displayName: "landing_non_cap",
    formatter: (item) => {
      return formatTime(item?.landing, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.landing) ? new Date(item?.landing) : null,
  },
  GateIn: {
    id: "gate_in",
    displayName: "gate_in_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateIn, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateIn) ? new Date(item?.gateIn) : null,
  },
  GateOut: {
    id: "gate_out",
    displayName: "gate_out_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateOut, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateOut) ? new Date(item?.gateOut) : null,
  },
  Takeoff: {
    id: "takeoff",
    displayName: "takeoff_non_cap",
    formatter: (item) => {
      return formatTime(item?.takeoff, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.takeoff) ? new Date(item?.takeoff) : null,
  },
  TotalGate: {
    id: "total_gate_time",
    displayName: "total_gate_time",
    formatter: (item) => {
      return formatHoursMins(item?.gateTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateTimeMinutes) ? item?.gateTimeMinutes : 0,
  },
  TotalGround: {
    id: "total_ground_time",
    displayName: "total_ground_time",
    formatter: (item) => {
      return formatHoursMins(item?.groundTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.groundTimeMinutes) ? item?.groundTimeMinutes : 0,
  },
};
