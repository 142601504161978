import { getApiByResourceName } from "./api";

export async function getAvailableLanguages(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "settings/available-languages",
    "AvailableLanguages",
    "get"
  );
}

export async function updateUserLanguage(dispatch, languageCode) {
  return getApiByResourceName(
    dispatch,
    "settings/user-language",
    "SettingsUserLanguage",
    "put",
    {
      languageCode: languageCode,
    }
  );
}

export async function switchDeployment(dispatch, deploymentUserGroupUuid) {
  return getApiByResourceName(
    dispatch,
    "settings/current-deployment-user-group",
    "SwitchDeployment",
    "put",
    {
      deploymentUserGroupUuid: deploymentUserGroupUuid,
    }
  );
}
