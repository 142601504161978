import { getEnvName } from "./api";
import { SettingNames, SettingsManager } from "./SettingsManager";
import { isNullOrUndefined } from "./utils";

export function debugLog(msg) {
  if (isDebugLoggingEnabled()) {
    console.log("DEBUG:", msg);
  }
}

export function isDebugLoggingEnabled() {
  const debugOverride = SettingsManager.getSetting(
    SettingNames.DEBUG_LOGGING.name
  );
  const isProd = getEnvName() === "prod";
  const hasOverrideValue = !isNullOrUndefined(debugOverride);
  return hasOverrideValue ? debugOverride === "true" : !isProd;
}
