import { getLocalMoment } from "./utils";

export function getDayInitial(dateObj) {
  return getDayAbbreviation(dateObj).substring(0, 1);
}

export function getDayAbbreviation(dateObj) {
  return getLocalMoment(dateObj)
    .format("ddd")
    .toUpperCase()
    .replaceAll(".", "");
}
