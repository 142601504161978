import { useTranslation } from "react-i18next";
import StatusBadge from "../StatusBadge";

function TurnaroundVehicleSlot(props) {
  const { t } = useTranslation();

  const {
    vehicleInfo,
    showStatus,
    showActions,
    selected,
    onClick,
    isSelectable,
  } = props;

  const vehicleTypeName = vehicleInfo?.name;

  return (
    <div
      className={`turnaround-vehicle empty ${
        isSelectable ? " selectable" : ""
      } ${selected ? " selected" : ""}`}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
      data-id={vehicleInfo?.id}
    >
      <div>
        {showStatus && (
          <div className="status">
            <div className="status-ring">
              <div className="status-indicator"></div>
            </div>
          </div>
        )}
        <div className="turnaround-vehicle-allocated">
          <div>
            <label>{t("allocated")}</label>
            <div>
              <StatusBadge
                isAllCap={true}
                isCritical={true}
                isFilled={true}
                message={vehicleTypeName}
              />
            </div>
            {showStatus && (
              <span>
                <span className="status-text">{t("pending_non_cap")}</span>
              </span>
            )}
          </div>
        </div>
        <div>
          {showActions && (
            <div>
              <button className="outlined">{t("locate")}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TurnaroundVehicleSlot;
