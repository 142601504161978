import { MapStyles } from "./constants";
import { isBlank } from "./utils";

export const SettingNames = {
  DEBUG_LOGGING: {
    name: "DEBUG_LOGGING",
    defaultValue: null,
  },
  MAP_STYLE: {
    name: "MAP_STYLE",
    defaultValue: MapStyles.DYNAMIC,
  },
};

export const SettingsManager = {
  getSetting: (name) => {
    const value = localStorage.getItem(name);
    return isBlank(value) ? SettingNames[name]?.defaultValue : value;
  },
  setSetting: (name, value) => {
    return localStorage.setItem(name, value);
  },
};
