import { useChatContext } from "../../ChatContext";
import { useMainContext } from "../../MainContext";
import { isEmptyList, isNullOrUndefined } from "../../utils";

function ChatIndicator() {
  const mainContext = useMainContext();
  const { currentUser } = mainContext;

  const chatContext = useChatContext();
  const { chatSummary } = chatContext;

  let hasUnreadChatMessages = false;
  if (!isNullOrUndefined(currentUser) && !isNullOrUndefined(chatSummary)) {
    const allChannels = [];
    if (!isEmptyList(chatSummary)) {
      // Combine all channels (top level is category)
      for (let i = 0; i < chatSummary.length; i++) {
        const category = chatSummary[i];
        if (!isNullOrUndefined(category.channels)) {
          allChannels.push.apply(allChannels, category.channels);
        }
      }

      for (let i = 0; i < allChannels.length && !hasUnreadChatMessages; i++) {
        const channel = allChannels[i];
        if (channel?.numUnreadMessages > 0) {
          hasUnreadChatMessages = true;
        }
      }
    }
  }

  const showIndicator = hasUnreadChatMessages;
  return <>{showIndicator && <div className="status-indicator"></div>}</>;
}

export default ChatIndicator;
