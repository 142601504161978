import "./styles.css";
import ChatModule, { ChatModuleMode } from "./ChatModule";

function Chat() {
  return (
    <>
      <ChatModule mode={ChatModuleMode.STANDARD} isActive={true} />
    </>
  );
}

export default Chat;
