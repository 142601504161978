import { useReducer } from "react";
import { SettingsContext, SettingsDispatchContext } from "./SettingsContext";

export const initialState = {
  globalError: null,
  availableLanguages: null,
  availableLanguagesLoading: false,
  settingsUserLanguageSaving: false,
  switchDeploymentSaving: false,
};

export function settingsReducer(state, action) {
  switch (action.type) {
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
      };
    case "setAvailableLanguages":
      return {
        ...state,
        availableLanguages: action.value,
      };
    case "setAvailableLanguagesLoading":
      return {
        ...state,
        availableLanguagesLoading: action.value,
      };
    case "setSettingsUserLanguageSaving":
      return {
        ...state,
        settingsUserLanguageSaving: action.value,
      };
    case "setSwitchDeploymentSaving":
      return {
        ...state,
        switchDeploymentSaving: action.value,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function SettingsProvider({ children }) {
  const [main, dispatch] = useReducer(settingsReducer, initialState);
  return (
    <SettingsContext.Provider value={main}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsContext.Provider>
  );
}
