// TODO: consider changing this to the deployment date
export const MIN_DATE = "2024-02-09";

export const EMPTY_TIME = "--:--";

export const EMPTY_STAND = "-/-";

export const DEFAULT_TIMEZONE = "America/Los_Angeles";

export const ALERT_DURATION = {
  DEFAULT: 5000,
  ERROR: 30000,
};

// in milliseconds
export const POLLING_INTERVALS = {
  ALERTS: 10000,
  TURNAROUNDS: 10000,
  TURNAROUNDS_SUMMARY: 60000,
  MAP_POSITIONS: 3000,
  TIME_OF_DAY: 60000,
  COMMS: 60000,
  COMMS_CHANNEL: 3000,
  CHAT_SUMMARY: 10000,
  ALLOCATIONS: 60000,
  NOTIFICATIONS: -1, // disabled
  OVERVIEW: 60000,
};

export const DEFAULT_START_TIME_OFFSET = 5;

export const ID_FIELDS = {
  ID: "id",
  UUID: "uuid",
};

export const SEARCH_QUERY_PLACEHOLDERS = {
  SEARCH_FLIGHTS: "search_flights",
  SEARCH_USERS: "search_users",
  SEARCH_VEHICLES: "search_gses",
  SEARCH_TURNAROUNDS: "search_turnarounds",
  SEARCH_CREW: "search_crew",
  SEARCH_AIRCRAFT_MODELS: "search_aircraft_models",
};

export const MAP_ASSET_CATEGORIES = {
  TURNAROUNDS: {
    label: "Turnarounds",
    value: "turnarounds",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
  },
  CREW: {
    label: "Crew",
    value: "crew",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_CREW,
  },
  FLIGHTS: {
    label: "Flights",
    value: "flights",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_FLIGHTS,
  },
  VEHICLES: {
    label: "Vehicles",
    value: "vehicles",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_VEHICLES,
  },
};

export const OVERLAY_PANEL_MODES = {
  TURNAROUNDS: {
    value: "turnarounds",
  },
  CREW: {
    value: "crew",
  },
  VEHICLES: {
    value: "vehicles",
  },
  FLIGHTS: {
    value: "flights",
  },
};

export const MAP_OVERLAY_PANEL_MODES = [
  OVERLAY_PANEL_MODES.TURNAROUNDS,
  OVERLAY_PANEL_MODES.CREW,
  OVERLAY_PANEL_MODES.VEHICLES,
  OVERLAY_PANEL_MODES.FLIGHTS,
];

export const METRICS_SORT_FIELDS = {
  COMPLETED_ONTIME: "completed_on_time",
  COMPLETED_LATE: "completed_late",
  COMPLETED_PARTIALLY: "completed_partially",
  MISSING: "missing",
};

export const METRICS_TERMS = {
  ALL_OPERATIONS: "All operations",
};

// For 3-part pie missing/late/on-time
export const CHART_COLORS = ["#4A5654", "#2BB2FE", "#1779DA", "#C38306"];

// For Line/Bar charts that can have multiple series
export const CHART_SERIES_COLORS = [
  "#1779DA",
  "#626262",
  "#B0B0B0",
  "#C20962",
  "#F76DAF",
  "#9D09C2",
  "#DC6DF7",
  "#5B10BB",
  "#AA72F2",
  "#0B28C2",
  "#6E84F7",
  "#0A7FC1",
  "#6DC5F7",
  "#07B0A6",
  "#6BEAE2",
  "#0AB372",
  "#9EF3D2",
  "#438E09",
  "#99D06D",
  "#C38306",
  "#F9C86B",
  "#BF4C0C",
  "#F59F6F",
  "#C20909",
  "#F76D6D",
];
export const CHART_COLOR_ALL = "#1779DA";

export const ANALYTICS_EVENTS = {
  CLIENT_ERROR: "CLIENT_ERROR",
  VIEWED_FULL_FLIGHT_LIST: "VIEWED_FULL_FLIGHT_LIST",
  DISMISSED_WEB_RELEASE_NOTES: "DISMISSED_WEB_RELEASE_NOTES",
  ALLOCATIONS_BANNER_CLICK: "ALLOCATIONS_BANNER_CLICK",
  ALLOCATIONS_PAGE_VIEW: "ALLOCATIONS_PAGE_VIEW",
  EDIT_ALLOCATION_BUTTON_CLICK: "EDIT_ALLOCATION_BUTTON_CLICK",
  EDIT_ALLOCATION_TURNAROUND_PANEL_BUTTON_CLICK:
    "EDIT_ALLOCATION_TURNAROUND_PANEL_BUTTON_CLICK",
  SAVE_ALLOCATION_BUTTON_CLICK: "SAVE_ALLOCATION_BUTTON_CLICK",
  MOVE_AIRCRAFT_REMINDER_BANNER_CLICK: "MOVE_AIRCRAFT_REMINDER_BANNER_CLICK",
  MOVE_AIRCRAFT_BUTTON_CLICK: "MOVE_AIRCRAFT_BUTTON_CLICK",
  MOVE_AIRCRAFT_COMMIT_BUTTON_CLICK: "MOVE_AIRCRAFT_COMMIT_BUTTON_CLICK",
  VIEWED_TURNAROUNDS_FROM_MAP: "VIEWED_TURNAROUNDS_FROM_MAP",
  VIEWED_GSES_FROM_MAP: "VIEWED_GSES_FROM_MAP",
  VIEWED_USERS_FROM_MAP: "VIEWED_USERS_FROM_MAP",
  VIEWED_FLIGHTS_FROM_MAP: "VIEWED_FLIGHTS_FROM_MAP",
};

export const FEATURE_FLAGS = {
  WEB_RELEASE_NOTES: "web-release-notes",
  TEMPLATE_DIAGRAM: "template-diagram",
  CREATE_TEMPLATE: "create_template",
  LIVE_ROSTER: "live_roster",
  TURNAROUND_LABELS: "turnaround_labels",
  NOTIFICATIONS: "notifications", // disabled
  FLIGHTS: "flights", // disabled
};

export const DATE_GRANULARITY = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
};

export const TIMELINE_EVENTS = {
  UNKNOWN: 0,
  SERVICE_INITIATED: 1,
  STAND_UPDATE: 2,
  INBOUND_AIRBORNE: 3,
  AIRCRAFT_LANDED: 4,
  GATE_IN: 5,
  GATE_OUT: 6,
  AIRCRAFT_DEPARTED: 7,
  OPERATION_INITIATED: 8,
  OPERATION_COMPLETED: 9,
  OPERATION_DELAYED: 10,
  CREW_DISPATCHED: 11,
};

// Supported languages
export const LANGUAGES = {
  en: "English",
  es: "Español",
};

export const MapStyles = {
  DYNAMIC: "dynamic_lighting_style",
  STANDARD: "standard_style",
};

export const UserDispatchStatus = {
  AVAILABLE: "available",
  UNAVAILABLE_NOT_ONLINE: "offline",
  UNAVAILABLE_IN_SERVICE: "unavailable_servicing",
  AVAILABLE_DURING_TURNAROUND: "available_during_turnaround",
  UNAVAILABLE_NOT_ONLINE_DURING_TURNAROUND:
    "unavailable_not_online_during_turnaround",
  UNAVAILABLE_IN_SERVICE_DURING_TURNAROUND:
    "unavailable_servicing_during_turnaround",
};

// TODO: Refactor to use operation types
// Rely on the operation name to identify a Reposition operation
export const RepositionOperationNames = {
  REPOSITION_1: "Reposition 1",
  REPOSITION_2: "Reposition 2",
};

export const MAP_RIGHT_PANEL_MODES = {
  TIMELINE: "TIMELINE",
  CHAT: "CHAT",
  CREW_ALLOCATIONS: "CREW_ALLOCATIONS",
  CREW_ASSIGNMENTS: "CREW_ASSIGNMENTS",
  ADD_CREW_MEMBER: "ADD_CREW_MEMBER",
};

export const SCROLL_STYLES = {
  SMOOTH: "smooth",
  INSTANT: "instant",
};
