import { createContext, useContext } from "react";

export const CrewShiftsContext = createContext(null);
export const CrewShiftsDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useCrewShiftsContext() {
  return useContext(CrewShiftsContext);
}

export function useCrewShiftsDispatchContext() {
  return useContext(CrewShiftsDispatchContext);
}
