import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { formatTime, isBlank, isNullOrUndefined } from "../../utils";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";
import { useTranslation } from "react-i18next";

function DispatchInfo(props) {
  const { turnaroundInfo } = props;
  const { t } = useTranslation();

  const inboundFlightName = !isBlank(turnaroundInfo.inboundFlightName)
    ? turnaroundInfo.inboundFlightName
    : "-";
  const outboundFlightName = !isBlank(turnaroundInfo.outboundFlightName)
    ? turnaroundInfo.outboundFlightName
    : "-";
  const gateIn = !isBlank(turnaroundInfo.gateIn)
    ? formatTime(turnaroundInfo.gateIn, turnaroundInfo.airportTimezone, true)
    : EMPTY_TIME;
  const gateOut = !isBlank(turnaroundInfo.gateOut)
    ? formatTime(turnaroundInfo.gateOut, turnaroundInfo.airportTimezone, true)
    : EMPTY_TIME;

  const hasSnappedStand = !isNullOrUndefined(
    turnaroundInfo?.snappedStand?.name
  );
  const arrivalStand = hasSnappedStand
    ? turnaroundInfo?.snappedStand?.name
    : !isBlank(turnaroundInfo?.arrivalStand?.name)
    ? turnaroundInfo?.arrivalStand.name
    : EMPTY_STAND;

  const arrivalStandLabel = hasSnappedStand
    ? t("current_stand")
    : t("assigned_arr_stand_abbr");

  const departureStand = !isBlank(turnaroundInfo?.departureStand?.name)
    ? turnaroundInfo?.departureStand.name
    : EMPTY_STAND;

  const departureStandLabel = t("assigned_dep_stand_abbr");

  const originAirport = !isBlank(turnaroundInfo.originAirport)
    ? turnaroundInfo.originAirport
    : "-";
  const destinationAirport = !isBlank(turnaroundInfo.destinationAirport)
    ? turnaroundInfo.destinationAirport
    : "-";
  return (
    <div className="dispatch-info">
      <div className="dispatch-info-row">
        <div>
          <Arrival />
        </div>
        <div className="label-value-pair">
          <div>
            <label>{t("inbound")}</label>
          </div>
          <div>{inboundFlightName}</div>
        </div>
        <div className="outline">
          <div className="label-value-pair">
            <div>
              <label>{t("gate_in_non_cap")}</label>
            </div>
            <div>{gateIn}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair">
            <div>
              <label>{arrivalStandLabel}</label>
            </div>
            <div>{arrivalStand}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair">
            <div>
              <label>{t("origin_non_cap")}</label>
            </div>
            <div>{originAirport}</div>
          </div>
        </div>
      </div>
      <div className="dispatch-info-row">
        <div>
          <Departure />
        </div>
        <div className="label-value-pair">
          <div>
            <label>{t("outbound")}</label>
          </div>
          <div>{outboundFlightName}</div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <div>
              <label>{t("gate_out_non_cap")}</label>
            </div>
            <div>{gateOut}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <div>
              <label>{departureStandLabel}</label>
            </div>
            <div>{departureStand}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <div>
              <label>{t("destination_non_cap")}</label>
            </div>
            <div>{destinationAirport}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DispatchInfo;
