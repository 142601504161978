import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import { getByFieldValue, isNullOrUndefined } from "../../utils";
import moment from "moment-timezone";

export function getTaskInfo(sourceTask, operationTypes, turnaroundsSummary) {
  const operationType = getByFieldValue(
    operationTypes,
    "uuid",
    sourceTask.operationTypeUuid
  );
  const turnaround = getByFieldValue(
    turnaroundsSummary,
    "uuid",
    sourceTask.turnaroundUuid
  );
  const turnaroundInfo = !isNullOrUndefined(turnaround)
    ? getTurnaroundInfoForTurnaroundSummary(turnaround)
    : null;

  const mStart = sourceTask?.expectedStartTime
    ? moment(sourceTask?.expectedStartTime).startOf("minute")
    : null;
  const mEnd = sourceTask?.expectedEndTime
    ? moment(sourceTask?.expectedEndTime).startOf("minute")
    : null;
  const durationInMinutes =
    !isNullOrUndefined(mEnd) && !isNullOrUndefined(mStart)
      ? mEnd.diff(mStart, "minutes")
      : 0;

  if (!isNullOrUndefined(operationType) && !isNullOrUndefined(turnaroundInfo)) {
    return {
      ...sourceTask,
      turnaroundInfo: turnaroundInfo,
      operationType: operationType,
      duration: durationInMinutes,
    };
  }
  return null;
}
