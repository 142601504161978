import "./styles.css";
import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import TurnaroundSection from "./TurnaroundSection";
import TurnaroundProgressBar from "../Turnarounds/TurnaroundProgressBar";
import { isNullOrUndefined } from "../../utils";
import { useMainDispatchContext } from "../../MainContext";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { useNavigate } from "react-router-dom";

function TurnaroundCard(props) {
  const dispatch = useMainDispatchContext();
  const navigate = useNavigate();

  const { turnaroundInfo } = props;
  const primaryStatus =
    turnaroundInfo?.turnaroundLevelOperationStatus?.primaryStatus;

  return (
    <div
      className={`turnaround-card ${
        !isNullOrUndefined(primaryStatus) ? `status-${primaryStatus}` : ""
      }`}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              registration: turnaroundInfo.registration,
            },
          },
        });
        navigate("/map");
      }}
    >
      <TurnaroundSection
        type="inboundFlight"
        turnaroundInfo={turnaroundInfo}
        flightName={turnaroundInfo.inboundFlightName}
        snappedStand={turnaroundInfo.snappedStand}
        arrivalStand={turnaroundInfo.arrivalStand}
        departureStand={turnaroundInfo.departureStand}
        gateIn={turnaroundInfo.gateIn}
        gateOut={turnaroundInfo.gateOut}
        landing={turnaroundInfo.landing}
        takeoff={turnaroundInfo.takeoff}
        origin={turnaroundInfo.originAirport}
        destination={turnaroundInfo.destinationAirport}
        timezone={turnaroundInfo.airportTimezone}
      />
      <div className="turnaround-progress-row">
        <div className="icon">
          <Turnaround />
        </div>
        <div>
          <TurnaroundProgressBar
            turnaroundInfo={turnaroundInfo}
            turnaroundLevelOperationStatus={true}
          />
        </div>
      </div>
      <TurnaroundSection
        type="outboundFlight"
        turnaroundInfo={turnaroundInfo}
        flightName={turnaroundInfo.outboundFlightName}
        snappedStand={turnaroundInfo.snappedStand}
        arrivalStand={turnaroundInfo.arrivalStand}
        departureStand={turnaroundInfo.departureStand}
        gateIn={turnaroundInfo.gateIn}
        gateOut={turnaroundInfo.gateOut}
        landing={turnaroundInfo.landing}
        takeoff={turnaroundInfo.takeoff}
        origin={turnaroundInfo.originAirport}
        destination={turnaroundInfo.destinationAirport}
        timezone={turnaroundInfo.airportTimezone}
      />
    </div>
  );
}

export default TurnaroundCard;
